import { API_KEY } from "./settings";
import axiosInstance from "./axiosInstance";

export const getAllStudyMaterials = async () => {
     const url = `MaterialEstudios/GetAll/${API_KEY}`;
     const axios = axiosInstance();
     try {
          const res = await axios.get(url);
          if (!res.ok) throw new Error(res);
          const studyMaterials = await res.json();
          return {
               ok: true,
               studyMaterials,
          };
     } catch (error) {
          return {
               ok: false,
          };
     }
};

import React, { useContext, useEffect, useState } from "react";

import { CoursesContext } from "context/coursesContext";
import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../firebase/credentials";
import TestCentral from "./Test/TestCentral";
import { UserInfoContext } from "./Test/contexts/UserInfoContext";
import TestCard from "./TestCard";

const coursesWithTests = [
  { name: "Junior", id: 5064 },
  { name: "Junior Plus", id: 5101 },
  { name: "Middle", id: 5069 },
];

const TestsPage = () => {
  const user = useContext(UserInfoContext);
  const { courses } = useContext(CoursesContext);
  const [coursesWithAvailableTests, setCoursesWithAvailableTests] = useState(
    []
  );

  const [juniorModules, setJuniorModules] = useState([]);
  const [juniorPlusModules, setJuniorPlusModules] = useState([]);
  const [middleModules, setMiddleModules] = useState([]);

  // Get modules from firestore
  const getCourseModules = async (course) => {
    const docRef = collection(firestore, "modulos", `${course.id}`, "modulos");
    const data = await getDocs(query(docRef));

    const modules = [];

    data.forEach((item) => {
      modules.push(item.data());
    });

    switch (course.id) {
      case 5064:
        setJuniorModules(modules);
        break;
      case 5101:
        setJuniorPlusModules(modules);
        break;
      case 5069:
        setMiddleModules(modules);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (coursesWithAvailableTests.length > 0) {
      coursesWithAvailableTests.forEach((course) => {
        getCourseModules(course);
      });
    }
  }, [coursesWithAvailableTests]);

  useEffect(() => {
    if (courses.length > 0) {
      let array = [];

      courses.forEach((course) => {
        for (let i = 0; i < coursesWithTests.length; i++) {
          if (course.id === coursesWithTests[i].id) {
            array.push(course);
          }
        }
      });

      setCoursesWithAvailableTests(array);
      // console.log("setCoursesWithAvailableTests", array);
    }
  }, [courses]);

  const checkCompletion = (courseID, bubbleIds) => {
    let matchArray = [];

    let [currentCourse] = courses.filter((course) => course.id === courseID);

    for (let i = 0; i < currentCourse.modulos.length; i++) {
      for (let j = 0; j < bubbleIds.length; j++) {
        if (currentCourse.modulos[i].id === bubbleIds[j]) {
          matchArray.push(currentCourse.modulos[i]);
        }
      }
    }

    let completed = true;

    for (let k = 0; k < matchArray.length; k++) {
      for (let l = 0; l < matchArray[k].clases.length; l++) {
        if (!matchArray[k].clases[l].completada) {
          completed = false;

          break;
        }
      }
    }

    return completed;
  };

  const [currentActiveTest, setCurrentActiveTest] = useState(0);
  const [currentLevel, setCurrentLevel] = useState("");
  const [testName, setTestName] = useState("");
  const [refresh, setRefresh] = useState(null);

  const openTest = (id, name) => {
    let [filter] = coursesWithTests.filter(
      (test) => test.id === Number(id.toString().slice(0, 4))
    );

    setCurrentLevel(filter.name);
    setCurrentActiveTest(id);
    setTestName(name);

    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
  };

  const closeTest = () => {
    setCurrentActiveTest(0);

    document.body.classList.remove("overflow-hidden");

    setRefresh(Date.now());
  };

  return (
    <section className="show-peace-page flex flex-col p-5 md:p-10">
      {courses.length > 0 ? (
        coursesWithAvailableTests.map((courseSection) => {
          let modulesToRender = [];

          if (courseSection.id === 5064) {
            modulesToRender = juniorModules;
          } else if (courseSection.id === 5101) {
            modulesToRender = juniorPlusModules;
          } else if (courseSection.id === 5069) {
            modulesToRender = middleModules;
          }

          return (
            <div key={courseSection.nombre}>
              <h1 className="font-roboto text-[#7DB8F2] ml-4 text-2xl font-semibold">
                {courseSection.nombre}
              </h1>

              <ol className="accordion flex flex-col pl-4 p-5">
                {modulesToRender.length > 0 ? (
                  modulesToRender.map((module) => {
                    if (checkCompletion(courseSection.id, module.bubbleIds)) {
                      let testID = Number(`${courseSection.id}${module.id}`);

                      return (
                        <li
                          key={module.moduleName}
                          className="accordion-item show-peace-page text-[#7DB8F2] mb-4 flex cursor-pointer flex-col items-start rounded-lg border-[#7DB8F2] border-4 bg-transparent p-5 shadow-sm transition-all duration-300 ease-in-out hover:border-[#CF7831]"
                          onClick={() => {
                            openTest(testID, module.moduleName)
                          }}
                        >
                          <TestCard
                            module={module}
                            user={user}
                            testID={testID}
                            refresh={refresh}
                          />
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={module.moduleName}
                          className="accordion-item show-peace-page text-[#7DB8F2] mb-4 flex cursor-pointer flex-col items-start rounded-xl border-2 border-slate-400 bg-transparent p-5 shadow-sm transition-all duration-300 ease-in-out"
                          onClick={() => {                            
                          }}
                        >
                          <div className="flex w-full flex-col items-start ">
                            <h2 className="accordion-title font-Barlow text-slate-400 mr-5 mb-6 text-left text-lg font-semibold">
                              {`Test ${module.id}: ${module.moduleName}`}
                            </h2>

                            <p className="text-slate-400">
                              Watch all the videos of this module to unlock the
                              test.
                            </p>
                          </div>
                        </li>
                      );
                    }
                  })
                ) : (
                  <p className="text-slate-500">Loading ...</p>
                )}
              </ol>
            </div>
          );
        })
      ) : (
        <p className="text-slate-500">Loading ...</p>
      )}

      {currentActiveTest !== 0 && (
        <TestCentral
          testName={testName}
          currentLevel={currentLevel}
          currentTestID={currentActiveTest}
          closeTest={closeTest}
        />
      )}
    </section>
  );
};

export default TestsPage;

import SideBarSkeleton from "components/material-content-section/skeletons-material-content/SideBarskeleton";
import VideoSectionSkeleton from "components/material-content-section/skeletons-material-content/video-section-skeleton";
import { SkeletonContent } from "components/material-content-section/skeletons-material-content/aboutSection-skeleton";
import { SkeletonStudentsPage } from "pages/MyTopic/skeleton";
import { SizeContext } from "context/SizeContext";
import { useContext } from "react";

export const MaterialSkeleton = () => {
  const { size } = useContext(SizeContext);
  return (
    <div className="flex flex-col w-full px-5">
      <section className="bg-[#333333] flex flex-col lg:flex-row lg:min-h-[700px] items-center">
        <SideBarSkeleton />
        <VideoSectionSkeleton />
        {size >= 1024 && <SkeletonStudentsPage />}
      </section>
      <section>
        <header className="h-20 w-full bg-gray-300 px-10 shadow-sm animate-pulse">
          <ul className="flex h-full items-center gap-5">
            {["about", "comments", "content"].map((tab) => (
              <li key={tab} className="h-6 w-20 bg-gray-400 rounded-md"></li>
            ))}
          </ul>
        </header>
        <SkeletonContent />
      </section>
    </div>
  );
};

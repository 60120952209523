import React from "react";
import ReactPlayer from "react-player";

import RadioType from "./RadioType";

const VideoType = ({ data }) => {
  return (
    <div className="video">
      <strong>Watch the video and choose the correct option</strong>

      <div className="iframe">
        <ReactPlayer
          url={data.video_URL}
          height="100%"
          width="100%"
          controls
          className="aspect-video"
        />
      </div>

      <RadioType data={data} />
    </div>
  );
};

export default VideoType;

import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

export const getAllAditionalMaterial = async () => {
  const url = `MaterialRefuerzo/GetAll/${API_KEY}`;
  const axios=  axiosInstance();
  const res = await axios.get(url);
  const aditionalMaterial = res.data;

  return {
    ok: true,
    aditionalMaterial,
  };
};

import React from "react";

import TopComponent from "./TopComponent";

const Instructions = ({ testName, currentLevel, setStage, closeTest }) => {
  return (
    <TopComponent>
      <div className="screen instructions bg-[#1B212B]">
        <div className="top-container bg-[#1B212B]">
          <div className="modal">
            <h2 className="text-[#7DB8F2]">MULTIPLE CHOICE TEST - {currentLevel.toUpperCase()} LEVEL</h2>

            <strong className="text-[#7DB8F2]">{testName}</strong>
            <p className="text-[#7DB8F2]">Read statements 1 to 10 and choose the correct answer</p>

            <div className="buttons-container-custom flex ">
              <button
                className="button"
                onClick={() => {
                  setStage(1);
                }}
              >
                Start Test
              </button>
              <button
                className="button blue"
                onClick={() => {
                  closeTest();
                }}
              >
                Try later
              </button>
            </div>
          </div>
        </div>
      </div>
    </TopComponent>
  );
};

export default Instructions;

import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export const postMaterialCompleteAsync = async ({ materialId, classNum }) => {
     function parseRecommendedData(data) {
          return data.recommendedModule ? {
               name: data.recommendedModule.nombre,
               id: data.recommendedModule.id,
               popUpText: data.popUpText,
               url: `/courses-paced/${data.recommendedModule.cursoId}#module-${data.recommendedModule.id}`,
               isSpeakingSession: false

          } : {
               name: data.name,
               id: data.id,
               popUpText: 'Ready to practice? Schedule a speaking session now!',
               url: `/speaking#topic-${data.id}`,
               isSpeakingSession: true
          }
     }
     try {
          const url = `/MaterialEstudios/SetMaterialCompletado/${materialId}/${classNum}/${USER_ID}/${API_KEY}/${USER_ID}`;
          const axios = axiosInstance();
          const res = await axios.get(url);
          const data = res.data;
          return {
               ok: true,
               recommended: res.status === 200 ? parseRecommendedData(data) : null
          };
     } catch (error) {
          console.log(error)
          return {
               ok: false,
               errorMessage: error || 'Ha ocurrido un error',
          };
     }
};

import { useRef, useState } from "react"
import EditPhoto from "../../assets/edit.svg"
import BubbleCamera from "../../assets/bubble-camera.svg"
import { uploadPhoto } from "services/uploadPhoto"
import { SnackBarComponent } from "components/SnackBarComponent"
import { API_ROOT } from "services/settings"
const initialSnackBar = {
     message: "",
     isSnackbarOpen: false,
     severity: "success",
}

export const BubbleProfile = ({ imagen = "", nombre = "nadie" }) => {
     const inputFile = useRef(null)
     const form = useRef(null)
     const [recentImage, setRecentImage] = useState("")
     const [isLoading, setIsLoading] = useState(false)
     const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar)

     const onUploadPhoto = async (e) => {
          setIsLoading(true)
          setSnackBarInfo({ ...snackBarInfo, message: "Subiendo imagen", isSnackbarOpen: true })
          const res = await uploadPhoto(e.target.files[0])
          form.current.reset()
          setIsLoading(false)
          if (!res.ok)
               return setSnackBarInfo({
                    ...initialSnackBar,
                    severity: "error",
                    message: "Ha ocurrido un error en la subida",
                    isSnackbarOpen: true,
               })
          setRecentImage(res.photo.replace(/[ '"]+/g, " "))
          setSnackBarInfo({
               ...snackBarInfo,
               message: "Imagen subida correctamente",
               isSnackbarOpen: true,
          })
     }

     const handleClose = () => {
          setSnackBarInfo({ ...initialSnackBar, isSnackbarOpen: false })
     }

     const imageURL = API_ROOT + "/ProfilePhotos/" + imagen

     const onEditClick = () => {
          if (isLoading)
               return setSnackBarInfo({
                    ...initialSnackBar,
                    severity: "error",
                    message: "Actualmente se esta subiendo una imagen",
                    isSnackbarOpen: true,
               })
          inputFile.current.click()
     }
     return (
          <>
               <div className='image-bubble-container relative'>
                    {imagen === "" && recentImage === "" ? (
                         <img src={BubbleCamera} className='' alt='no imagen' />
                    ) : (
                         <div className='user-img-container'>
                              <img
                                   src={recentImage !== "" ? recentImage : imageURL}
                                   alt={nombre}
                                   className='user-img'
                              />
                         </div>
                    )}
                    <div
                         className='edit-photo-container absolute right-0 top-0'
                         onClick={onEditClick}
                    >
                         <form ref={form}>
                              <input
                                   type='file'
                                   ref={inputFile}
                                   className='hidden'
                                   onChange={onUploadPhoto}
                              />
                         </form>
                         <img src={EditPhoto} alt='editar-foto' />
                    </div>
               </div>
               <SnackBarComponent handleClose={handleClose} {...snackBarInfo} />
          </>
     )
}

import { Grid, Typography } from "@mui/material"
import { TestsContext } from "context/TestsContext"
import { useContext, useEffect, useState } from "react"

const endMessageData = {
     business: {
          getEnglishLevel: (score) => {
               if (score <= 11) {
                    return { name: "a1", longName: "Junior Level (A1)" }
               } else if (score >= 12 && score <= 22) {
                    return { name: "a2", longName: "Middle Level (A2)" }
               } else if (score >= 23 && score <= 42) {
                    return { name: "b1", longName: "Senior Level (B1)" }
               } else if (score >= 43 && score <= 46) {
                    return { name: "b2", longName: "Expert Level (B2)" }
               } else {
                    return { name: "c1", longName: "Master Level (C1)" }
               }
          },
          a1: [
               {
                    description: `a (works): La respuesta correcta es "works" ya que se trata de una afirmación en presente simple. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "1.",
               },
               {
                    description: `a (email): La respuesta correcta es "email" ya que se trata de un verbo en infinitivo que describe una acción solicitada. La estructura gramatical es "Can + sujeto + verbo en infinitivo + objeto + ?".`,
                    number: "2.",
               },
               {
                    description: `a (starts): La respuesta correcta es "starts" ya que se trata de una afirmación en presente simple. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "3.",
               },
               {
                    description: `a (give): La respuesta correcta es "give" ya que se trata de un verbo en imperativo que describe una acción solicitada. La estructura gramatical es "Verbo en imperativo + objeto indirecto + objeto directo".`,
                    number: "4.",
               },
               {
                    description: `a (make): La respuesta correcta es "make" ya que se trata de un verbo en infinitivo que describe una acción solicitada. La estructura gramatical es "Sujeto + need to + verbo en infinitivo".`,
                    number: "5.",
               },
          ],
          a2: [
               {
                    description: `a (making): La respuesta correcta es "making" ya que se trata de un verbo en presente perfecto continuo que describe una acción en progreso que comenzó en el pasado. La estructura gramatical es "Sujeto + have/has been + verbo con -ing".`,
                    number: "6.",
               },
               {
                    description: `a (give): La respuesta correcta es "give" ya que se trata de un verbo en infinitivo que describe una acción futura. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "7.",
               },
               {
                    description: `a (submit): La respuesta correcta es "submit" ya que se trata de un verbo en infinitivo que describe una acción solicitada. La estructura gramatical es "Sujeto + have/has to + verbo en infinitivo".`,
                    number: "8.",
               },
               {
                    description: `a (main): La respuesta correcta es "main" ya que se trata de un adjetivo que describe un objetivo principal.`,
                    number: "9.",
               },
               {
                    description: `a (reserve): La respuesta correcta es "reserve" ya que se trata de un verbo en infinitivo que describe una acción solicitada. La estructura gramatical es "Can + sujeto + verbo en infinitivo + objeto + ?".`,
                    number: "10.",
               },
          ],
          b1: [
               {
                    description: `a (acquired): La respuesta correcta es "acquired" ya que se trata de un verbo en participio pasado que describe una acción que comenzó en el pasado y aún no ha terminado. La estructura gramatical es "Sujeto + have/has + participio pasado".`,
                    number: "11.",
               },
               {
                    description: `a (is): La respuesta correcta es "is" ya que se trata de una afirmación en presente continuo. La estructura gramatical es "Sujeto + be + verbo con -ing". `,
                    number: "12.",
               },
               {
                    description: `a (plans): La respuesta correcta es "plans" ya que se trata de una afirmación en presente simple. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "13.",
               },
               {
                    description: `a (will be completed): La respuesta correcta es "will be completed" ya que se trata de una acción futura que se completará en el futuro. La estructura gramatical es "Sujeto + will + verbo en participio pasado".`,
                    number: "14.",
               },
               {
                    description: `a (has): La respuesta correcta es "has" ya que se trata de un estado permanente en el presente. La estructura gramatical es "Sujeto + has/have + complement".`,
                    number: "15.",
               },
          ],
          b2: [
               {
                    description: `a (exceeded): La respuesta correcta es "exceeded" ya que se trata de un verbo en pasado simple que describe una acción pasada. La estructura gramatical es "Sujeto + verbo en pasado simple".`,
                    number: "16.",
               },
               {
                    description: `a (ran): La respuesta correcta es "ran" ya que se trata de un verbo en pasado simple que describe una acción pasada. La estructura gramatical es "Sujeto + verbo en pasado simple".`,
                    number: "17.",
               },
               {
                    description: `a (are having): La respuesta correcta es "are having" ya que se trata de un verbo en presente continuo que se usa para hablar sobre planes en el futuro. La estructura gramatical es "Sujeto + verbo to be + verbo con -ing".`,
                    number: "18.",
               },
               {
                    description: `a (reports): La respuesta correcta es "reports" ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "19.",
               },
               {
                    description: `a (prepare): La respuesta correcta es "prepare" ya que se trata de un verbo en infinitivo que describe una acción solicitada. La estructura gramatical es "Sujeto + need to + verbo en infinitivo".`,
                    number: "20.",
               },
          ],
          c1: [
               {
                    description: `a (has increased): La respuesta correcta es "has increased" ya que se trata de un verbo en presente perfecto que describe una acción que comenzó en el pasado y continúa en el presente. La estructura gramatical es "Sujeto + have/has + participio pasado”.`,
                    number: "21.",
               },
               {
                    description: `a (issued): La respuesta correcta es "issued" ya que se trata de un verbo en pasado simple que describe una acción pasada. La estructura gramatical es "Sujeto + verbo en pasado simple".`,
                    number: "22.",
               },
               {
                    description: `b (tests): La respuesta correcta es "tests" ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "23.",
               },
               {
                    description: `a (approved): La respuesta correcta es "approved" ya que se trata de un verbo en pasado simple que describe una acción pasada. La estructura gramatical es "Sujeto + verbo en pasado simple".`,
                    number: "24.",
               },
               {
                    description: `a (has entered into): La respuesta correcta es "has entered into" ya que se trata de un verbo en presente perfecto que describe una acción pasada con relevancia en el presente. La estructura gramatical es "Sujeto + have/has + participio pasado".`,
                    number: "25.",
               },
          ],
     },
     marketing: {
          getEnglishLevel: (score) => {
               if (score <= 11) {
                    return { name: "a1", longName: "Junior Level (A1)" }
               } else if (score >= 12 && score <= 22) {
                    return { name: "a2", longName: "Middle Level (A2)" }
               } else if (score >= 23 && score <= 42) {
                    return { name: "b1", longName: "Senior Level (B1)" }
               } else if (score >= 43 && score <= 58) {
                    return { name: "b2", longName: "Expert Level (B2)" }
               } else {
                    return { name: "c1", longName: "Master Level (C1)" }
               }
          },
          a1: [
               {
                    description: `a (Did): La respuesta correcta es "Did" ya que se trata de una pregunta en pasado simple. "Did" es la forma afirmativa y negativa del verbo "to do" en pasado simple. La estructura gramatical es "Did + sujeto + verbo en infinitivo?".`,
                    number: "1.",
               },
               {
                    description: `a (run): La respuesta correcta es "run" ya que se trata de un verbo en presente simple que se utiliza para describir una acción habitual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "2.",
               },
               {
                    description: `a (understand): La respuesta correcta es "understand" ya que se trata de un verbo en infinitivo que se utiliza para describir una acción que se debe realizar. La estructura gramatical es "Sujeto + need + verbo en infinitivo".`,
                    number: "3.",
               },
               {
                    description: `a (writes): La respuesta correcta es "writes" ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "4.",
               },
               {
                    description: `c ( are practicing): La respuesta correcta es " are practicing" ya que se trata de un verbo en presente continuo que se utiliza para describir una acción con referencia al futuro cercano. La estructura gramatical es "Sujeto + verbo en presente continuo".`,
                    number: "5.",
               },
          ],
          a2: [
               {
                    description: `a (will review): La respuesta correcta es "will review" ya que se trata de un verbo en futuro simple que se utiliza para describir una acción que ocurrirá en el futuro. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "6.",
               },
               {
                    description: `a (will attract): La respuesta correcta es "will attract" ya que se trata de un verbo en futuro simple que se utiliza para describir una acción que ocurrirá en el futuro. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "7.",
               },
               {
                    description: `a (is conducting): La respuesta correcta es "is conducting" ya que se trata de un verbo en presente continuo que describe una acción en progreso en el presente. La estructura gramatical es "Sujeto + to be + verbo con -ing".`,
                    number: "8.",
               },
               {
                    description: `a (update): La respuesta correcta es "update" ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "9.",
               },
               {
                    description: `a (Will): La respuesta correcta es "Will" ya que se trata de una pregunta en futuro simple. "Will" es la forma afirmativa y negativa del verbo "to will" en futuro simple. La estructura gramatical es "Will + sujeto + verbo en infinitivo?".`,
                    number: "10.",
               },
          ],
          b1: [
               {
                    description: `a (has set): La respuesta correcta es "has set" ya que se trata de un verbo en presente perfecto que describe una acción que se ha completado en el pasado con relevancia en el presente. La estructura gramatical es "Sujeto + has/have + participio pasado".`,
                    number: "11.",
               },
               {
                    description: `a (need): La respuesta correcta es "need" ya que se trata de un verbo en presente simple que describe una necesidad actual. La estructura gramatical es "Sujeto + verbo en presente simple".`,
                    number: "12.",
               },
               {
                    description: `a (will be launched): La respuesta correcta es "will be launched" ya que se trata de un verbo en futuro simple que se utiliza para describir una acción que ocurrirá en el futuro. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "13.",
               },
               {
                    description: `a (organized): La respuesta correcta es "organized" ya que se trata de un verbo en pasado simple que describe una acción completada en el pasado. La estructura gramatical es "Sujeto + verbo en pasado simple".`,
                    number: "14.",
               },
               {
                    description: `a (will receive): La respuesta correcta es "will receive" ya que se trata de un verbo en futuro simple que se utiliza para describir una acción que ocurrirá en el futuro. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "15.",
               },
               {
                    description: `d (plan): La respuesta correcta es "plan" ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es "Sujeto + verbo en infinitivo".`,
                    number: "16.",
               },
               {
                    description: `a (will give): La respuesta correcta es "will give" ya que se trata de un verbo en futuro simple que se utiliza para describir una acción que ocurrirá en el futuro. La estructura gramatical es "Sujeto + will + verbo en infinitivo".`,
                    number: "17.",
               },
               {
                    description: `a (has been developing): La respuesta correcta es "has been developing" ya que se trata de un verbo en presente perfecto continuo que describe una acción que comenzó en el pasado y continúa en el presente. La estructura gramatical es "Sujeto + has/have + been + verbo con -ing".`,
                    number: "18.",
               },
               {
                    description: `a (has set): La respuesta correcta es "has set" ya que se trata de un verbo en presente perfecto que describe una acción que se ha completado en el pasado con relevancia en el presente. La estructura gramatical es "Sujeto + has/have + participio pasado".`,
                    number: "19.",
               },
               {
                    description: `a (Have): La respuesta correcta es "Have" ya que se trata de una pregunta en presente perfecto que se utiliza para preguntar sobre experiencias pasadas que tienen relevancia en el presente. La estructura gramatical es "Have/Has + sujeto + participio pasado?".`,
                    number: "20.",
               },
          ],
          b2: [
               {
                    description:
                         "d (has had a vision): La respuesta correcta es 'has had a vision' ya que se trata de un verbo en presente perfecto que describe una acción que comenzó en el pasado y continúa en el presente. La estructura gramatical es 'Sujeto + has/have + participio pasado'.",
                    number: "21.",
               },
               {
                    description:
                         "a (seize): La respuesta correcta es 'seize' ya que se trata de un verbo en presente simple que describe una acción habitual. La estructura gramatical es 'Sujeto + verbo en infinitivo'.",
                    number: "22.",
               },
               {
                    description:
                         "a (can analyze): La respuesta correcta es 'can analyze' ya que se trata de un verbo modal que describe una habilidad o posibilidad. La estructura gramatical es 'Sujeto + can + verbo en infinitivo'.",
                    number: "23.",
               },
               {
                    description:
                         "a (will have been launched): La respuesta correcta es 'will have been launched' ya que se trata de un verbo en futuro perfecto que describe una acción que habrá ocurrido en el futuro en un momento determinado. La estructura gramatical es 'Sujeto + will have + participio pasado'.",
                    number: "24.",
               },
               {
                    description:
                         "a (needs to work harder): La respuesta correcta es 'needs to work harder' ya que se trata de un verbo en presente simple que describe una necesidad actual. La estructura gramatical es 'Sujeto + verbo en presente simple'.",
                    number: "25.",
               },
          ],
          c1: [
               {
                    description: `a (has received): La respuesta correcta es "has received" ya que se trata de un verbo en presente perfecto que describe una acción que se ha completado en el pasado con relevancia en el presente. La estructura gramatical es "Sujeto + has/have + participio pasado"`,
                    number: "26.",
               },
               {
                    description: `a (had completed): La respuesta correcta es "had completed" ya que se trata de un verbo en pasado perfecto que describe una acción que se ha completado en el pasado con anterioridad a otra acción pasada (launched the campaign). La estructura gramatical es "Sujeto + had + participio pasado".`,
                    number: "27.",
               },
               {
                    description: `a (could plan): La respuesta correcta es "could plan" ya que se trata de un verbo en condicional que describe una acción que podría realizarse de cumplirse una condición. La estructura gramatical es "Sujeto + could + infinitivo".`,
                    number: "28.",
               },
               {
                    description: `a (has changed): La respuesta correcta es "has changed" ya que se trata de un verbo en presente perfecto que describe una acción que se ha completado en el pasado con relevancia en el presente. La estructura gramatical es "Sujeto + has/have + participio pasado".`,
                    number: "29.",
               },
               {
                    description: `a (been following): La respuesta correcta es "been following" ya que se trata de un verbo en presente perfecto continuo que describe una acción que se ha completado en el pasado con relevancia en el presente, en donde se enfatiza la duración de la acción. La estructura gramatical es "has/have been + Sujeto + been +verbo con -ing".`,
                    number: "30.",
               },
          ],
     },
     tech: {
          getEnglishLevel: (score) => {
               if (score <= 11) {
                    return { name: "a1", longName: "Junior Level (A1)" }
               } else if (score >= 12 && score <= 22) {
                    return { name: "a2", longName: "Middle Level (A2)" }
               } else if (score >= 23 && score <= 42) {
                    return { name: "b1", longName: "Senior Level (B1)" }
               } else if (score >= 43 && score <= 58) {
                    return { name: "b2", longName: "Expert Level (B2)" }
               } else {
                    return { name: "c1", longName: "Master Level (C1)" }
               }
          },
          a1: [
               {
                    description: `a (Are): La forma correcta del verbo "to be" en segunda persona singular es "are", por lo que la respuesta correcta es "Are you a software developer?".`,
                    number: "1.",
               },
               {
                    description: `b (use): El verbo correcto en este caso es "use", ya que se refiere a la acción de utilizar una tecnología específica de manera habitual, por lo que la respuesta correcta es "The stack I use is LAMP.".`,
                    number: "2.",
               },
               {
                    description: `a (need): El verbo "need" es el correcto en este caso, ya que se refiere a la necesidad de realizar una acción, por lo que la respuesta correcta es "We need to test the code before we release it.".`,
                    number: "3.",
               },
               {
                    description: `a (likes): En este caso, el sujeto "she" está en tercera persona singular, por lo que el verbo "like" debe ser conjugado en tercera persona singular, por lo que la respuesta correcta es "She likes JavaScript.".`,
                    number: "4.",
               },
               {
                    description: `a (am): La forma correcta del verbo "to be" en primera persona singular es "am", por lo que la respuesta correcta es "I am new to programming.".`,
                    number: "5.",
               },
          ],
          a2: [
               {
                    description: `a (checks): El verbo correcto en este caso es "checks", ya que se refiere a la acción de revisar algo, por lo que la respuesta correcta es "He checks his code carefully every time before delivery.”`,
                    number: "6.",
               },
               {
                    description: `a (decided): El verbo correcto en este caso es "decided", ya que se refiere a una acción que ya ha ocurrido en el pasado, por lo que la respuesta correcta es "We decided to use Python for this project.".`,
                    number: "7.",
               },
               {
                    description: `d (are implementing): En este caso, la respuesta correcta es "implementing", ya que se refiere a una acción que hace referencia al futuro cercano, por lo que la respuesta correcta es "They are implementing the new feature tomorrow.".`,
                    number: "8.",
               },
               {
                    description: `a (want): El verbo correcto en este caso es "want", ya que se refiere a una acción deseada, por lo que la respuesta correcta es "I want to learn more about databases.".`,
                    number: "9.",
               },
               {
                    description: `d (is talking): En este caso, la respuesta correcta es "is talking", ya que se refiere a una acción que está ocurriendo en el momento presente, por lo que la respuesta correcta es "She is talking to a client right now.".`,
                    number: "10.",
               },
          ],
          b1: [
               {
                    description: `a (finished): El verbo correcto en este caso es "finished", ya que se refiere a una acción completada en el pasado, por lo que la respuesta correcta es "The software engineer finished the project by herself at 10pm".`,
                    number: "11.",
               },
               {
                    description: `a (plan): El verbo correcto en este caso es "plan", ya que se refiere a una acción que ocurrirá en el futuro, por lo que la respuesta correcta es "We plan to refactor the code next week.".`,
                    number: "12.",
               },
               {
                    description: `a (developed): El verbo correcto en este caso es "developed", ya que se refiere a una acción que ya ocurrió en el pasado, por lo que la respuesta correcta es "He developed his first app last year.".`,
                    number: "13.",
               },
               {
                    description: `a (need): El verbo correcto en este caso es "need", ya que se refiere a una necesidad actual, por lo que la respuesta correcta es "They need to hire more developers soon.".`,
                    number: "14.",
               },
               {
                    description: `a (have done): En este caso, el verbo "to have done" es el correcto, ya que se refiere a una acción en el pasado que tiene relevancia en el presente, por lo que la respuesta correcta es "I have done this code before, but I can't remember how to do it.".`,
                    number: "15.",
               },
               {
                    description: `a (will have done): En este caso, el verbo "to have done" se utiliza en futuro perfecto, por lo que la respuesta correcta es "By the time we release the app, we will have done a lot of testing.".`,
                    number: "16.",
               },
               {
                    description: `a (approved): El verbo correcto en este caso es "approved", ya que se refiere a una acción completada en el pasado, por lo que la respuesta correcta es "The project manager approved the new feature yesterday.".`,
                    number: "17.",
               },
               {
                    description: `a (have been): En este caso, el verbo "to have been" se utiliza en presente perfecto continuo, por lo que la respuesta correcta es "I have been working on this project for two weeks now.".`,
                    number: "18.",
               },
               {
                    description: `c (could have finished): En este caso, el verbo "to have finished" se utiliza en pasado perfecto, ya que se refiere a una acción que podría haber ocurrido en el pasado, pero no ocurrió, por lo que la respuesta correcta es "They could have finished the app if they had more time.".`,
                    number: "19.",
               },
               {
                    description: `a (will have): En este caso, el verbo "to have" se utiliza en futuro perfecto, por lo que la respuesta correcta es "We will have our code review next week.".`,
                    number: "20.",
               },
          ],
          b2: [
               {
                    description: `a (doesn't like): En este caso, el verbo "to like" se utiliza en tercera persona singular, en negativo, ya que se refiere a una acción que podría ocurrir en el futuro, por lo que la respuesta correcta es "If the client doesn't like the new feature, we'll have to change our plans.".`,
                    number: "21.",
               },
               {
                    description: `c (would have made): En este caso, el verbo "to make" se utiliza, en el condicional de tercer tipo, ya que se refiere a una acción que no ocurrió, por lo que la respuesta correcta es "We would have made more progress if we had had more resources.".`,
                    number: "22.",
               },
               {
                    description: `c (is always double-checking): En este caso, el verbo "to double-check" se utiliza en presente continuo, ya que se refiere a una acción que ocurre en el presente de forma habitual de manera repetitiva, por lo que la respuesta correcta es "The QA analyst is always double-checking her code before sending it to the client.".`,
                    number: "23.",
               },
               {
                    description: `a (will have to cancel): En este caso, el verbo "to have to cancel" se utiliza en futuro simple, ya que se refiere a una acción que podría ocurrir en el futuro, por lo que la respuesta correcta es "We will have to cancel the project if we don't get more funding.".`,
                    number: "24.",
               },
               {
                    description: `a (will have worked): En este caso, el verbo "to have worked" se utiliza en futuro perfecto, ya que se refiere a una acción que estará completa en el futuro, por lo que la respuesta correcta es "By the time we finish this project, we will have worked for six months.".`,
                    number: "25.",
               },
               {
                    description: `a (adopted): En este caso, el verbo "to adopt" se utiliza en pasado simple (modo subjuntivo), ya que se refiere a una acción que podría ocurrir en el futuro, por lo que la respuesta correcta es "If we adopted Agile methodology, we would be able to work more efficiently.".`,
                    number: "26.",
               },
               {
                    description: `a (wouldn't have been improved): En este caso, se utiliza la estructura “would have + participio pasado”, ya que se refiere a una acción que no ocurrió en el pasado. En este caso el verbo es “to be improve”, por lo tanto la respuesta correcta es "The code wouldn't have been improved if it hadn't been for the QA analyst's feedback.".`,
                    number: "27.",
               },
          ],
          c1: [
               {
                    description: `a (have been using): En este caso, el verbo "to have been using" se utiliza en presente perfecto continuo, ya que se refiere a una acción pasada que se extiende en el presente, por lo que la respuesta correcta es "I have been using this technology for years.".`,
                    number: "28.",
               },
               {
                    description: `b (have completed): En este caso, el verbo "to complete" se utiliza en presente perfecto, ya que se refiere a una acción completada en el pasado con relevancia en el presente, por lo que la respuesta correcta es "They have completed the project ahead of schedule.".`,
                    number: "29.",
               },
               {
                    description: `a (will have tested): En este caso, el verbo "to have tested" se utiliza en futuro perfecto, ya que se refiere a una acción que se completará en el futuro, por lo que la respuesta correcta es "By the time the client arrives, we will have tested the software.".`,
                    number: "30.",
               },
          ],
     },
}

export const EndMessage = ({ score, getScore }) => {
     const { topicTest } = useContext(TestsContext)
     const [level, setLevel] = useState({ title: "", messages: [] })

     useEffect(() => {
          getScore()
     }, [])

     useEffect(() => {
          const level = endMessageData[topicTest]

          const levelName = level.getEnglishLevel(score)
          const messages = level[levelName.name]

          setLevel({
               title: levelName.longName,
               messages: messages,
          })
     }, [score, topicTest])

     return (
          <Grid container sx={{ width: "100%", p: 5, justifyContent: "center" }}>
               <Grid container sx={{ alignItems: "center", flexDirection: "column" }}>
                    <Typography
                         sx={{
                              ...titleSyles,
                              fontSize: { xs: "25px", sm: "28px", lg: "43px" },
                         }}
                    >
                         Tu puntaje: {score}{" "}
                    </Typography>
                    <Typography component={"b"} sx={{ ...titleSyles, color: "var(--green)" }}>
                         {level.title}
                    </Typography>
               </Grid>
               <Grid container sx={whiteBox}>
                    {level.messages.map((lvl, i) => (
                         <Grid key={i} sx={{ my: 2 }}>
                              <Typography sx={{ fontFamily: "Barlow" }}>
                                   <Typography
                                        component={"b"}
                                        sx={{ fontWeight: 700, fontFamily: "Barlow" }}
                                   >
                                        {lvl.number}
                                   </Typography>
                                   {lvl.description}
                              </Typography>
                         </Grid>
                    ))}
               </Grid>
          </Grid>
     )
}

const titleSyles = {
     textAlign: "center",
     fontWeight: 700,
     fontSize: { xs: "18px", sm: "22px", lg: "35px" },
     fontFamily: "Barlow",
     mb: 0,
}

const whiteBox = {
     background: "#fff",
     p: { xs: 2, sm: 4 },
     mt: 4,
     borderRadius: "15px",
     boxShadow: "0 0 15px rgba(0,0,0,0.02)",
     width: { md: "70%" },
}

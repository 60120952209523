import { API_KEY } from "./settings";
import axiosInstance from "./axiosInstance";

export default async function getAllCoursesByUser({ id }) {
  const USER_ID = JSON.parse(localStorage.getItem("loggedAppUser")).id;
  const url = `Cursos/GetAllByAlumno/${USER_ID}/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching courses: ", error.response || error.message);
    throw error;
  }
}

const API_URL = process.env.REACT_APP_DIGISPEAK_API_BASE_URL;
const API_KEY = process.env.REACT_APP_DIGISPEAK_API_KEY;
const API_ROOT = process.env.REACT_APP_DIGISPEAK_API_ROOT_URL;
const URL_LANDING = process.env.REACT_APP_DIGISPEAK_LANDING_URL;
let userInfo = JSON.parse(window.localStorage.getItem("loggedAppUser")) || null;
if (userInfo && !userInfo.token) { // workaround for old user data
    window.localStorage.removeItem("loggedAppUser");
    userInfo = null;
}
const USER_DATA = userInfo;
const USER_ID = USER_DATA ? USER_DATA.id : null;
const USER_TOKEN = USER_DATA ? USER_DATA.token : null;

export { API_URL, API_KEY, API_ROOT, URL_LANDING, USER_DATA, USER_ID, USER_TOKEN };

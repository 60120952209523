import { UIContext } from "context/UIContext"
import { useAccess } from "hooks/useAccess"
import { useContext } from "react"

const AccordionItemTitle = ({ title }) => {
     return <h2 className='mr-5 pl-6 font-Barlow text-xl font-semibold text-[#7DB8F2]'>{title}</h2>
}

const AccordionItemContent = ({ content }) => {
     const { isBasic } = useAccess()
     const { openModal } = useContext(UIContext)
     if (Array.isArray(content)) {
          return content.map((item) => <h4 className='px-5'>{item.nombre}</h4>)
     }

     const onCardClick = (e) => {
          if (isBasic) {
               e.stopPropagation()
               openModal()
          }
     }

     return (
          <div className='pl-5 pt-5 w-full' onClick={onCardClick}>
               <NormalItem content={content} />
          </div>
     )
}

export default function AccordionItem({ item }) {
     return (
          <li className='w-full max-w-5xl bg-transparent p-1 shadow-sm md:p-10 lg:p-5'>
               <AccordionItemTitle title={item.curso} />
               {item.archivos.map((file) => {
                    return <AccordionItemContent content={file} key={file.id} />
               })}
          </li>
     )
}

/* -- esto muestra el material con el contenido enmascarado. se cambio por un page que muestra la posibilidad de upgradear. Si esta ok se puede eliminar este bloque.
const BasicItem = ({ content }) => {
     return (
          <div className='group flex max-w-fit gap-1 overflow-hidden rounded-xl border border-gray-300 hover:bg-primary'>
               <span className='flex items-center border-gray-300 bg-accent p-2 font-semibold text-primary'>
                    {content.tipoContenido}
               </span>
               <div className='p-2 group-hover:text-white'>
                    <h5 className='font-semibold'>{content.nombre}</h5>
                    <span>{content.pathAcceso.replace(/./g, "*")}</span>
               </div>
          </div>
     )
}
*/
const NormalItem = ({ content }) => {
     return (
          <a
               className='group flex gap-1 overflow-hidden rounded-xl border border-gray-300 hover:bg-primary'
               href={content.pathAcceso}
               target='_blank'
               rel='noreferrer'
          >
               <span className='flex items-center border-gray-300 bg-[#CF7831] p-2 font-semibold text-white'>
                    {content.tipoContenido}
               </span>
               <div className='p-2 group-hover:text-white'>
                    <h5 className='font-semibold text-[#7DB8F2]'>{content.nombre}</h5>
                    <span class="text-[#7DB8F2]">{content.pathAcceso}</span>
               </div>
          </a>
     )
}

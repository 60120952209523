import { useState, useEffect, useContext } from "react";

import Accordion from "components/Accordion";
import { CoursesContext } from "context/coursesContext";
import { getAllClassMaterial } from "services/getAllClassMaterial";
import { UpgradePlanPage } from "components/UpgradePlanPage";

const sortById = (list) => {
  return list.map((item) => {
    item.archivos = item.archivos.sort((a, b) => a.id - b.id);
    return item;
  });
};

export default function ClassMaterialPage({ requiredPlanFeature }) {
  const [refuerzo, setRefuerzo] = useState([]);
  const { courses, userPlan } = useContext(CoursesContext);

  const fetchAditionalMaterial = async () => {
    let filteredCourses = courses.filter((course) => course.PlanEstudio !== "Obligatorio" && course.planEstudio !== "Freemium");
    filteredCourses = filteredCourses.filter((course) => course.planEstudio.includes('plus')).length > 0 ? filteredCourses.filter((course) => course.planEstudio.includes('plus')) : filteredCourses;
    const ids = filteredCourses.map((course) => course.id);
    const res = await getAllClassMaterial();
    let aditionalMaterials = res.aditionalMaterial.filter((material) =>
      ids.includes(material.cursoId)
    );
    aditionalMaterials = sortById(aditionalMaterials);

    setRefuerzo(aditionalMaterials);
  };

  useEffect(() => {
    if (courses.length <= 0) return;
    fetchAditionalMaterial();
  }, [courses]);


  const hasRequiredPlan = userPlan?.features.some(item => item.featureName === requiredPlanFeature && item.enabled);
  if (hasRequiredPlan === undefined) {
    return null;
  }
  return (hasRequiredPlan ?
    <section className="flex flex-col gap-5 p-5 md:p-10 lg:px-14">
      <h2 className="text-[#7DB8F2] text-2xl font-semibold font-roboto">Class Material</h2>
      <Accordion items={refuerzo} />
    </section>
    :
    <UpgradePlanPage />
  );
}

export const SkeletonStudentsPage = () => {
    return (
      <div className="relative lg:min-h-[650px] max-h-[650px] overflow-hidden z-20 rounded-[12px] bg-gray-800 lg:w-[30%] animate-pulse">

        <div className="absolute top-0 h-[80px] w-full border-b border-b-gray-500 bg-gray-700 rounded-t-lg flex justify-between items-center px-4">
          <div className="h-4 w-24 bg-gray-500 rounded-md"></div>
          <div className="h-4 w-20 bg-gray-500 rounded-md flex items-center gap-2">
            <div className="h-4 w-4 bg-gray-500 rounded-full"></div>
            <div className="h-4 w-10 bg-gray-500 rounded-md"></div>
          </div>
        </div>

        <div className="min-h-[400px] w-full overflow-y-scroll pb-[87px] bg-gray-700 pt-[80px] space-y-2 px-4">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="h-6 w-3/4 bg-gray-500 rounded-md"></div>
          ))}
        </div>

        <div className="absolute bottom-[100px] w-full flex justify-center">
          <div className="text-center text-sm text-gray-400">
            <div className="h-4 w-40 bg-gray-500 rounded-md"></div>
          </div>
        </div>

        <div className="absolute bottom-0 bg-gray-700 border-t border-gray-500 rounded-b-lg w-full px-4 py-3 flex gap-3 items-center">
          <div className="flex-1 h-10 bg-gray-500 rounded-md"></div>
          <div className="h-10 w-10 bg-gray-500 rounded-full"></div>
        </div>
      </div>
    );
  };
  
import { useCallback, useContext, useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase/credentials";

import "./sass/App.scss";

//Components
import Questionnaire from "./components/Questionnaire";
import Instructions from "./components/Instructions";
import Results from "./components/Results";

//Context
import { DataLenghtContext } from "./contexts/DataLenghtContext";
import { UserChoicesContext } from "./contexts/UserChoicesContext";
import { UserInfoContext } from "./contexts/UserInfoContext";

//Helpers
import { activateScreenSizeChangeDetection } from "./helpers/screenSizeChangeDetector";
import { calculateScore, generateResultStructure } from "./helpers/utilities";

//Questions
import { allTests } from "./data/allTestQuestions";

function TestCentral({ testName, currentLevel, currentTestID, closeTest }) {
  const user = useContext(UserInfoContext);

  let [currentTest] = allTests.filter((test) => test.id === currentTestID);

  // console.log(currentTest);

  //User test choices
  const [userChoices, setUserChoices] = useState(
    generateResultStructure(currentTest.questions.length)
  );

  //Status
  //0 === uninitialized
  //1 === successful upload
  //2 === error
  const [uploadStatus, setUploadStatus] = useState(0);

  //Important for the transition effect
  useEffect(() => {
    activateScreenSizeChangeDetection();
  }, []);

  //Update user choices
  const updateContext = useCallback(
    (questionIndex, questionChoice) => {
      const newState = userChoices.map((obj) => {
        if (obj.id === questionIndex) {
          return { ...obj, choice: questionChoice };
        }
        return obj;
      });

      setUserChoices(newState);
    },
    [userChoices, setUserChoices]
  );

  const getContext = useCallback(
    () => ({
      array: userChoices,
      updateContext,
    }),
    [userChoices, updateContext]
  );

  const [score, setScore] = useState(0);

  //Upload results to Firebase
  const saveData = async () => {
    const localScore = calculateScore(currentTest.questions, userChoices);

    try {
      let db = firestore;

      const testRef = collection(
        db,
        "testResults",
        `${user.id}`,
        `${currentTestID}`
      );

      const docRef = await addDoc(testRef, {
        score: localScore,
        timestamp: serverTimestamp(),
      });

      console.log("Document written with ID: ", docRef.id);

      setUploadStatus(1);
    } catch (e) {
      console.error("Error adding document: ", e);

      setUploadStatus(2);
    }

    setScore(localScore);
  };

  useEffect(() => {
    if (uploadStatus === 1) {
      setStage(2);
    }
  }, [uploadStatus]);

  const [stage, setStage] = useState(0);

  return (
    <div className="test-superior-container">
      {
        <UserChoicesContext.Provider value={getContext}>
          <DataLenghtContext.Provider value={currentTest.questions.length - 2}>
            {stage === 0 ? (
              <Instructions
                testName={testName}
                currentLevel={currentLevel}
                setStage={setStage}
                closeTest={closeTest}
              />
            ) : stage === 1 ? (
              <Questionnaire
                currentTest={currentTest}
                saveData={saveData}
                uploadStatus={uploadStatus}
              />
            ) : (
              <Results
                testName={testName}
                score={score}
                closeTest={closeTest}
              />
            )}
          </DataLenghtContext.Provider>
        </UserChoicesContext.Provider>
      }
    </div>
  );
}

export default TestCentral;

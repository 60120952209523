export const diagnosisQuestionBusiness = {
     id: 0,
     type: "radio",
     question: "Our company ________ in the financial sector",
     options: [
          { id: 1, text: "works", points: 2 },
          { id: 2, text: "worked", points: 0 },
          { id: 3, text: "has worked", points: 0 },
          { id: 4, text: "will work", points: 0 },
     ],
}

export const testQuestionsBusiness = [
     null,
     {
          id: 1,
          type: "radio",
          question: "Our company ________ in the financial sector",
          options: [
               { id: 1, text: "works"  },
               { id: 2, text: "worked"  },
               { id: 3, text: "has worked"  },
               { id: 4, text: "will work"  },
          ],
          answer: 1,
          points: 4,
     },
     {
          id: 2,
          question: "Can you please ________ me the report?",
          options: [
               { id: 1, text: "email" },
               { id: 2, text: "emails" },
               { id: 3, text: "emailed" },
               { id: 4, text: "emailing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 3,
          question: "The meeting ________ every Monday at 10 am.",
          options: [
               { id: 1, text: "starts" },
               { id: 2, text: "started" },
               { id: 3, text: "has started" },
               { id: 4, text: "will start" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 4,
          question: "Please ________ me your business card.",
          options: [
               { id: 1, text: "give" },
               { id: 2, text: "gives" },
               { id: 3, text: "gave" },
               { id: 4, text: "giving" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 5,
          question: "We need to ________ a budget for this project.",
          options: [
               { id: 1, text: "make" },
               { id: 2, text: "makes" },
               { id: 3, text: "made" },
               { id: 4, text: "making" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 6,
          question: "The company has been ________ profits for the last quarter.",
          options: [
               { id: 1, text: "making" },
               { id: 2, text: "made" },
               { id: 3, text: "makes" },
               { id: 4, text: "make" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 7,
          question: "The CEO will ________ a presentation at the conference.",
          options: [
               { id: 1, text: "give" },
               { id: 2, text: "gives" },
               { id: 3, text: "gave" },
               { id: 4, text: "giving" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 8,
          question: "We have to ________ the sales report by Friday",
          options: [
               { id: 1, text: "submit" },
               { id: 2, text: "submits" },
               { id: 3, text: "submitted" },
               { id: 4, text: "submitting" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 9,
          question: "The company's ________ objective is to increase revenue.",
          options: [
               { id: 1, text: "main" },
               { id: 2, text: "mane" },
               { id: 3, text: "mean" },
               { id: 4, text: "men" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 10,
          question: "Can you please ________ the conference room for the meeting?",
          options: [
               { id: 1, text: "reserve" },
               { id: 2, text: "reserves" },
               { id: 3, text: "reserved" },
               { id: 4, text: "reserving" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 11,
          question: "We have ________ many new clients this year",
          options: [
               { id: 1, text: "acquired" },
               { id: 2, text: "acquires" },
               { id: 3, text: "acquire" },
               { id: 4, text: "acquiring" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 12,
          question: "Our team ________ discussing the new marketing strategy.",
          options: [
               { id: 1, text: "is" },
               { id: 2, text: "are" },
               { id: 3, text: "has" },
               { id: 4, text: "have" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 13,
          question: "The CEO ________ a new product launch next quarter.",
          options: [
               { id: 1, text: "plans" },
               { id: 2, text: "planned" },
               { id: 3, text: "plan" },
               { id: 4, text: "planning" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 14,
          question: "The project ________ by the end of the year.",
          options: [
               { id: 1, text: "will be completed" },
               { id: 2, text: "was completed" },
               { id: 3, text: "completes" },
               { id: 4, text: "completed" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 15,
          question: "Our company ________ a strong reputation in the industry.",
          options: [
               { id: 1, text: "has" },
               { id: 2, text: "have" },
               { id: 3, text: "is having" },
               { id: 4, text: "had" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 16,
          question: "The company ________ its sales target last year.",
          options: [
               { id: 1, text: "exceeded" },
               { id: 2, text: "exceed" },
               { id: 3, text: "exceeds" },
               { id: 4, text: "exceeding" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 17,
          question: "The marketing team ________ a successful campaign last month.",
          options: [
               { id: 1, text: "ran" },
               { id: 2, text: "runs" },
               { id: 3, text: "run" },
               { id: 4, text: "running" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 18,
          question: "We ________ a meeting with the stakeholders next week.",
          options: [
               { id: 1, text: "are having" },
               { id: 2, text: "have" },
               { id: 3, text: "had" },
               { id: 4, text: "have had" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 19,
          question: "The department ________ to the management team.",
          options: [
               { id: 1, text: "reports" },
               { id: 2, text: "reported" },
               { id: 3, text: "reporting" },
               { id: 4, text: "report" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 20,
          question: "We need to ________ the financial statements before the audit.",
          options: [
               { id: 1, text: "prepare" },
               { id: 2, text: "prepares" },
               { id: 3, text: "prepared" },
               { id: 4, text: "preparing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 21,
          question: "The company's revenue _________ by 20% this year so far.",
          options: [
               { id: 1, text: "has increased" },
               { id: 2, text: "increased" },
               { id: 3, text: "increase" },
               { id: 4, text: "will increase" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 22,
          question: "The CEO ________ a statement regarding the company's new direction.",
          options: [
               { id: 1, text: "issued" },
               { id: 2, text: "issues" },
               { id: 3, text: "issue" },
               { id: 4, text: "issuing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 23,
          question: "Our team always ________ the new software before the release.",
          options: [
               { id: 1, text: "tested" },
               { id: 2, text: "tests" },
               { id: 3, text: "testing" },
               { id: 4, text: "test" },
          ],
          answer: 2,
          points: 2,
     },
     {
          id: 24,
          question: "Yesterday, the board of directors ________ the new business plan.",
          options: [
               { id: 1, text: "approved" },
               { id: 2, text: "approves" },
               { id: 3, text: "approve" },
               { id: 4, text: "approving" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 25,
          question: "The company ________ a joint venture with a competitor recently",
          options: [
               { id: 1, text: "has entered into" },
               { id: 2, text: "entered into" },
               { id: 3, text: "enters into" },
               { id: 4, text: "will enter into" },
          ],
          answer: 1,
          points: 2,
     },
     null,
]

import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

export default function getMaterialById({ id }) {
  const url = `MaterialEstudios/GetById/${id}/${API_KEY}/`;
  const axios = axiosInstance();
  return axios.get(url)
    .then((response) => {
      return response.data;
    });
}

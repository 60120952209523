import { SnackBarComponent } from "components/SnackBarComponent";
import { useState } from "react";
import { recoverPassword } from "services/recoverPassword";

export const RecoverPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("success");
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== "" && emailRegex.test(email)) {
      setIsLoading(true);
      try {
        await recoverPassword(email);
        setSnackType("success");
        setSnackMessage(`Si ${email} corresponde a uno de nuestros usuarios, enviaremos un correo de recuperación de contraseña. Por favor verifica tu bandeja de correo en unos instantes.`);
        setIsSnackbarOpen(true);
      } catch (error) {
        setSnackType("error");
        setSnackMessage("Se produjo un error al enviar el correo de recuperación de contraseña, por favor intenta más tarde.");
        setIsSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setSnackType("error");
      setSnackMessage("Por favor ingresa un correo electrónico.");
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center px-8">
      <form
        onSubmit={handleLogin}
        className="bg-primary flex w-full min-w-[20rem] max-w-[30rem] flex-col items-center justify-start gap-5 rounded-2xl p-10 shadow"
      >
        <h1 className=" font-Barlow text-4xl text-white">
          Digispeak
        </h1>
        <div className="flex flex-col gap-1 w-full">
          <label className="font-Barlow text-white">Email</label>
          <input
            className="rounded-md p-2 pt-1 w-full outline-none focus:outline-teal-500"
            type="text"
            name="email"
            placeholder="example@mail.com"
            value={email}
            onChange={handleEmail}
          />
        </div>
        {!isLoading ? (
          <button className="bg-accent font-Barlow text-primary flex h-11 items-center justify-center rounded-3xl p-2 px-4 pt-1 font-semibold mt-4">
            Recuperar contraseña
          </button>
        ) : (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </form>
      <SnackBarComponent
        isSnackbarOpen={isSnackbarOpen}
        handleClose={handleClose}
        message={snackMessage}
        severity={snackType}
      />
    </div>
  );
};

import Comment from "./Comment";

function CommentsList({ comments = [], userId, materialId, courseId }) {
  return comments.map((comment) => (
    <Comment
      key={comment.id}
      comment={comment}
      userId={userId}
      materialId={materialId}
      courseId={courseId}
    />
  ));
}

export default CommentsList;

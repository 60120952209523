import axiosInstance from "./axiosInstance";
import { API_KEY, API_URL, USER_ID } from "./settings"

export const uploadPhoto = async (photo, id = "") => {
     const url = `${API_URL}/Usuario/UpdateProfilePhoto/${API_KEY}/${id === "" ? USER_ID : id}`
     const body = new FormData();
     body.append("pro", photo);
     const axios = axiosInstance();

     try {
          const res = await axios.post(url, body);
          if (!res.ok) throw Error(res)
          const photoUrl = await res.text()
          return {
               ok: true,
               photo: photoUrl,
          }
     } catch (error) {
          return {
               ok: false,
               errorMessage: error.response.message | "Ha ocurrido un error",
          }
     }
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SizeProvider } from "context/SizeProvider";
import { UserDataProvider } from "context/UserDataProvider";

ReactDOM.render(
  <React.StrictMode>
    <SizeProvider>
      <UserDataProvider>
        <App/>
      </UserDataProvider>
    </SizeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

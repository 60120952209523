import { IconDone } from "components/icons-items";
import { useState } from "react";
import { useEffect } from "react";
import Logo from "../../assets/DigiSpeak.svg";

const emailDomains = {
  "gmail.com": "https://mail.google.com/",
  "hotmail.com": "https://outlook.live.com/",
  "outlook.com": "https://outlook.live.com/",
  "yahoo.com": "https://mail.yahoo.com/",
};

export const Notification = ({ email }) => {
  const [dominio, setDominio] = useState(email);
  const [error, setError] = useState(true);

  useEffect(() => {
    if (email) {
      setDominio(email);
      const emailDomain = email.split("@")[1];
      if (!emailDomains[emailDomain]) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [email]);

  const redirection = (e) => {
    const emailDomain = dominio.split("@")[1];
    window.location.href = emailDomains[emailDomain];
  };

  return (
    <div className="flex flex-col place-content-center min-h-[100vh] content-center justify-center flex-wrap">
      <img
        className={`ml-[6px] w-full cursor-pointer max-h-[90px] mb-5`}
        src={Logo}
        alt="English 4 tech"
      />
      <div className="flex flex-col flex-wrap bg-white max-w-[350px] min-w-[350px] min-h-[590px] lg:max-w-[800px] lg:min-w-[800px] lg:min-h-[390px] rounded-xl content-center justify-center mx-auto">
        <div className="flex flex-col flex-wrap content-center justify-center mb-8 place-content-center">
          <IconDone className="mx-auto px-auto"/>
          <h1 className="my-auto text-4xl font-semibold text-center">
            ¡Te has Registrado con Éxito!
          </h1>
        </div>
        <div className="flex flex-col">
          <p className="text-[18px] px-10 text-pretty mb-2">
            Te hemos enviado un correo a {dominio} para que comiences tu test de
            nivel. Sigue estos pasos:
          </p>
          <p className="text-[18px] px-10 text-pretty mb-2">
            1. Revisa tu bandeja de entrada: Si no encuentras el correo, revisa
            "Promociones" o "Spam".
          </p>
          <p className="text-[18px] px-10 text-pretty mb-2">
            2. Haz tu test de nivel: Abre el enlace del correo y completa el
            test.
          </p>
          <p className="text-[18px] px-10 text-pretty mb-2">
            3. Recibe tus resultados: Te enviaremos los resultados y un enlace
            para acceder a la plataforma.
          </p>
          <p className="text-[18px] px-10 text-pretty mb-2">
            ¡Nos vemos en la plataforma!
          </p>
          {!error && (
            <button
              className="bg-[#2de678] text-blue-950 max-w-[150px] min-w-[150px] mx-auto rounded-md font-semibold"
              onClick={redirection}
            >
              Ir al correo
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

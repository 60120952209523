import axiosInstance from "./axiosInstance";
import { API_KEY, API_URL, USER_ID } from "./settings";

export default function postComment({ comment }) {
  const url = `Comentarios/Create/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  comment.CursoId = parseInt(comment.CursoId, 10)
  const body = JSON.stringify(comment);
  axios.post(url, body);
}

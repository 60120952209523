import axiosInstance from "./axiosInstance";
import { API_KEY, API_URL, USER_ID } from "./settings"

export const updateUser = async (user) => {
     const url = `${API_URL}/Usuario/Update/${API_KEY}/${USER_ID}`;
     const body = JSON.stringify(user)
     const axios = axiosInstance();
     try {
          const res = await axios.put(url, body);
          if (!res.ok) throw new Error(res)
          return {
               ok: true,
          }
     } catch (error) {
          console.log(error)
          return {
               ok: false,
               errorMessage: error.response.message | "Ha ocurrido un error",
          }
     }
}

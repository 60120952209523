export const useVideolessonsTable = (contentArray, bubbleSelectValue) => {
  const { materialEstudios: materials } = bubbleSelectValue;

  console.log(bubbleSelectValue);

  if (materials === undefined) {
    return { columns: [], rows: [] };
  }

  const defaultProps = {
    indicator: "",
    observations: "",
    noData: true,
  };

  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    {
      field: "videolesson",
      headerName: "Topics",
      sortable: false,
      width: 360,
    },
    {
      field: "indicator",
      headerName: "Indicator",
      width: 140,
    },
    {
      field: "observations",
      headerName: "Observations",
      sortable: false,
      width: 360,
    },
  ];

  const rows = materials.map(({ id, nombre: videolesson }) => {
    let found = false;

    if (contentArray) {
      found = contentArray.lessons.find((lesson) => lesson.lessonId === id);
    }

    if (found) {
      return {
        id,
        videolesson,
        indicator: found.indicator,
        observations: found.observations,
        noData: false,
        lessonId: found.id,
      };
    } else {
      return {
        id,
        videolesson,
        ...defaultProps,
      };
    }
  });

  return {
    columns,
    rows,
  };
};

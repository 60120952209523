import { SnackBarComponent } from "components/SnackBarComponent";
import { useState } from "react";
import { resetPassword } from "services/resetPassword";
import { useNavigate } from "react-router-dom";

export const ResetPasswordPage = ({ params }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("success");
    const minPasswordLength = 5;
    const passwordRegex = new RegExp(`^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{${minPasswordLength},})`);
    const passwordRegexMessage = `La contraseña debe tener al menos ${minPasswordLength} caracteres, una letra y un número.`;
    const passwordsMatch = (password === passwordConfirm);
    const navigate = useNavigate();

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordConfirm = (e) => {
        setPasswordConfirm(e.target.value);
    };
    const handleClose = () => {
        setIsSnackbarOpen(false);
    };

    const handleReset = async (e) => {
        e.preventDefault();
        let response = null;
        if (passwordRegex.test(password) && passwordsMatch) {
            setIsLoading(true);
            try {
                response = await resetPassword(params.secret, password);
                setSnackType("success");
                setSnackMessage(`Se ha restablecido la contraseña de tu cuenta.`);
                setIsSnackbarOpen(true);
                setTimeout(() => {
                    navigate("/");
                },3000);
            } catch (error) {
                setSnackType("error");
                const message = error.response?.data? error.response.data:"Se produjo un error al restablecer la contraseña, por favor intenta más tarde.";
                setSnackMessage(message);
                setIsSnackbarOpen(true);
            } finally {
                setIsLoading(false);
            }
        } else {
            setSnackType("error");
            setSnackMessage("Por favor verifica que las contraseñas coincidan y cumplan con los requisitos.");
            setIsSnackbarOpen(true);
            setIsLoading(false);
        }
    };

    return (
        <div className="flex h-screen w-screen items-center justify-center px-8">
            <form
                onSubmit={handleReset}
                className="bg-primary flex w-full min-w-[20rem] max-w-[30rem] flex-col items-center justify-start gap-5 rounded-2xl p-10 shadow"
            >
                <h1 className=" font-Barlow text-4xl text-white">
                    Digispeak
                </h1>
                <div className="flex flex-col gap-1 w-full">
                    <label className="font-Barlow text-white">Contraseña</label>
                    <input
                        className="rounded-md p-2 pt-1 w-full outline-none focus:outline-teal-500"
                        type="password"
                        name="password"
                        value={password}
                        onChange={handlePassword}
                    />
                    <label className="font-Barlow text-white">Confirmar contraseña</label>
                    <input
                        className="rounded-md p-2 pt-1 w-full outline-none focus:outline-teal-500"
                        type="password"
                        name="password_confirm"
                        value={passwordConfirm}
                        onChange={handlePasswordConfirm}
                    />
                </div>
                <div>
                    <p className="text-accent">
                        {(!passwordsMatch && password.length > 0 && passwordConfirm.length > 0) && "Las contraseñas no coinciden"}
                    </p>
                    <p className="text-accent">
                        {(!passwordRegex.test(password) && password.length > 0) && passwordRegexMessage}
                    </p>
                </div>
                {!isLoading ? (
                    <button
                        className="bg-accent font-Barlow text-primary flex h-11 items-center justify-center rounded-3xl p-2 px-4 pt-1 font-semibold mt-4"
                        type="submit"
                    >
                        Guardar contraseña
                    </button>
                ) : (
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </form>
            <SnackBarComponent
                isSnackbarOpen={isSnackbarOpen}
                handleClose={handleClose}
                message={snackMessage}
                severity={snackType}
            />
        </div>
    );
};

import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export default function getUser() {
  const url = `Usuario/GetById/${USER_ID}/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  return axios.get(url)
    .then(response => response.data)
    .then(data => JSON.stringify(data))
    .catch(error => {
      console.error('Error fetching user data:', error);
      throw error;
    });
}

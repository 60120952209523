import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef('');
    const [scrollPerformed, setScrollPerformed] = useState(false);
    const [scrollRetryCount, setScrollRetryCount] = useState(0);
    const maxRetryCount = 6;
    useEffect(() => {
        function setScrollTimeOut(hashId) {
            setTimeout(() => {                
                setScrollRetryCount(scrollRetryCount + 1);
                const itemToScroll = document.getElementById(hashId);
                if (itemToScroll) {                                        
                    itemToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    lastHash.current = '';
                    setScrollPerformed(true);
                } else {
                    if (!scrollPerformed && scrollRetryCount < maxRetryCount)
                        setScrollTimeOut(hashId);
                }
            }, 500);
        }
        
        if (location.hash) {
            lastHash.current = location.hash.slice(1);            
            setScrollTimeOut(lastHash.current);

        }
    }, [location]);

    return null;
}

export default ScrollToAnchor;
import axiosInstance from "./axiosInstance";
import { USER_ID, API_KEY } from "./settings";

export const getPlans = async (plan) => {
  const url = plan ? `PlanById/${plan}/${API_KEY}/${USER_ID}` : `Planes/${API_KEY}/1007`;
  const axios = axiosInstance();
  return axios.get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching plans:', error);
      throw error;
    });
};

import DashBoarMyCourses from "components/DashBoardMyCourses";

export default function DashboardSection() {
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));

  return (
    <section className="flex flex-col size-full h-full justify-start align-middle gap-5 p-5 pt-8 pb-14 md:p-10 xl:px-14 bg-[#1B212B]">
      <h2 className="text-[#7DB8F2] text-[26px] font-roboto font-[700]">
        Course Material
      </h2>
      <div className="flex flex-col lg:flex-row gap-5 mt-16">
        <DashBoarMyCourses ruta="/class-material" nombre="Class Material" />
        <DashBoarMyCourses
          ruta="/additional-material"
          nombre="Additional Material"
        />
      </div>
    </section>
  );
}

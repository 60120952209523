import { API_URL, USER_ID as TEACHER_ID } from "../../services/settings";

const basicCall = async (url, method, object) => {
  try {
    if (!method) {
      const res = await fetch(url);

      if (!res.ok) throw new Error(`Ha ocurrido un error`);

      const data = await res.json();

      return {
        ok: true,
        data,
      };
    } else if (method === "POST") {

      const res = await fetch(url, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(object),
      });

      if (!res.ok) throw new Error(res.status);

      const data = await res.json();

      return {
        ok: true,
        data,
      };
    } else if (method === "PUT") {
      const res = await fetch(url, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify(object),
      });

      if (!res.ok) throw new Error(res.status);

      const data = await res.text();

      return {
        ok: true,
        data,
      };
    } else if (method === "MULTIPART") {
      const formData = new FormData();
      formData.append('file', new Blob([object.buffer])); // Agrega el buffer como un Blob

      formData.append('studentId', object.studentId); // Agrega otros campos según sea necesario
      formData.append('cursoId', object.cursoId);
      const res = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!res.ok) throw new Error(res.status);

      const data = await res.text();

      return {
        ok: true,
        data,
      };
    }
  } catch (error) {
    console.log(error)
    return {
      ok: false,
      error: error.message,
    };
  }
};

export const updateRow = async (object, id) => {
  const url = `${API_URL}/FollowUp/${!id ? "createLesson" : "updateLesson"
    }/1234/${TEACHER_ID}`;

  let method = `${!id ? "POST" : "PUT"}`;

  if (id) {
    object.id = id;
  }

  return await basicCall(url, method, object);
};

export const updateBubble = async (object, id) => {
  const url = `${API_URL}/FollowUp/${!id ? "createBubble" : "updateBubble"
    }/1234/${TEACHER_ID}`;

  let method = `${!id ? "POST" : "PUT"}`;

  if (id) {
    object.id = id;
  }

  return await basicCall(url, method, object);
};

export const sendEmailPDFReport = async (data) => {


  const url = `${API_URL}/FollowUp/sendEmailReport/1234/${TEACHER_ID}`;


  return await basicCall(url, 'MULTIPART', data);

};

export const getAllByTeacher = async () => {
  const url = `${API_URL}/FollowUp/getAllByTeacher/1234/${TEACHER_ID}/${TEACHER_ID}`;

  return await basicCall(url);
};

import { useState, useEffect } from "react";
import { useLocation } from "wouter";
import { useParams, useNavigate } from "react-router-dom";
import postRegisterUser from "services/postRegisterUser";
import { useForm } from "hooks/useForm";
import { getPayment } from "services/getPayment";
import { ModalTest } from "components/ModalTest";
import { BubbleProfileRegister } from "components/BubbleProfileRegister";
import getCountries from "services/getCountries";

const initialForm = {
  birthday: null,
  phone: "",
  country: "",
  city: "",
  occupation: "",
  password: "",
  location: "",
  name: "",
};

export default function ExternalRegisterPage({ params }) {
  const englishTestBaseURL = process.env.REACT_APP_DIGISPEAK_ENGLISH_TEST_URL;
  const landingPageURL = process.env.REACT_APP_DIGISPEAK_LANDING_URL;
  const { formState, onInputChange } = useForm(initialForm);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [staticInfo, setStaticInfo] = useState({
    name: "",
    email: "",
    plan: "",
    country: null,
  });
  const [userId, setUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [location, setLocation] = useLocation();
  const navigate = useNavigate();

  const onUploadingImageChange = () => {
    setIsUploadingImage(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (formState.password === "") {
        return alert(`Por favor completa los campos`);
      }

      if (staticInfo.name === "") {
        if (formState.name === "") {
          return alert(`Por favor completa los campos`);
        }
        if (formState.name.split(" ").length < 2) {
          return alert("El nombre debe contener nombre y apellido");
        }
      }

      const userInfo = {
        code: params.paymentId,
        name: staticInfo.name ? staticInfo.name : formState.name,
        birthday: formState.birthday,
        phone: formState.phone,
        country: staticInfo.country.paisId,
        city: formState.city,
        occupation: formState.occupation,
        email: staticInfo.email,
        password: formState.password,
        plan: staticInfo.plan,
        country: staticInfo.country.id,
        photo: "", // utilizar endpooint api/Usuario/UploadProfilePhoto/{code} para cargar la foto
      };

      setLoading(true);
      const res = await postRegisterUser(userInfo);
      setUserId(res.id);
      setLoading(false);
      if (!isUploadingImage) {
        setLocation("/");
      }
      navigate(`/`);
    } catch (error) {
      navigate(`/`);       
      /**** FIXME
      esto es confuso, se va a redireccionar a la home si hay un error el usuario no se entera, deberia informarce mediante un toast (o snackbar)
       ****/
    } finally {
      setLoading(false);
    }
  };

  const getPaymentInfo = async () => {
    const payment = await getPayment(params.paymentId);
    if (payment === "El id del pago no esta asociado a ningun pago") {
      setIsModalErrorOpen(true);
      return;
    }
    if (!payment.testInfo) {
      setIsModalOpen(true);
      setUrl(`${englishTestBaseURL}/${payment.paymentInfo.code}`);
      return;
    }
    const paymentInfo = payment.paymentInfo;
    const selectedCountry = countries.find((country) => country.id === paymentInfo.countryId)
    setStaticInfo({
      ...staticInfo,
      name:  `${paymentInfo.nombre} ${paymentInfo.apellido}`,        
      email: paymentInfo.email,
      plan: paymentInfo.itemId,
      country: selectedCountry,
    });

  };
  useEffect(() => {
    getCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  useEffect(() => {
    getPaymentInfo()
  }, [countries]);


  return (
    <>
      <div className="flex min-h-screen w-screen items-center justify-center bg-gray-100 pt-10 pb-10">
        <div className="flex flex-wrap min-w-[20rem] flex-col items-center justify-items-center justify-center rounded-xl border-gray-200 bg-white p-5 px-10 shadow-sm relative">
          <h1 className=" font-Barlow text-primary m-1 text-5xl font-medium">
            Digi<span className="text-accent"></span>speak
          </h1>
          <h2 className="m-1 text-2xl">Registro de Alumno</h2>
          <span className="pb-5">
            Completa tus datos y crea tu usuario y contraseña para acceder al
            portal.
          </span>
          <div className="flex flex-row flex-wrap justify-center content-center"></div>
          <BubbleProfileRegister
            id={userId}
            redirect={true}
            onUploadingImageChange={onUploadingImageChange}
          />
          <form
            className="mt-2 flex w-full flex-col gap-1"
            onSubmit={handleSubmit}
          >
            <label className="font-medium">Nombre y Apellido</label>
            <input
              className="min-h-[2rem] rounded-sm border border-gray-200 px-2"
              type="text"
              name="name"
              value={staticInfo.name ? staticInfo.name : formState.name}
              placeholder="Escribe tu nombre y apellido"
              onChange={onInputChange}
              required
            />
            <label className="font-medium">Pais</label>
            <input
              className={`min-h-[2rem] rounded-sm border px-2`}
              type="text"
              name="country"
              value={
                staticInfo.country ? staticInfo.country.nombre : "..."
              }
              disabled
            />
            <label className="font-medium">Email</label>
            <input
              className={`min-h-[2rem] rounded-sm border px-2`}
              type="email"
              name="email"
              value={staticInfo.email}
              disabled
            />
            <label className="font-medium">Contraseña</label>
            <input
              className="min-h-[2rem] rounded-sm border border-gray-200 px-2"
              type="password"
              name="password"
              value={formState.password}
              onChange={onInputChange}
              required
            />
            <div className="flex w-full justify-center">
              <button
                disabled={loading}
                className="disabled:bg-gray-300 bg-accent font-Barlow text-primary mt-5 flex h-11 w-40 items-center justify-center rounded-3xl p-2"
              >
                Registrarme
              </button>
            </div>
          </form>
        </div>
      </div>
      <ModalTest
        url={url}
        isModalOpen={isModalOpen}
        message={`Parece que no has hecho el test`}
      />
      <ModalTest
        url={`${landingPageURL}`}
        isModalOpen={isModalErrorOpen}
        message={`No existe información de pago`}
      />
    </>
  );
}

import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { SelectOptions } from "components/SelectOptions";
import { SnackBarActions } from "components/SnackBarActions";
import { CoursesContext } from "context/coursesContext";
import { useForm } from "hooks/useForm";
import Select from "react-select";
import { getAllUsersAsync } from "services/getAllUsersAsync";
import { getModuleByCourseIdAsync } from "services/getModuleByCourseIdAsync";
import { postAttendance } from "services/postAttendance";
import { USER_ID } from "services/settings";
import { processAttendance } from "utils/processAttendance";
import { blue, grey } from "@mui/material/colors";

export const initialClassOption = [...Array(24)].map((a, i) => ({
  label: `Clase ${i + 1}`,
  value: i + 1,
}));

const initialForm = {
  observaciones: "",
  date: "",
};

const initialSelected = {
  profesorSelected: "",
  moduloSelected: "",
  courseSelected: "",
  classSelected: "",
  presentSelected: "",
};

const initialList = {
  moduleList: [],
  studentsList: [],
  professorsList: [],
};

const initialSnackBar = {
  isSnackBarOpen: false,
  severity: "success",
  message: "El material de estudio ha sido editado exitosamente!!",
};

const errorSnackbar = {
  isSnackBarOpen: true,
  severity: "error",
  message: "Ha ocurrido un error",
};

const customSelectStyles = {
  input: (provided, state) => ({
    ...provided,
    height: "47px",
    outline: state.isHovering ? "1px solid #000" : "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 500,
  }),
};

export const AddAsistancesPage = () => {
  const { date, onInputChange, onResetForm } = useForm(initialForm);
  const { courses } = useContext(CoursesContext) || [];
  const [valueSelected, setValueSelected] = useState(initialSelected);
  const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);
  const [selectsData, setSelectsData] = useState(initialList);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const onValueSelected = (e, selector) => {
    setValueSelected({
      ...valueSelected,
      [selector]: e.target.value,
    });
  };

  const closeSnackbar = () => {
    setSnackBarInfo({
      ...snackBarInfo,
      isSnackBarOpen: false,
    });
  };

  const coursesParsed = useMemo(() => {
    return courses.map((course) => ({
      label: course.nombre,
      value: course.id,
    }));
  }, [courses]);

  const getData = async () => {
    const { users } = await getAllUsersAsync();
    const professors = users
      .filter(({ user }) => user.rol === "Profesor")
      .map(({ user }) => user);
    const students = users
      .filter(({ user }) => user.rol === "Alumno")
      .map(({ user }) => user);
    const professorsParsed = professors.map((prof) => ({
      label: prof.nombre,
      value: prof.id,
    }));
    const studentsParsed = students.map((student) => ({
      label: `${student.nombre} - ${student.email}`,
      value: student.id,
      presente: false,
      observaciones: "",
    }));
    setSelectsData({
      ...selectsData,
      studentsList: studentsParsed,
      professorsList: professorsParsed,
    });
  };

  const getModules = async (id) => {
    setValueSelected({
      ...valueSelected,
      moduloSelected: "",
      classSelected: "",
    });

    const { modulos } = await getModuleByCourseIdAsync(id);
    const modulesParsed = modulos.map((mod) => ({
      label: mod.nombre,
      value: mod.id,
    }));

    // console.log(modulesParsed);
    setSelectsData({
      ...selectsData,
      moduleList: modulesParsed,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      valueSelected.classSelected === "" ||
      valueSelected.courseSelected === "" ||
      valueSelected.moduloSelected === "" ||
      selectedStudents.length === 0 ||
      date === ""
    ) {
      setSnackBarInfo({
        ...errorSnackbar,
        message: "Por favor completa los datos",
      });
      return;
    }

    let listOfStudentMinusLabel = selectedStudents.map((obj) => {
      let newObj = {
        AlumnoId: obj.value,
        Presente: obj.presente,
        Observaciones: obj.observaciones,
      };

      return newObj;
    });

    // console.log(listOfStudentMinusLabel);

    const res = await postAttendance(
      processAttendance({
        ...valueSelected,
        listOfStudentMinusLabel,
        profesorSelected: USER_ID,
        date,
      })
    );
    if (!res.ok) {
      setSnackBarInfo({ ...errorSnackbar, message: res.errorMessage });
      return;
    }
    setSnackBarInfo({
      ...initialSnackBar,
      isSnackBarOpen: true,
      message: "Asistencia creada exitosamente!!!",
    });

    onResetForm();
    setValueSelected(initialSelected);
    setSelectedStudents([]);
  };

  useEffect(() => {
    if (valueSelected.courseSelected.length <= 0) return;
    getModules(valueSelected.courseSelected);
    //eslint-disable-next-line
  }, [valueSelected.courseSelected]);

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  const handleChangeStudents = (e) => {
    let repeated = false;

    for (let i = 0; i < selectedStudents.length; i++) {
      if (selectedStudents[i].value === e.value) {
        repeated = true;
        break;
      }
    }

    // Agregar
    if (!repeated) {
      setSelectedStudents([...selectedStudents, e]);
    }
    // Quitar
    else {
      setSelectedStudents(
        selectedStudents.filter((student) => student.value !== e.value)
      );
    }
  };

  const handleAttendance = (index) => {
    let copy = selectedStudents.slice();
    let p = copy[index].presente;

    copy[index].presente = !p;

    setSelectedStudents(copy);
  };

  const handleObservations = (index, e) => {
    let copy = selectedStudents.slice();

    copy[index].observaciones = e.target.value;

    setSelectedStudents(copy);
  };

  return (
    <div className="w-full grid grid-cols-[2fr_3fr] p-5">
      <div className="flex flex-col py-2 px-6 w-full">
        <strong className="text-gray-500 mb-4 font-medium text-base">
          Clase
        </strong>
        <Box
          component={"form"}
          width={"100%"}
          maxWidth={500}
          sx={{ pb: 10 }}
          onSubmit={onSubmit}
        >
          <Grid container>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Select
                options={selectsData.studentsList}
                placeholder="Alumnos"
                label="Alumnos"
                styles={customSelectStyles}
                multiple
                value={selectedStudents}
                onChange={(e) => {
                  handleChangeStudents(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <SelectOptions
                options={coursesParsed}
                value={valueSelected.courseSelected}
                handleSelect={(e) => onValueSelected(e, "courseSelected")}
                label={"Curso"}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <SelectOptions
                options={selectsData.professorsList}
                label={"Profesor"}
                value={selectsData.professorsList.length <= 0 ? "" : USER_ID}
                handleSelect={(e) => onValueSelected(e, "profesorSelected")}
                disabled={true}
              />
            </Grid>
            {/* <Grid item xs={12} sx={{ my: 1 }}>
              <SelectOptions
                options={optionPresence}
                label={"Asistencia"}
                value={valueSelected.presentSelected}
                handleSelect={(e) => onValueSelected(e, "presentSelected")}
              />
            </Grid> */}
            {selectsData.moduleList.length > 0 && (
              <Grid item xs={12} sx={{ my: 1 }}>
                <SelectOptions
                  options={selectsData.moduleList}
                  label={"Content Bubble"}
                  value={valueSelected.moduloSelected}
                  handleSelect={(e) => onValueSelected(e, "moduloSelected")}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ my: 1 }}>
              <TextField
                type={"date"}
                name="date"
                value={date}
                onChange={onInputChange}
                sx={{ backgroundColor: "#fff" }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <SelectOptions
                options={initialClassOption}
                label={"Clase"}
                value={valueSelected.classSelected}
                handleSelect={(e) => onValueSelected(e, "classSelected")}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1, mt: 4 }}>
              <Button variant="outlined" size={"large"} type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="flex flex-col py-2 px-6 w-full">
        <strong className="text-gray-500 mb-6 font-medium text-base">
          Alumnos seleccionados
        </strong>
        {selectedStudents.length > 0 &&
          selectedStudents.map((student, index, thisArray) => {
            return (
              <div
                key={student.label + index}
                className="border border-gray-300 p-4 pt-1 bg-white rounded mb-2"
              >
                <div className="flex items-center justify-between">
                  <p className="text-base">{student.label}</p>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisArray[index].presente}
                          onChange={() => {
                            handleAttendance(index);
                          }}
                          sx={{
                            color: grey[500],
                            "&.Mui-checked": {
                              color: blue[700],
                            },
                          }}
                        />
                      }
                      label="Presente"
                      sx={{
                        "& .MuiTypography-root": { fontSize: 14 },
                      }}
                    />
                  </FormGroup>
                </div>
                <TextField
                  id="standard-basic"
                  label="Observaciones"
                  variant="standard"
                  size="small"
                  className="w-full"
                  value={thisArray[index].observaciones}
                  onChange={(e) => {
                    handleObservations(index, e);
                  }}
                  sx={{
                    "& .MuiInputBase-input, & .MuiFormLabel-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </div>
            );
          })}
      </div>

      <SnackBarActions handleSnackbar={closeSnackbar} {...snackBarInfo} />
    </div>
  );
};

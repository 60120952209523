import React, { useEffect, useRef, useState } from "react";

import Question from "./Question";

const StandaloneQuestion = ({
  index,
  question,
  goForward,
  goBack,
  topToBottom,
  disabled,
  loading = false,
  error,
  screen,
}) => {
  const thisElement = useRef(null);
  const [height, setHeight] = useState(568);

  //To calculate the size of each screen
  useEffect(() => {
    setHeight(thisElement.current.clientHeight);

    setTimeout(() => {
      setHeight(thisElement.current.clientHeight);
    }, 100);
  }, [thisElement]);

  return (
    <div
      className="screen standalone-question"
      style={{
        transform: `translateY(calc(${height * (index - 1)}px))`,
        zIndex: topToBottom
          ? index === 0
            ? 50
            : index === 1
            ? 100
            : 0
          : index === 0
          ? 0
          : index === 1
          ? 100
          : 50,
      }}
      ref={thisElement}
    >
      <div className="top-container">
        <div className="modal">
          {/* <Logo className="logo" /> */}

          <div className="container">
            <Question
              data={question}
              goForward={goForward}
              goBack={goBack}
              disabled={disabled}
              loading={loading}
              screen={screen}
            />
          </div>

          {error && (
            <span className="error-msg">
              An unexpected error occurred, please check your connection and try
              again...
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StandaloneQuestion;

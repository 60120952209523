import React from "react";

import RadioType from "./RadioType";

const ImageType = ({ data }) => {
  return (
    <div className="image-type">
      <strong>Look at the image and choose the correct option</strong>

      <div className="image-container">
        <img src={data.image_URL} alt="question" />
      </div>

      <RadioType data={data} />
    </div>
  );
};

export default ImageType;

import { Button, Grid, Modal, Typography } from "@mui/material"
import { UIContext } from "context/UIContext"
import { useContext } from "react"

export const BasicPlanModal = () => {
     const { isModalOpen, closeModal } = useContext(UIContext)
     const userInfo = JSON.parse(localStorage.getItem("loggedAppUser"))
     return (
          <Modal open={isModalOpen} sx={modalStyles} onClose={closeModal}>
               <Grid sx={boxContainer}>
                    <Typography sx={{ fontFamily: `'Barlow', sans-serif` }}>
                         Para disfrutar de esta función actualiza tu suscripción
                    </Typography>
                    <a href={`https://www.digispeak.io/?email=${userInfo.email}&plan=${userInfo.id}&user=${userInfo.id}`} target='_blank' rel='noopener noreferrer'>
                         <Button variant='contained' sx={{ backgroundColor: "#00001c", borderRadius: '10px' }}>
                              Actualizar suscripción
                         </Button>
                    </a>
               </Grid>
          </Modal>
     )
}

const boxContainer = {
     backgroundColor: "#fff",
     p: 5,
     ":focus": { outline: "none" },
     borderRadius: "10px",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     gap: 2,
}

const modalStyles = {
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     minHeight: "100vh",
}

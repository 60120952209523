import { Alert, Snackbar } from "@mui/material"

export const SnackBarComponent = ({
     message,
     isSnackbarOpen,
     handleClose,
     severity = "success",
     autoHideDuration = 6000,
}) => {
     const horizontal = "right"
     const vertical = "bottom"
     return (
          <Snackbar
               open={isSnackbarOpen}
               onClose={handleClose}
               anchorOrigin={{ horizontal, vertical }}
               autoHideDuration={autoHideDuration}
               sx={{ width: "300px" }}
          >
               <Alert
                    severity={severity}
                    sx={{
                         width: "100%",
                         backgroundColor: `${severity === "success" ? "#b2ffb5" : "#ef9a9a"}`,
                    }}
               >
                    {message}
               </Alert>
          </Snackbar>
     )
}

import { useContext } from "react";
import { SideBarContext } from "context/sideBarContext";
import { Link } from "wouter";
import Logo from "../../assets/DigiSpeak.svg";
import MiniLogo from "../../assets/white-digi-small.svg";

import SideNav from "../SideNav";
import SideNavIcon from "components/SideNavIcons";
import { SizeContext } from "context/SizeContext";

export default function SideBar() {
  const { isOpen, setIsOpen } = useContext(SideBarContext);
  const { handleShowBar, showBar } = useContext(SizeContext);

  return (
    <>
      <aside
        className={`fixed top-0 left-0 z-30 hidden h-screen transition-[width] overflow-y-auto chatHiddenScroll ${
          showBar ? "w-60" : "w-12 "
        } bg-[#1B212B] flex-col bg-gradient-to-b md:flex`}
      >
        <header
          className={`mb-4 p-3 pt-4 ${
            showBar ? "" : "flex flex-col "
          } icon-container`}
        >
          <ion-icon name="menu" onClick={handleShowBar}></ion-icon>
          <Link href="/" className="text-5xl font-light text-[#7DB8F2]">
            <img
              className={`${
                showBar ? `ml-[6px]` : `w-6`
              } w-full cursor-pointer max-h-[90px]`}
              src={showBar ? Logo : MiniLogo}
              alt="English 4 tech"
            />
          </Link>
        </header>
        <SideNav isOpen={showBar} />
      </aside>

      <aside
        className={`bg-[#1B212B] fixed top-0 left-0 z-30 flex h-screen w-60 flex-col bg-gradient-to-b ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } duration-300 ease-in-out`}
      >
        <header className="flex justify-center mb-4 p-3 text-center">
          <button
            onClick={() => setIsOpen(false)}
            className="flex justify-center items-center mr-2"
          >
            <SideNavIcon name="close" />
          </button>
          <Link href="/" className="text-4xl font-light text-white">
            i<span className="text-accent">.</span>speak
          </Link>
        </header>
        <SideNav />
      </aside>
    </>
  );
}

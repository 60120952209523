import React, { useEffect, useRef, useState } from "react"

import Logo from "../../../assets/digispeak.png"

const Instructions = ({ screen, goForward, index = null, goPrevious }) => {
     const thisElement = useRef(null)
     const [height, setHeight] = useState(568)

     //To calculate the size of each screen
     useEffect(() => {
          setHeight(thisElement.current.clientHeight)

          setTimeout(() => {
               setHeight(thisElement.current.clientHeight)
          }, 100)
     }, [thisElement])

     return (
          <div
               className='screen instructions'
               style={{
                    transform: `translateY(calc(${
                         (index !== null
                              ? index === 0
                                   ? screen
                                   : index === 1
                                   ? screen - 2
                                   : screen
                              : screen - 1) * -1
                    } * ${height}px))`,
                    zIndex:
                         index === null
                              ? screen === 1
                                   ? 100
                                   : 0
                              : index === 0
                              ? index === screen
                                   ? 100
                                   : 0
                              : index + 1 === screen
                              ? 100
                              : 0,
               }}
               ref={thisElement}
          >
               <div className='top-container'>
                    {index === 1 && (
                         <div className='back-icon-container' onClick={goPrevious}>
                              <ion-icon name='arrow-back-outline'></ion-icon>
                         </div>
                    )}
                    <img src={Logo} alt="digispeak" width={200} style={{marginBottom: 12}} />

                    {index === null ? (
                         <p>
                              Ahora vienen las preguntas, responde a cada una{" "}
                              <strong>SIN el uso de ningún traductor!</strong>
                         </p>
                    ) : index === 0 ? (
                         <p>
                              Bravo! Has terminado el cuestionario, pero aún no acabamos, a
                              continuación deberás seleccionar una fecha para tu cita diagnóstico
                              con un <strong>profesor en vivo.</strong>
                         </p>
                    ) : index === 1 ? (
                         <div className='text'>
                              <p>
                                   ¡Enhorabuena! Ya has agendado tu entrevista en vivo. Hemos
                                   enviado a tu correo la confirmación de esta reunión.
                              </p>
                              <p>
                                   Los resultados del «Test teórico» se enviarán a tu correo junto
                                   al link de registro para que crees tu cuenta.
                              </p>
                         </div>
                    ) : (
                         <div className='text'>
                              <p>Bravo! Has completado todo.</p>
                              <p>
                                   Los resultados del cuestionario se enviarán a tu{" "}
                                   <strong>correo</strong>, no te olvides de revisarlo!
                              </p>
                         </div>
                    )}

                    {(index === null || index === 0) && (
                         <button
                              className='button'
                              tabIndex={
                                   index === null
                                        ? screen === 1
                                             ? 0
                                             : -1
                                        : screen === index
                                        ? 0
                                        : -1
                              }
                              onClick={() => {
                                   goForward()
                              }}
                         >
                              Continuar
                         </button>
                    )}
               </div>
          </div>
     )
}

export default Instructions

import { CoursesContext } from "context/coursesContext"
import { useEffect, useState } from "react"
import { useContext } from "react"

export const useAccess = () => {
     const { userPlan } = useContext(CoursesContext)
     const [isBasic, setIsBasic] = useState(false)

     useEffect(() => {
          if (userPlan?.nombre === "Basic") {
               setIsBasic(true)
          } else {
               setIsBasic(false)
          }
     }, [userPlan])

     return { isBasic }
}

import { Link } from "wouter"
import BubbleDone from "../../assets/burbuja-done.svg"
import BubblePending from "../../assets/burbuja-pending.svg"
export const BubbleModules = ({ id, nombre, clases, url, course, module, index }) => {
     const bubbleCompleted = (clases) =>{
          let completed = true;
          clases.forEach((clase)=> {if (!clase.completada) completed=false});
          return completed;
     }
     return (
          <>
               {!clases[0]?.id && index === 1 && <p>No hay material</p>}
               {clases[0]?.id && (
                    <Link
                         to={`/${url}/${course.id}/module/${id}/material/${clases[0].id}/${module.id}`}
                    >
                         <div className='mt-5 mb-5 cursor-pointer w-[200px] relative hover:scale-105 transition-all show-peace-page'>
                              <div className='flex'>
                                   <h4 className='font-Barlow font-semibold text-[#1B212B] text-sm absolute bubble-text text-center pr-5 pl-5'>
                                        {nombre}
                                   </h4>  
                                   <img src={bubbleCompleted(clases) ? BubbleDone:BubblePending} className='bubble-img' />            
                              </div>
                         </div>
                    </Link>
               )}
          </>
     )
}

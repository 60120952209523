import jsPDF from "jspdf";
import { sendEmailPDFReport } from "./calls";
import {
  useBubbleObservations,
  useReportFiltering,
} from "./useReportFiltering";
require("jspdf-autotable");

const props = {
  headStyles: {
    fillColor: "#efefef",
    valign: "middle",
    halign: "center",
    fontStyle: "normal",
  },
  theme: "plain",
  margin: { top: 36 },
  styles: {
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
    fontSize: 9,
    cellPadding: 1,
    valign: "middle",
  },
};

const generatePDF = (user, data, course, onlyBuffer = false) => {
  // console.log(course);
  const courseName = data.nombre;

  const doc = new jsPDF();
  doc.setFont("helvetica", "normal", "bold");
  doc.setFontSize(9);
  doc.text(`Student`, 14, 14);
  doc.text(`${user.nombre} (${user.email})`, 40, 14);
  doc.text(`LEVEL`, 14, 20);
  doc.text(`${courseName}`, 40, 20);

  // ------------------------

  let counter = 0;

  data.bubbles.forEach((bubble) => {
    const GodsPlan = course.getmodulos.find((modulo) => {
      return modulo.id === bubble.bubbleId;
    });

    // console.log(GodsPlan);

    const { columns, rows } = useReportFiltering(bubble, GodsPlan);
    // console.log(columns, rows);

    doc.autoTable({
      ...props,
      body: rows,
      columns: columns.map((col) => ({
        header: col.headerName,
        dataKey: col.field,
      })),
      columnStyles: {
        0: { cellWidth: 14, halign: "center" },
        1: { cellWidth: 50 },
        2: { cellWidth: 18, halign: "center" },
        3: { cellWidth: "auto" },
      },
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(9);

        if (counter === 0) {
          doc.text(
            GodsPlan.nombre,
            data.settings.margin.left,
            data.settings.margin.top - 3
          );
        } else {
          doc.text(
            GodsPlan.nombre,
            data.settings.margin.left,
            doc.autoTable.previous.finalY + 10
          );
        }
      },
      ...(counter !== 0 && { startY: doc.lastAutoTable.finalY + 13 }),
    });

    // Bubble observations

    const { finalColumns, finalRows } = useBubbleObservations(bubble);

    doc.autoTable({
      ...props,
      columns: finalColumns.map((col) => ({
        header: col.headerName,
        dataKey: col.field,
      })),
      body: [finalRows],
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: 20, halign: "center" },
      },
      startY: doc.lastAutoTable.finalY,
    });

    counter += 1;
  });

  if (!onlyBuffer) {
    doc.save("report.pdf");
    return;
  } else {


    return doc.output('arraybuffer');

  }


};

const sendReportPDF = async (user, data, course) => {


  // eslint-disable-next-line no-undef
  Swal.showLoading();



  let buffer = generatePDF(user, data, course, true);





  let response = await sendEmailPDFReport({ studentId: user.id, cursoId: course.id, buffer });
  if (response.ok) {
    // eslint-disable-next-line no-undef
    Swal.fire({

      title: 'Report sent',

      html: 'The report has been sent',

      allowOutsideClick: false,

      //color boton

      confirmButtonColor: "#1976d2",

      onBeforeOpen: () => {
        // eslint-disable-next-line no-undef
        Swal.hideLoading()

      },

    });

  } else {

    // eslint-disable-next-line no-undef
    Swal.fire({

      title: 'Error',

      html: 'There was an error sending the report',

      allowOutsideClick: false,
      confirmButtonColor: "#1976d2",
      onBeforeOpen: () => {
        // eslint-disable-next-line no-undef
        Swal.hideLoading()

      },

    });

  }





}
export {
  generatePDF,
  sendReportPDF
}
import { useState } from "react";
import postComment from "services/postComment";
import Reply from "./Reply";

function Comment({ comment, userId, materialId, courseId }) {
  const [isActive, setIsActive] = useState(false);
  const [replyIsActive, setReplyActive] = useState(false);
  const [replies, setReplies] = useState(comment.respuestas);
  const [reply, setReply] = useState("");
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));

  const handleChange = (event) => {
    setReply(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (comment) {
      const data = {
        UsuarioId: userId,
        MaterialId: materialId,
        CursoId: parseInt(courseId, 10),
        Comentario: reply,
        ComentarioId: comment.id,
      };
      const update = [...replies];
      update.push({
        id: 999999,
        alumno: user.nombre,
        fecha: "",
        respuestas: [],
        comentario: reply,
      });
      setReplies(update);
      setIsActive(true);
      postComment({ comment: data });
    }
  };

  return (
    <div
      key={comment.id}
      className="w-full max-w-3xl rounded-lg border-2 border-[#CF7831] bg-transparent p-5 shadow-sm"
    >
      <header className="border-[#7DB8F2] flex justify-between border-b-4 p-2">
        <div>
          <img url={comment.imagen} alt="" />
          <span className="text-[#7DB8F2] font-semibold">{comment.alumno}</span>
        </div>
        <span>{comment.fecha}</span>
      </header>
      <p className="p-2 text-white">{comment.comentario}</p>
      <button
        onClick={() => setReplyActive(!replyIsActive)}
        className="text-[#7DB8F2] pl-5 font-semibold"
      >
        reply
      </button>
      <button
        className="text-[#7DB8F2] pl-5 font-semibold"
        onClick={() => setIsActive(!isActive)}
      >
        comments ({replies.length})
      </button>
      {isActive && replies.map((reply) => <Reply key={reply.id} reply={reply} />)}
      {replyIsActive && (
        <form
          onSubmit={handleSubmit}
          className="mt-2 w-full max-w-3xl rounded-xl border border-gray-200 bg-white p-5 shadow-sm"
        >
          <textarea
            onChange={handleChange}
            type="textarea"
            value={reply}
            className="h-20 w-full rounded-xl border border-gray-400 p-1"
          />
          <button className="bg-accent text-primary m-1 rounded-lg p-2">
            Reply
          </button>
        </form>
      )}
    </div>
  );
}

export default Comment;

import { createContext, useState, useEffect } from "react";
import { getPlans } from "services/getPlans"
import { getUserAsync } from "services/getUserAsync"
import getAllCoursesByUser from "services/getAllCoursesByUser"
import { USER_DATA } from "services/settings"

export const CoursesContext = createContext(null);

export const CoursesProvider = ({ children }) => {
    const [courses, setCourses, ] = useState([]);
    const [userPlan, setUserPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getInfo = async () => {
        const user = await getUserAsync();                        
        const plan = await getPlans(user.plan);
        setUserPlan(plan);
    };

    useEffect(() => {
        const fetchCourses = async () => {
            setIsLoading(true);
            try {
                const response = await getAllCoursesByUser(USER_DATA.id);
                setCourses(response);
            } catch (error) {
                console.error("Error fetching courses:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCourses();
        getInfo();
    }, [USER_DATA.id]);

    return (
        <CoursesContext.Provider value={{ courses, setCourses, userPlan, isLoading }}>
            {children}
        </CoursesContext.Provider>
    );
};

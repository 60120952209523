export const allTests = [
  //Junior
  {
    id: 50641,
    questions: [
      null,
      {
        id: 1,
        question:
          "There are two types of articles in English, the indefinite article and the definite article. Based on this, choose the correct option:",
        options: [
          {
            id: 1,
            text: "I graduated as an IT architect, but now I am interested in working as a project manager. Fortunately, there is a company looking for a PM and the interview is in March.",
          },
          {
            id: 2,
            text: "I graduated as IT architect, but now I am interested in working as project manager. Fortunately, there is a company looking for PM and interview is in March.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        question: "The meaning of meticulous is:",
        options: [
          {
            id: 1,
            text: "Very careful and with great attention to every detail",
          },
          {
            id: 2,
            text: "To be the person who caused something to happen, especially something bad",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        question:
          "According to what you learned about demonstrative determiners, choose the correct option:",
        options: [
          {
            id: 1,
            text: "This and that are singular. These and those are plural. We use that and those to show things that are far away, whereas this and these are used to show things that are close to the speaker.",
          },
          {
            id: 2,
            text: "This and these are plural. That and Those are singular. We use This and These to show things that are far away, whereas that and those are used to show things that are close to the speaker.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        question: "Choose the correct sentence:",
        options: [
          {
            id: 1,
            text: "These is your office and that are the members of your team. This are the IT members.",
          },
          {
            id: 2,
            text: "This is your office and those are the members of your team. These are the IT members.",
          },
          {
            id: 3,
            text: "These is your office and that are the members of your team. Those are the IT members.",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        question:
          "Choose the correct option based on the correct order of the adjectives:",
        options: [
          {
            id: 1,
            text: "My PM wants to buy a small, new, round, Argentinian, glass desk.",
          },
          {
            id: 2,
            text: "My PM wants to buy an Argentinian new desk that is round and made of glass.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        question: `Choose the grammatically correct sentence:`,
        options: [
          {
            id: 1,
            text: "Peter is a scrum master. He is in charge of ensuring all agile practices are followed by the team members. But sometimes, as a developer, I am in charge of leading the daily scrum. We really make a good team!",
          },
          {
            id: 2,
            text: "Peter is a scrum master. She is in charge of ensuring all agile practices are followed by the team members. But sometimes, as a developer, I am in charge of leading the daily scrum. We really make a good team!",
          },
          {
            id: 3,
            text: "Peter is a scrum master. He is in charge of ensuring all agile practices are followed by the team members. But sometimes, as a developer, I am not in charge of leading the daily scrum. They really makes a good team!",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 7,
        question: "Choose the numbers that are written correctly:",
        options: [
          {
            id: 1,
            text: "nineteen, ninety, forty, fourteen, one hundred, eight hundred and fifty",
          },
          {
            id: 2,
            text: "nineten, ninety, forty, forten, hundred, eight hundred and fifty",
          },
          {
            id: 3,
            text: "nineteen, ninety, forty, forteen, a hundred, eight hundred fifty",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        question: `Choose the correct words to complete the following sentence:
        \n
        As I am an ____, I have a ____ job. I need to meet tight ____ and I always have ____ and monthly meetings.`,
        options: [
          { id: 1, text: "accountant, home, budget, daily" },
          { id: 2, text: "app developer, demanding, deadlines, daily" },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 9,
        question: "Choose the grammatically correct sentence:",
        options: [
          {
            id: 1,
            text: "I am Peter. My job is to design and program web pages. From Monday to Friday, I work with Steven. He is a QA developer and his main tasks are developing and running new tests and reporting their results to stakeholders.",
          },
          {
            id: 2,
            text: "I am Peter. My job is to design and program web pages. From Monday to Friday, I work with Steven. She is a QA developer and his main tasks are developing and running new tests and reporting its results to stakeholders.",
          },
          {
            id: 3,
            text: "I am Peter. My job is to design and program web pages. From Monday to Friday, I work with Steven. He is a QA developer and her main tasks are developing and running new tests and reporting their results to stakeholders.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        question: `Read carefully the following conversation and choose the grammatically correct one:`,
        options: [
          {
            id: 1,
            text: `Peter is looking for a new job. He has a virtual interview at 2 pm. His recruiter is Miss Maria. He connects through Zoom, and as he wants to be polite, he says "Good afternoon, Ms. Maria, pleased to meet you, how do you do?" Maria replies "very well, thank you" and he goes on to have a small talk "Great, where are you located?"`,
          },
          {
            id: 2,
            text: `Peter are looking for a new job. He has a virtual interview at 2 pm. Her recruiter is Mr Maria. He connects through Zoom, and as he wants to be polite, he say "Good morning, Mr Maria, great to meet you, how do you do?" Maria replies "very well, thank you" and he goes on to have a small talk "Great, where are located?"`,
          },
          {
            id: 3,
            text: `Peter is looking for a new job. He have a virtual interview at 2 pm. Her recruiter is Mr Maria. He connect through Zoom, and as he want to be polite, he say "Good evening, Mr Maria, great to meet you, how do you do?" Maria replies "very well, thank you" and he goes on to have a small talk "Great, where you from?"`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50642,
    questions: [
      null,
      {
        id: 1,
        question:
          "Choose the grammatically correct questions: wh-questions, using the verb to be:",
        options: [
          {
            id: 1,
            text: `Peter needs to arrange a meeting with the PM, so he asks him: "Are you available next Friday at 4 pm?" The PM says: "I am afraid I am not available on Friday. When is our next monthly meeting? What is the issue?"`,
          },
          {
            id: 2,
            text: `Peter needs to arrange a meeting with the PM, so he asks him: "You are available next Friday at 4 pm?" The PM says: "I am afraid I am not available on Friday. When is our next monthly meeting? What's the issue?"`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        question: "Appointment is:",
        options: [
          {
            id: 1,
            text: "A formal arrangement to meet or visit someone at a particular time and place.",
          },
          {
            id: 2,
            text: "A doctor's visit or a job interview.",
          },
          {
            id: 3,
            text: "A planned occasion when people come together, either in person or online (using the internet), to discuss something.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        question: "Which of the following structures is grammatically correct:",
        options: [
          {
            id: 1,
            text: "In + January, February, March, April, May, June, July, August, September, October, November, December; In 2022; In Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday",
          },
          {
            id: 2,
            text: "In + January, February, March, April, May, June, July, August, September, October, November, December; In 2022; On + Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday",
          },
          {
            id: 3,
            text: "On + January, February, March, April, May, June, July, August, September, October, November, December; In 2022; On Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 4,
        question: "Choose the grammatically correct sentence:",
        options: [
          {
            id: 1,
            text: "I have a lot of work to do. But I have a very important update meeting right now. There is very important news about the project budget.",
          },
          {
            id: 2,
            text: "I have many works to do. But I have a very important update meeting right now. There is very important news about the project budget.",
          },
          {
            id: 3,
            text: "I have a lot of work to do. But I have a very important update meeting right now. There are very important news about the project budget.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        question:
          "Choose the grammatically correct sentence, based on what you learned about possessive adjectives:",
        options: [
          {
            id: 1,
            text: "Peter asks his assistant Sofia to schedule a meeting with their most important client, but as his assistant is sick, she can't make it. Finally, Peter could arrange the meeting for next Friday, he is looking forward to it!",
          },
          {
            id: 2,
            text: "Peter asks her assistant Sofia to schedule a meeting with their most important client, but as her assistant is sick, he couldn't make it. Finally, Peter could arrange the meeting for next Friday, he is looking forward to it!",
          },
          {
            id: 3,
            text: "Peter asks his assistant Sofia to schedule a meeting with his most important client, but as her assistant is sick, he couldn't make it. Finally, Peter could arrange the meeting for next Friday, he is looking forward to it!",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        question: `Choose the correct option:`,
        options: [
          {
            id: 1,
            text: "Have you seen our workmates's desks? They are really untidy! Yeah, and what about our boss's office? He is so clean, everything is shining!",
          },
          {
            id: 2,
            text: "Have you seen our workmates' desks? They are really untidy! Yeah, and what about our boss's office? He is so clean, everything is shining!",
          },
          {
            id: 3,
            text: "Have you seen our workmate's desks? They are really untidy! Yeah, and what about our boss' office? He is so clean, everything is shining!",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        question: `Choose either A, B or C to fill in the blanks:
        \n
        Peter is looking for a new job ____ he wants to work remotely. There is a company that needs a new developer, ____ one of the requirements is to have at least 2 years of work experience. Anyway, he reviews his CV ____ sends it via email. After fifteen days, he checks out his email account ____ sees that the recruiter asks: Are you applying for the front-end developer ____ back-end developer position? Therefore, he answers back immediately ____ as not to miss the opportunity of having an interview.`,
        options: [
          {
            id: 1,
            text: "because, but, and, and, or, so",
          },
          {
            id: 2,
            text: "but, and, but, and, or, so",
          },
          {
            id: 3,
            text: "but, but, and, and, and, so",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        question: `Choose either A, B or C to fill in the blanks:
        \n
        It is Friday afternoon and there ____ a monthly meeting. The office is full of people hoping that the meeting will finish soon. There ____ cups of coffee ____ the table and ____ it, there ____ a lot of rubbish ____ the table, there ____ a group of accountants talking with each other. Suddenly, ____ the group, there appears the CEO and asks: is there any question? As nobody replies, he calls it a day.
        `,
        options: [
          {
            id: 1,
            text: "IS / ARE / IN / IN FRONT OF / IS / BETWEEN / IS / BEHIND",
          },
          { id: 2, text: "IS / ARE/ IN / UNDER / ARE / UNDER / BETWEEN" },

          { id: 3, text: "IS / ARE / ON / UNDER / IS / NEXT TO / IS / BEHIND" },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 9,
        question: "Choose the correct option:",
        options: [
          {
            id: 1,
            text: "Thank you for choosing our company to develop your app. I am looking forward to seeing it ready! It will be a huge success for sure. But we need to redo the contract. Unfortunately, I found a typo after reading it.",
          },
          {
            id: 2,
            text: "Thank you for choose our company to develop your app. I am looking forward to see it ready!It will be a huge success for sure. But we need to redo the contract. Unfortunately, I found a typo after reading it.",
          },
          {
            id: 3,
            text: "Thank you for choose our company to develop your app.I am looking forward to see it ready! It will be a huge success for sure. But we need to redo the contract. Unfortunately, I found a typo after read it.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        question: `Based on your knowledge of word categories, choose the correct sentence:`,
        options: [
          {
            id: 1,
            text: `Hi, Mark. How are you? Have you read Peter's propose for the new project? I think we need to have a meeting with our team of development soon.`,
          },
          {
            id: 2,
            text: `Hi, Mark. How are you? Have you read Peter's proposals for the new project? I think we need to have a meeting with our team of development soon.`,
          },
          {
            id: 3,
            text: `Hi, Mark. How are you? Have you read Peter's proposals for the new project? I think we need to have a meeting with our team of developers soon.`,
          },
        ],
        answer: 3,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50643,
    questions: [
      null,
      {
        id: 1,
        question:
          "Based on the order of adverbs of time, place and frequency, choose the correct sentence. (10ptos)",
        options: [
          {
            id: 1,
            text: `Peter is working on a very important project. He has an update meeting tomorrow. But he is stressed out because he hardly ever forgets to make a backup of his work. It is Friday morning, and he and part of his team are in the office. Some of them are sometimes late because they commute to work every day, , but Peter would never be late.`,
          },
          {
            id: 2,
            text: `Peter is working on a very important project. He has an update meeting tomorrow. But he is stressed out because he forgets hardly ever to make a backup of his work. It is Friday morning, and he and part of his team are in the office. Some of them are late sometimes because they commute to work every day, but Peter never would be late.`,
          },
          {
            id: 3,
            text: `Peter is working on a very important project. He has an update meeting tomorrow. But he is stressed out because he hardly ever forgets to make a backup of his work. It is Friday morning, and he and part of his team are in the office. Some of them are late sometimes because they every day commute to work, but Peter would never be late.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        question:
          "Choose the correct answer for this common interview question. What do you do? (10ptos)",
        options: [
          {
            id: 1,
            text: "I am an application development",
          },
          {
            id: 2,
            text: "I am an application developer. I design applications to use across mobile devices and tablets.",
          },
          {
            id: 3,
            text: "I am a application developer. I design applications for use across mobile devices and tablets.",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        question: "Choose the correct sentence: (10ptos)",
        options: [
          {
            id: 1,
            text: "If there is a new mobile app, I gather and evaluate user feedback",
          },
          {
            id: 2,
            text: "If is there a new app mobile, I gather and evaluate user feedback",
          },
          {
            id: 3,
            text: "I gather and evaluate feedback user, if is there a new mobile app",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        question: "Choose the grammatically correct sentence: (10ptos)",
        options: [
          {
            id: 1,
            text: "I usually work from 9 to 5, but my wife work from 5 to 12 pm. She prefer to work in the afternoon shift, so we don’t see each other quite often during the week.",
          },
          {
            id: 2,
            text: "I usually work from 9 to 5, but my wife works from 5 to 12 pm. She prefers to work in the afternoon shift. We don’t see each other quite often during the week.",
          },
          {
            id: 3,
            text: "I usually work from 9 to 5, but my wife work from 5 to 12 pm. She prefer to work in the afternoon shift, so we doesn’t see each other quite often during the week",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        question: `Read carefully the following sentence and choose the correct word to complete it: (10ptos)
        \n
        For this job position, English is_____ since we work with international clients and they usually _____ online update meetings. Besides, our clients are overly demanding because the projects we work on _____massive investment.
          `,
        options: [
          {
            id: 1,
            text: "requested / request / require",
          },
          {
            id: 2,
            text: "required / require / request",
          },
          {
            id: 3,
            text: "required / request / require",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        question: `Following the structure of WH questions, choose the correct ones: (10ptos)`,
        options: [
          {
            id: 1,
            text: "Where we are now? You are in a meeting? What about Maria, where she is? When she is coming back? How often does she go to the gym?",
          },
          {
            id: 2,
            text: "Where are we now? Are you in a meeting? What about Maria, where is she? When is she coming back? How often does she go to the gym?",
          },
          {
            id: 3,
            text: "Where are we now? You are in a meeting? What about Maria, where she is? When she is coming back? How often does she go to the gym?",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        question: `Based on the structure of simple present interrogatives: (10ptos)`,
        options: [
          {
            id: 1,
            text: "Do you work on the project in the evenings? No, I don’t. I prefer working in the mornings.What about Peter? He does all his work over the weekends.",
          },
          {
            id: 2,
            text: "You  work on the project in the evenings? No, I do not. I prefer working in the mornings.What about Peter? He does all his work over the weekends.",
          },
          {
            id: 3,
            text: "Do you work on the project in the evenings? No, I doesn’t. I prefer working in the mornings.What about Peter? He does all his work over the weekends.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        question: `Choose the well-structured information questions: (10ptos)
        `,
        options: [
          {
            id: 1,
            text: "When you work on the project? How often you work on the project? Where you work?",
          },
          {
            id: 2,
            text: "When you do work on the project? How often you do work on the project? Where you do work?",
          },

          {
            id: 3,
            text: "When do you work on the project? How often do you work on the project? Where do you work?",
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 9,
        question: `Choose either A, B or C to indicate the order of appearance of the type of imperatives of the following sentences: (10ptos)
        \n
        We are having an update meeting in 15 minutes. Let’s take advantage of it and don’t take your cell phone! Remember to share all the information you consider important and ask for suggestions if needed!
        `,
        options: [
          {
            id: 1,
            text: "Exhortation / negative / affirmative",
          },
          {
            id: 2,
            text: "Affirmative / exhortation / negative",
          },
          {
            id: 3,
            text: "Exhortation / affirmative / negative",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        question: `Taking into account what you learned about present simple, choose the correct options to complete the following sentences: (10ptos)
        \n
        ____ you work for Google? No, I ____. I work in a Marketing company and my wife works in Google. Oh, wow. ____she have wellness benefits at work? Yes, she ____ She is very pleased with her job. In my case, I ____have wellness benefits, but I do have retirement benefits.  Fortunately, we ____want to quit our jobs, although we____have winter holidays.`,
        options: [
          {
            id: 1,
            text: `DO / DON’T/ DOES/ DOES/ DON’T/ DON’T / DON’T`,
          },
          {
            id: 2,
            text: `DO / DO NOT / DO / DO / DO NOT / DO NOT / DOES NOT`,
          },
          {
            id: 3,
            text: `DOES / DOESN’T / DO / DO / DOES / DON’T /  DON’T`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50644,
    questions: [
      null,
      {
        id: 1,
        question: `Consider how to correctly answer the following question: (10ptos)
          \n
          Do you have any impediments or blocks?
          `,
        options: [
          {
            id: 1,
            text: `No, I don't have any. For now, everything is on track`,
          },
          {
            id: 2,
            text: `I don't have none. Alright.`,
          },
          {
            id: 3,
            text: `I have impediment or a block.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        question: `Isn't versus doesn't. What does the following sentence mean?  (10ptos)
        \n
        The motherboard doesn't work`,
        options: [
          {
            id: 1,
            text: "It is broken, we need to change it.",
          },
          {
            id: 2,
            text: "It can be fixed.",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        question: `Consider the order of the actions, what meanings are expressed by the present continuous tense? (10ptos)
        \n
        Peter is working with a new IT team this week and since his PM is always changing the project deadline, he wants to quit. He is looking for a new job right now.`,
        options: [
          {
            id: 1,
            text: "Temporary, annoying and happening at the moment of speaking",
          },
          {
            id: 2,
            text: "Temporary, temporary and happening at the moment of speaking",
          },
          {
            id: 3,
            text: "Happening at the moment of speaking, annoying and happening at the moment of speaking",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        question: "Choose the grammatically correct sentence: (10ptos)",
        options: [
          {
            id: 1,
            text: "Peter is not coming to the office tomorrow and Sara is wishing to see him!  Unfortunately, Peter doesn’t love his job, and he wants to take some days off.",
          },
          {
            id: 2,
            text: "Peter is not coming to the office tomorrow and Sara is wishing to see him!  Unfortunately, Peter is not loving his job, and he wants to take some days off.",
          },
          {
            id: 3,
            text: "Peter is not coming to the office tomorrow and Sara wishes to see him!  Unfortunately, Peter doesn’t love his job, and he wants to take some days off.",
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 5,
        question: `Which is the time reference expressed in the following sentences? (10ptos)
        \n
        What time does the coffee shop open? I need a coffee to continue working, we are launching a new product next week.
        `,
        options: [
          {
            id: 1,
            text: "Present time reference",
          },
          {
            id: 2,
            text: "Future and present time reference",
          },
          {
            id: 3,
            text: "Present, past and future time reference",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        question: `Read the next conversation carefully. Then, choose option A, B or C according to the order of appearance of the four different meanings of CAN. (10ptos)
        \n
        A) I am sorry, Peter, I cannot read these electronic documents, I don’t have my glasses here. (_____) Can you, please, read them for me?  (_____) You can use my laptop if you need (_____)
        B) It's fine. I can use mine.  (_____)
        `,
        options: [
          {
            id: 1,
            text: "Ability / request / permission / possibility",
          },
          {
            id: 2,
            text: "Permission / ability / request /  possibility",
          },
          {
            id: 3,
            text: "Ability / request / possibility /  permission",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 7,
        question: `Complete the sentence using IN, ON, BY, AT (10ptos)
        \n
        I was _____the bus when you phoned. I have just arrived _______the office. About the reports you asked me, they are_____ the desk in the conference room, _____ the fourth floor. Oh, remember next week I am traveling _____ train to Toronto. So, I won’t be _____the monthly meeting.
        `,
        options: [
          {
            id: 1,
            text: "BY / AT / ON / IN / BY / AT",
          },
          {
            id: 2,
            text: "ON / AT / ON / ON / BY / AT",
          },
          {
            id: 3,
            text: "BY / IN / ON / ON / BY / IN",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 8,
        question: `How would you answer the following question: Can you tell me about yourself? (10ptos)
        `,
        options: [
          {
            id: 1,
            text: "You would include information about the present (current role and the scope of it), past (work experience relevant to the job and company you are applying for) and future (what you are looking to do next and why you are interested in this job)",
          },
          {
            id: 2,
            text: "You would include information about the present (current role and the scope of it)",
          },

          {
            id: 3,
            text: "You would include information about the present (current role and the scope of it) and past (work experience relevant to the job and company you are applying for)",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        question: `How would you correctly answer the following question: What are you working on? (10ptos)
        `,
        options: [
          {
            id: 1,
            text: "Tracking glitches and send emails to the client to update them about our progress.",
          },
          {
            id: 2,
            text: "I am tracking glitches and sending emails to the client to update them about our progress.",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 10,
        question: `Considering the video ‘’The encyclopedia syndrome’’, choose the best sentence. (10ptos)`,
        options: [
          {
            id: 1,
            text: `Retirement benefits are important for the reason that they allow you to retire and live the same lifestyle you are accustomed to.`,
          },
          {
            id: 2,
            text: `Retirement benefits are important because they allow you to retire and live the same lifestyle you are accustomed to.`,
          },
        ],
        answer: 2,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50645,
    questions: [
      null,
      {
        id: 1,
        question: `Choose the well written ordinal numbers: (10ptos)`,
        options: [
          {
            id: 1,
            text: `Twenty first, thirty two, forty three, fifteenth, fifty first`,
          },
          {
            id: 2,
            text: `Twenty first, thirty second, forty third, fifteenth, fifty first`,
          },
          {
            id: 3,
            text: `Twenty first, thirty second, forty third, fifteen, fifty first`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 2,
        question: `Let’s be polite: (10ptos)`,
        options: [
          {
            id: 1,
            text: "Peter, could you finish this report for next week?",
          },
          {
            id: 2,
            text: "Peter, finish this report for next week",
          },
          {
            id: 3,
            text: "Peter, you can finish this report for next week",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        question: ` Peter needs to talk with his PM, but he is quite busy at the moment, choose the polite answer: (10ptos)`,
        options: [
          {
            id: 1,
            text: "Not now",
          },
          {
            id: 2,
            text: "One second, please",
          },
          {
            id: 3,
            text: "Later",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 4,
        question:
          "There is a debate meeting, and you want to show disagreement, which expressions would you use? (10ptos)",
        options: [
          {
            id: 1,
            text: "I disagree / I have another opinion",
          },
          {
            id: 2,
            text: "That’s a bad idea",
          },
          {
            id: 3,
            text: "You are wrong",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        question: `Choose the option that expresses the right meaning of troubleshoot in this sentence: (10ptos)
        \n
        “The company's help site explains how to set up and troubleshoot your DSL modem”
        `,
        options: [
          {
            id: 1,
            text: "to try to diagnose a problem and solve it",
          },
          {
            id: 2,
            text: "to cause someone to be worried or nervous",
          },
          {
            id: 3,
            text: "to discover where to get or how to achieve something",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        question: `Choose the grammatically correct sentence. (10ptos)
        `,
        options: [
          {
            id: 1,
            text: "Sara need work until 10 p.m. today. She need to verify all tasks and work in implementations.",
          },
          {
            id: 2,
            text: "Sara needs  work until 10 p.m. today. She needs verify all tasks and work in implementations.",
          },
          {
            id: 3,
            text: "Sara needs to work until 10 p.m. today. She needs to verify all tasks and work in implementations.",
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 7,
        question: `Based on what you learned from "Verbally Say Daily Technical Tasks the Correct Way", choose the correct option to complete the text: (10ptos)
        \n
        As a web developer, I write code using programming languages such as JavaScript. I ____ test applications and interfaces ____ maintaining websites and web applications. ____, ____ do I work with my team to create a website, I ____ work with graphic designers to determine its layout. ____, I have meetings with clients to discuss website needs ____ features.
        `,
        options: [
          {
            id: 1,
            text: "Also / in addition to / besides / not only / also / moreover / and",
          },
          {
            id: 2,
            text: "Also / besides / in addition to/ not only / also / Also / and",
          },
          {
            id: 3,
            text: "Not only / also / besides / also / also / moreover / and",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        question: `Based on what you learned about present continuous tense, choose the correct questions to answer the following email: (10ptos)
        \n
        Hi, hope you're all well.
        Next week we're having our planning meeting on a different day.
        A new member is joining the team, so he is attending this meeting.        My meeting room is being repaired, so we need to change rooms.
        Hope to see you all there!
        Have a good day!

        `,
        options: [
          {
            id: 1,
            text: "When are we having the planning meeting? Where are we having it ? Who is attending it?",
          },
          {
            id: 2,
            text: "When is the planning meeting? Where is it? Who will be at the planning meeting?",
          },

          {
            id: 3,
            text: "When is the planning meeting? Where will it be? Who will be at the planning meeting?",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        question: `How would you complete the following questions? (10ptos)
        \n
        ____ are we doing the project? ____ we doing the project together? ____ Sara working with us?
        ____ often ____ we meeting?`,
        options: [
          {
            id: 1,
            text: "How / are / is / what / are",
          },
          {
            id: 2,
            text: "Which / am / is / Where / is",
          },
          {
            id: 3,
            text: "How / are / is / how / are",
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 10,
        question: `Read this extract from the story ‘’A meeting with her boss’’ and choose option A, B or C to answer the following question: (10ptos)
        \n
        “Mauro is a gentleman. He is polite and easygoing, but she still wonders why she is there. Mauro asks about Laura's team progress and work. He also wants to know if she needs something else to accomplish the team's goals.”
        \n
        What was the purpose of the meeting?
        `,
        options: [
          {
            id: 1,
            text: `Make changes in the project that are very necessary`,
          },
          {
            id: 2,
            text: `Talk about Laura's team progress and work and offer assistance to accomplish the team's goal`,
          },
          {
            id: 3,
            text: `Ask Laura to supervise the other teams' progress`,
          },
        ],
        answer: 2,
        points: 10,
      },
      null,
    ],
  },

  //Junior Plus
  {
    id: 51011,
    questions: [
      null,
      {
        id: 1,
        question: `Read this extract from the story “Laura has a new friend”, and answer: who is an application developer?
        \n
        Laura is a little sad.
        Paula, another colleague, notices the situation. She comes and introduces herself.
        She is on the same team as Laura. She is an application developer.
        `,
        options: [
          {
            id: 1,
            text: `Laura`,
          },
          {
            id: 2,
            text: `Paula`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 2,
        question: `In the story “Laura has a new friend”, which of the following statements is correct?
        \n
        Paula says she doesn't have to worry about that because probably he (Peter) remembers her, but he is at the top now. He is a project manager and has a strong personality. She works with him and knows he is a little bossy and very meticulous.
        Laura is sad because they used to be good friends, but he changed a lot.
        `,
        options: [
          {
            id: 1,
            text: `Paula and Laura are good friends`,
          },
          {
            id: 2,
            text: `Peter and Laura were good friends`,
          },
          {
            id: 3,
            text: `Peter and Laura are good friends `,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        question: `In the story “At the cafeteria”, “Some colleagues were laid off and finally, she presented her resignation letter” A synonym of LAY OFF is ...`,
        options: [
          {
            id: 1,
            text: "to dismiss or fire",
          },
          {
            id: 2,
            text: "to recline or line back",
          },
          {
            id: 3,
            text: "to assign or give",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        question: `Read this extract from the story “At the cafeteria”, choose the correct option:
        \n
        Paula inquires about her last job. Laura talks about her background studies and last job position. Laura says that everything was fine until the company was purchased by a big corporation. Some colleagues were laid off and finally, she presented her resignation letter. PAULA says: “Oh… I'm sorry to hear that. But I am sure you have the potential to contribute new ideas to our company. So, welcome again!
        `,
        options: [
          {
            id: 1,
            text: `Laura was happy in her previous job, but she did not have a good relationship with her colleagues`,
          },
          {
            id: 2,
            text: `Laura was laid off from her previous position`,
          },
          {
            id: 3,
            text: `Laura quit her previous job`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 5,
        question: `Read this sentence from the story “Mike has some questions”:
        \n
        My hobbies are music, movies, and swimming. And, as many software developers, I love technology and reading tech magazines.
        \n
        Is the following statement true or false?: “Many software developers love technology and reading tech magazines, but Laura doesn’t”
        `,
        options: [
          {
            id: 1,
            text: "TRUE",
          },
          {
            id: 2,
            text: "FALSE",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        question: `The antonym for GENTLE IS ...`,
        options: [
          {
            id: 1,
            text: "Unkind",
          },
          {
            id: 2,
            text: "Tolerant",
          },
          {
            id: 3,
            text: "Tender",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/743464710/133c2322d4",
        question: `Watch the story “Mike has some questions” and answer the following questions:
        \n
        Where does Laura live?
        `,
        options: [
          {
            id: 1,
            text: "She lives in Buenos Aires, Argentina",
          },
          {
            id: 2,
            text: "She lives in Chile",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/743464710/133c2322d4",
        question: `Based on the story “Mike has some questions” answer if the following statement is true or false: Laura lives in an apartment/flat. She also has a male roommate.
        `,
        options: [
          {
            id: 1,
            text: "FALSE",
          },
          {
            id: 2,
            text: "TRUE",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/743464710/133c2322d4",
        question: `Based on the story, “Mike has some questions”, choose the correct statement.
        `,
        options: [
          {
            id: 1,
            text: "Laura doesn’t like when her partner asks too many questions",
          },
          {
            id: 2,
            text: "Laura and her partner don’t get along very well",
          },
          {
            id: 3,
            text: "As Laura’s partner is Russian, they don’t get along very well",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/743464710/133c2322d4",
        question: `Based on the story, “Mike has some questions”, choose the correct statement.
        `,
        options: [
          {
            id: 1,
            text: `Mike is surprised about Laura. She is not Chilean, she works as a software developer, and she is not responsible.`,
          },
          {
            id: 2,
            text: `Mike admires Laura, she is an accountable software developer, and she is not Chilean`,
          },
        ],
        answer: 2,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 51012,
    questions: [
      null,
      {
        id: 1,
        question: `Read this extract from the story “The red twinkling light” and answer the question: Is the email unclear?
        \n
        She starts work at seven-thirty in the morning. She comes into her office and turns on her computer. The air conditioner works very well. She notices that there is an e-mail in her inbox.
        \n
        The email is from Peter, but it was sent at 4 am, and it is not clear at all. He probably was sleepy when he wrote it. So, she decided to reply to the e-mail and ask some questions.
        `,
        options: [
          {
            id: 1,
            text: `Yes, it is`,
          },
          {
            id: 2,
            text: `No, it isn’t`,
          },
          {
            id: 3,
            text: `Yes, he is`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        question: `Read this extract from the story “The meeting”. A synonym for “ACCOMPLISH” is ...
        \n
        Lean agrees 100 % with her. Then, he adds that they are a team, and they must work together to accomplish their goals.`,
        options: [
          {
            id: 1,
            text: `Accede`,
          },
          {
            id: 2,
            text: `Achieve`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        question: `Read this extract from the story “Laura helps her friend” and answer the question: How frequently do they use the printer machine?\n
        Laura says: “We barely use it. I don't know what the exact problem is, but it seems to be serious. And, we need to hand in our reports tomorrow morning. Can you lend us a hand?”`,
        options: [
          {
            id: 1,
            text: "They usually use it",
          },
          {
            id: 2,
            text: "They hardly ever use it",
          },
          {
            id: 3,
            text: "They always use it",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 4,
        question: `Read this extract from the story “The Office” and answer if the following statement is true or false:
        \n
        There isn’t any tech shop near the office because it caught fire.
        \n
        Monica gives them directions. They need to come outside the building and turn left. Then, walk straight ahead for around three blocks. The tech shop is on Cartagena Street, just in front of the bank. So, they follow the instructions carefully, but there isn't any tech shop around. In fact, everything is closed in that area. They wonder what's going on. Someone passes by. Laura doesn't lose the opportunity and asks: “Hello. Sorry. We are looking for a tech shop, but everything is closed. What is going on?” The man replies that there was an accidental gas leak yesterday. So, the firefighter team is taking precautions today. They advised entrepreneurs to keep their shops closed.
        `,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        question: `Read this extract from the story “The Office” and choose the correct statement.
        \n
        Laura says: “Well, I didn't spend too much time in my office this week. So, it is organized. Everything is in its place. But what I really like the most about it is that there is a wonderful view of the boulevard. Also, there is always a nice smell that comes from the boulevard's trees and flowers. I love my office. Working there sets me in a good mood.”`,
        options: [
          {
            id: 1,
            text: "Laura does not like the smell that comes from the boulevard’s trees and flowers",
          },
          {
            id: 2,
            text: "Laura enjoys working in her office, it makes her feel in high spirits",
          },
          {
            id: 3,
            text: "Laura does not usually spend much time in her office",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        question: `Read the following extract from the story “Under pressure” and answer the following question: Who is Laura’s PM?
        \n
        After helping Mike, Laura feels happier and more integrated into the workgroup, but she realizes that her project is delayed. She lost a whole day's work, and now she is worried about it. She considers it convenient to talk to the project manager, who is no other than Peter.`,
        options: [
          {
            id: 1,
            text: "Mike",
          },
          {
            id: 2,
            text: "Peter",
          },
          {
            id: 3,
            text: "Other person",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/743464838/90341445a5",
        question: `Watch the story “An e-mail to London” and answer the following questions: Who needs to improve their English-speaking skills?
        `,
        options: [
          {
            id: 1,
            text: "Laura needs to improve her English-speaking skills",
          },
          {
            id: 2,
            text: "Natasha needs to improve her English-speaking skills",
          },
          {
            id: 3,
            text: "Peter needs to improve his English-speaking skills",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/743464838/90341445a5",
        question: `Based on the story “An e-mail to London”, choose the correct statement:
        `,
        options: [
          {
            id: 1,
            text: "Laura and Jennifer lived together for over three years in Buenos Aires, and they met each other there",
          },
          {
            id: 2,
            text: "Laura and Jennifer lived together approximately three years in Buenos Aires, but they met in London",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/743464838/90341445a5",
        question: `Based on the story “An e-mail to London”, answer the following question: What does Jennifer want to know about Laura?
        `,
        options: [
          {
            id: 1,
            text: "Jennifer wants to know about Laura’s routine/current life",
          },
          {
            id: 2,
            text: "Jennifer wants to know about Laura’s job",
          },
          {
            id: 3,
            text: "Jennifer wants to know about Laura’s friends",
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/743464838/90341445a5",
        question: `Based on the story “An e-mail to London” answer the following question:
        \n
        What time does Laura start working? How frequently does she have update meetings?
        `,
        options: [
          {
            id: 1,
            text: `She starts working at 7:30 pm, and she sometimes has update meetings in the morning`,
          },
          {
            id: 2,
            text: `She starts working at 7:30 am, and she usually has update meetings in the morning`,
          },
          {
            id: 3,
            text: `She starts working at 7:30 am, and she normally has update meetings in the afternoon`,
          },
        ],
        answer: 2,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 51013,
    questions: [
      null,
      {
        id: 1,
        question: `Read this extract from the story “Laura's tasks at work” and choose the correct statement
        \n
        It's six-thirty in the morning. Laura leaves home and goes to the office. When she arrives, the janitor is already cleaning and opening doors. She says good morning to everyone.`,
        options: [
          {
            id: 1,
            text: `Laura arrives at work and the janitor finishes cleaning`,
          },
          {
            id: 2,
            text: `The janitor starts cleaning before Laura arrives at work`,
          },
          {
            id: 3,
            text: `As soon as Laura arrives at work, the janitor starts cleaning`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 2,
        question: `Read this extract from the story “Laura's tasks at work” and choose the correct statement.
        \n
        Laura starts work at 7:30 a.m. She turns on her computer. Sometimes, she prepares a strong coffee with some sugar. It tastes delicious. After that, she is ready to work. She makes a to-do list and divides her tasks into two categories: urgent and not urgent, so she can focus easily on her priorities.`,
        options: [
          {
            id: 1,
            text: `Sometimes Laura starts working at 7:30, then she turns on her computer and prepares a strong coffee.`,
          },
          {
            id: 2,
            text: `Laura starts working at 7:30, she turns on her computer and makes a to-do list to divide her tasks. After that, she always prepares a strong coffee with a lot of sugar.`,
          },
          {
            id: 3,
            text: `Laura sometimes prepares a strong coffee with a bit of sugar and then she is ready to work.`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 3,
        question: `Read this extract from the story “Laura's tasks at work” and answer if the following statement is TRUE or FALSE: Laura drinks a coffee or tea at 6 o'clock because she is free from work.
        \n
        At 6 o'clock she is free for a while, so she has a snack. It can be another cup of coffee or tea and a brownie. She loves brownies. At the office, she focuses on the agenda and thinks about the next day's tasks. When everything is done, it's time to get off. She turns the computer off and locks her office door. She takes the elevator and arrives at the reception. Laura says goodbye to Monica, the receptionist, and leaves the building.
        `,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/794274335/a070382808",
        question: `Watch the story “About Python” and choose the correct statement.`,
        options: [
          {
            id: 1,
            text: `There is an issue with the project they are working on, so Laura sets a meeting.`,
          },
          {
            id: 2,
            text: `Laura and her team are delayed with their project, so she decides to set a meeting as if she were the team leader, but she is not.`,
          },
          {
            id: 3,
            text: `They are delayed with their project, so they meet to discuss the project advancements and avoid losing the client.`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/794274335/a070382808",
        question: `Based on the story “About Python” and answer the following question. Why is the team delayed?
        `,
        options: [
          {
            id: 1,
            text: `They are delayed because Java is complicated and time-consuming, and they are tired`,
          },
          {
            id: 2,
            text: `They are delayed because they are overworking, they don't have time to finish the project`,
          },
          {
            id: 3,
            text: `They are delayed because they don't understand Java`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/743464876/f1b5eed078",
        question: `Watch the story “A controversial client” and answer the following questions: Who sets the meeting on Wednesday morning?
        `,
        options: [
          {
            id: 1,
            text: "Laura",
          },
          {
            id: 2,
            text: "Mr. Frede’s assistant / The assistant",
          },
          {
            id: 3,
            text: "Peter",
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/743464876/f1b5eed078",
        question: `Based on the story “A controversial client” answer the following question: At the meeting, is the client polite?
        `,
        options: [
          {
            id: 1,
            text: `Yes, she is`,
          },
          {
            id: 2,
            text: `Yes, he is`,
          },
          {
            id: 3,
            text: `No, he isn’t`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/743464876/f1b5eed078",
        question: `Based on the story “A controversial client” answer the following question: What does the company offer?
        `,
        options: [
          {
            id: 1,
            text: `The company sells a wide range of products not only clothes but also electronic devices, among other things`,
          },
          {
            id: 2,
            text: `The company sells a variety of clothing and electronic devices`,
          },
          {
            id: 3,
            text: `The company sells only clothes and electronic devices`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/794275593/98647c5424",
        question: `Watch the story “The status update meeting” and choose the correct statement.
        `,
        options: [
          {
            id: 1,
            text: "Although Mike couldn't finish his research, he hands in a report and asks for a deadline extension.",
          },
          {
            id: 2,
            text: "Mike doesn't hand in a report, he needs a deadline extension. He might deliver it the next day.",
          },
          {
            id: 3,
            text: "Mike's report is incomplete, but he might deliver the final one in the afternoon.",
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/794275593/98647c5424",
        question: `Based on the story “The status update meeting”, choose the correct statement.
        `,
        options: [
          {
            id: 1,
            text: `Paula's task is time-consuming. She needs a deadline extension to complete her report. Laura allows her to take more time to finish it.`,
          },
          {
            id: 2,
            text: `Paula has a lot to do. She cannot finish her task. Laura gets mad at her, they are under pressure.`,
          },
          {
            id: 3,
            text: `Paula does not expect to finish the report.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 51014,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/794276531/c84995e09e",
        question: `Watch the story “A disaster” and choose the correct statement. Why is Mike not going to the office?`,
        options: [
          {
            id: 1,
            text: `He is sick`,
          },
          {
            id: 2,
            text: `He wants to rest`,
          },
          {
            id: 3,
            text: `He is sick and his family is taking care of him`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/794276531/c84995e09e",
        question: `Based on the story “A disaster” and answer the question: Laura needs someone to finish Mike's report. Can Helen do it?`,
        options: [
          {
            id: 1,
            text: `Yes, she can`,
          },
          {
            id: 2,
            text: `No, she can’t`,
          },
          {
            id: 3,
            text: `No, she can finish Mike’s report`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/794276531/c84995e09e",
        question: `Watch the story “A disaster” and choose the correct meaning of “COME UP WITH”`,
        options: [
          {
            id: 1,
            text: `To think`,
          },
          {
            id: 2,
            text: `To make progress`,
          },
          {
            id: 3,
            text: `To arrive`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/794277088/4dcad1b510",
        question: `Based on the story “The tech guy”, choose the grammatically correct questions. Consider the description.`,
        options: [
          {
            id: 1,
            text: `Where is Laura? Is she working? What is she doing?`,
          },
          {
            id: 2,
            text: `Where does Laura is? Does she work? What does she do?`,
          },
          {
            id: 3,
            text: `Where is Laura? She is working? What she is doing?`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/794277088/4dcad1b510",
        question: `Watch the story “The tech guy” and choose the best answer: What is the problem?`,
        options: [
          {
            id: 1,
            text: `Laura’s computer does not work`,
          },
          {
            id: 2,
            text: `Helen’s computer doesn’t turn on`,
          },
          {
            id: 3,
            text: `Helen’s computer isn’t turning on`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/794277088/4dcad1b510",
        question: `Based on the story “The tech guy” and choose the correct statement`,
        options: [
          {
            id: 1,
            text: `Peter is taking to the CEO right now`,
          },
          {
            id: 2,
            text: `Peter wants to talk to the CEO to make him understand the issue`,
          },
          {
            id: 3,
            text: `Peter is going to the CEO's office. He understands the issue.`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/794277088/4dcad1b510",
        question: ` Based on the story “The tech guy” and answer the following question: What does Laura need to do?`,
        options: [
          {
            id: 1,
            text: `Laura needs to get in touch with Mr.Fredes to change their meeting.`,
          },
          {
            id: 2,
            text: `Laura needs to find Mr.Fredes and set a meeting`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/794277235/791a22171c",
        question: `Watch the story “The candidate” and choose the best answer: When is the interview?`,
        options: [
          {
            id: 1,
            text: `The interview will be on Friday morning`,
          },
          {
            id: 2,
            text: `The interview is on Friday morning`,
          },
          {
            id: 3,
            text: `The interview can be on Friday morning`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/794277235/791a22171c",
        question: `Based on the story “The candidate” and choose the correct statement`,
        options: [
          {
            id: 1,
            text: `Martin has some work experience, but he can't code with Python, Jira nor Marvel`,
          },
          {
            id: 2,
            text: `Martin doesn't have work experience, but he can code with Python, Jira and Marvel`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/794277235/791a22171c",
        question: `Based on the story “The candidate” and answer the question: Does Laura hire Martin?`,
        options: [
          {
            id: 1,
            text: `No, she doesn’t`,
          },
          {
            id: 2,
            text: `Yes, she does`,
          },
        ],
        answer: 2,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 51015,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/794277483/75c9eb814",
        question: `Watch the story “A meeting with HR” choose the correct answer for the following question: Why does Silvia set a meeting with Laura?`,
        options: [
          {
            id: 1,
            text: `Because Laura hired a new employee and Silvia is usually in charge of the interview process`,
          },
          {
            id: 2,
            text: `Because Laura hired a new employee and Silvia doesn’t like this candidate`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/794277483/75c9eb814",
        question: `Based on the story “A meeting with HR” answer the question: What are Laura’s reasons for hiring a new employee?`,
        options: [
          {
            id: 1,
            text: `There is an urgent issue to solve, and this new team member can help to solve this problem`,
          },
          {
            id: 2,
            text: `There is an urgent issue to solve, we are delayed because one team member cannot work from home`,
          },
          {
            id: 3,
            text: `There is an urgent issue to solve, we are delayed  and one team member is sick and cannot work from home`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/794277483/75c9eb814",
        question: `Based on the story “A meeting with HR” answer the following questions:
        \n
        Can Laura take a day off?  Can she make some other adjustments to her team? Can they add another teammate?`,
        options: [
          {
            id: 1,
            text: `Yes, she can take one day off, but she cannot add another teammate, because there is not enough budget.`,
          },
          {
            id: 2,
            text: `Yes, she can take some days off until Tuesday morning, but she can't add another teammate, because there is not enough budget.`,
          },
          {
            id: 3,
            text: `Yes, she can take only one day off until Tuesday morning, and she can add another teammate. However, the company’s budget is short.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/794278571/5a5d2f54d8",
        question: `Watch the story “The shopping cart button” and answer the following question: Why do the team have a meeting?`,
        options: [
          {
            id: 1,
            text: `The objective of the meeting is to share an update report of the project from Paula`,
          },
          {
            id: 2,
            text: `Because Laura wants to have an updated report from each team member`,
          },
          {
            id: 3,
            text: `Because the team member need to gain confidence`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/794278571/5a5d2f54d8",
        question: `Based on the story “The shopping cart button” and answer the following question: What does Laura suggest to Paula?`,
        options: [
          {
            id: 1,
            text: `To change the background color, implement another typography, delete the title and add an extra button that links to the shopping cart`,
          },
          {
            id: 2,
            text: `To change the background color, delete the title and add an extra button that links to the shopping cart`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/794277088/4dcad1b510",
        question: `Read the following extract from the story “The package” and choose the best description of the robot:
        \n
        Helen asks a lot of questions. She asks how the robot works and about the autonomy it has.
        Laura answers: “The robot has a name. Its name is Ron. Ron can answer requests. It can answer phone calls and keep a record of them as well. Ron can also welcome people and organize our schedule. It is always connected to the main computer. Therefore, every task it does is backed up. Ron needs one charge per week to fully operate. In addition, it sends one full report per hour by email. So, Peter and I can check its tasks periodically. Anyway, there is only one thing it cannot do: take your coffee.”`,
        options: [
          {
            id: 1,
            text: `The robot’s name is Ron. He can answer requests, phone calls and keep a record of them as well. He can also welcome people and organize the schedule. He is connected to the main computer.`,
          },
          {
            id: 2,
            text: `The name of the robot is Ron. It can answer requests, phone calls and keep a record of them. It can also welcome people and organize schedules. Every task it does is backed up because it is always connected to the main computer.`,
          },
          {
            id: 3,
            text: `The name of the robot is Ron. It can answers requests, phone calls and keeps a record of them. It can also welcomes people and organize schedules. Every task it does is backed up because it is always connected to the main computer. To operate fully, it needs one charge per weekend. `,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/794278872/391be6afe8",
        question: `Watch the story “The missing boss” and choose the correct statement`,
        options: [
          {
            id: 1,
            text: `Peter gets closer to Laura because he is worried about Maura, he isn’t at his office, but his cell phone rings inside the office.`,
          },
          {
            id: 2,
            text: `Peter shuts Laura because he is worried about Mauro, he isn’t at his office, but his cell phone rings inside the office.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/794278872/391be6afe8",
        question: `Based on the story “The missing boss” choose the correct answer for the following question: Does Peter call the police?`,
        options: [
          {
            id: 1,
            text: `Yes, he does`,
          },
          {
            id: 2,
            text: `No, he doesn’t`,
          },
          {
            id: 3,
            text: `Yes, he did`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/793007532/1a1c654efa",
        question: `Watch the story “A colleague’s birthday” and choose the correct answer: When is Helen’s, Peter’s, Monica’s and Mike’s birthday?`,
        options: [
          {
            id: 1,
            text: `Helen's birthday is on December 2nd, Peter's birthday is on January 30th, Monica's birthday is on August 4th and Mike's birthday is on September 4th`,
          },
          {
            id: 2,
            text: `Helen's birthday is on December 22nd, Peter's birthday is on January 13th, Monica's birthday is on August 4th and Mike's birthday is on September 4th`,
          },
          {
            id: 3,
            text: `Helen's birthday is on December 2nd, Peter's birthday is on January 30th, Monica's birthday is on August 14th and Mike's birthday is on September 4th`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/793007532/1a1c654efa",
        question: `Based on the story “A colleague’s birthday” and answer the following question: Who attends the meeting?`,
        options: [
          {
            id: 1,
            text: `Only Helen, Monica and Laura attend the meeting because the rest are busy or have another appointment`,
          },
          {
            id: 2,
            text: `Helen, Monica and Laura and the rest join later because they are busy or have another appointment`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },

  //Middle
  {
    id: 50691,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/770410672/6bba21b712",
        question: `Watch the story “The induction process” and choose the grammatically correct sentence: When will they meet? Where?`,
        options: [
          {
            id: 1,
            text: `They will meet on September 12th at 8am in 1230 Rancagua Street, Providencia, Santiago, Chile.`,
          },
          {
            id: 2,
            text: `In September 12th at 8am, they meet in 1230 Rancagua Street, Providencia, Santiago, Chile.`,
          },
          {
            id: 3,
            text: `In 1230 Rancagua Street, Providencia, Santiago, Chile, on September 12th at 8am, they will meet.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/770410672/6bba21b712",
        question: `Based on the story "The induction process", choose the best description of Emilia’s work`,
        options: [
          {
            id: 1,
            text: `She is working from Monday to Friday from 7 to 5 pm. She manage Mrs. Bustamante’s schedule, check emails, arrange meetings and welcome guests, among other activities.`,
          },
          {
            id: 2,
            text: `She works from Monday to Friday from 7 to 5 pm. She manages Mrs. Bustamante’s schedule, checks emails, arranges meetings and welcomes guests, among other activities.`,
          },
          {
            id: 3,
            text: `She work from Monday to Friday from 7 to 5 pm. She manage the schedule of Mrs. Bustamante, check emails, arrange meetings and welcome guests, among other activities.`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/770410672/6bba21b712",
        question: `Based on the story  ‘’The induction process’’,  choose the correct meaning of the expressions used:
        \n
        To have a long way to go means ___ and best of luck means ___`,
        options: [
          {
            id: 1,
            text: `To have a long way to go means to need to do a lot more before you are successful, and best of luck is used for wishing someone good luck in something they are trying to do.`,
          },
          {
            id: 2,
            text: `To have a long way means to achieve a lot of things and make progress, and best of luck means to prosper in life.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/763815522/cc137b8d33",
        question: `Watch the story ‘’Her first English email’’, and remember the suggestions for writing formal emails.`,
        options: [
          {
            id: 1,
            text: `Use active voice, full forms and structure the email as follows: subject line, salutations, body, call to action and signature.`,
          },
          {
            id: 2,
            text: `Use passive voice, full forms and structure the email as follows:subject line, the body, call to action and signature.`,
          },
          {
            id: 3,
            text: `Use passive voice, avoid contractions and structure the email as follows:subject line, salutations, the body, call to action and signature.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/763815522/cc137b8d33",
        question: `Based on the story  ‘’Her first English email’’ , choose the correct synonym of the expressions to be on it and give it a try`,
        options: [
          {
            id: 1,
            text: `To be on it means to depend on something and to give it a try means to hope to get something`,
          },
          {
            id: 2,
            text: `To be on it means to be dealing with it and  to give it a try means to give it a go`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/763815522/cc137b8d33",
        question: `Based on the story ‘’Her first English email’’, answer the following question: This week, Stephanie is working on an app, why is the present progressive being used here?`,
        options: [
          {
            id: 1,
            text: `Because she is working on an app now`,
          },
          {
            id: 2,
            text: `Because she is working on an app, but that is temporary`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/764178312/b64e4c6ca6",
        question: `Watch the story ‘’Ready for success’’ and answer the question:
        \n
        Is it the same to say piece of luck or stroke of luck?`,
        options: [
          {
            id: 1,
            text: `Yes, it is`,
          },
          {
            id: 2,
            text: `No, it isn’t`,
          },
          {
            id: 3,
            text: `Yes, it does`,
          },
          {
            id: 4,
            text: `No, it doesn’t`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/764178312/b64e4c6ca6",
        question: `Based on the story ‘’Ready for success’’ , which are the questions asked with the verb to be?`,
        options: [
          {
            id: 1,
            text: `What are your qualifications? Do you have any work experience? What is your long-term goal?`,
          },
          {
            id: 2,
            text: `What are your qualifications? What is your long-term goal?`,
          },

          {
            id: 3,
            text: `Your qualifications?  You have any work experience? Your long-term goal?`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/764178312/b64e4c6ca6",
        question: `Based on the story ‘’Ready for success’’, other verbs that can be used with IN TOUCH are keep and get, but what does it mean?`,
        options: [
          {
            id: 1,
            text: `To communicate or continue to communicate with someone by using a phone or writing to them`,
          },
          {
            id: 2,
            text: `To speak to someone`,
          },
          {
            id: 3,
            text: `To say, write, or do something as a reaction to a question, letter, etc`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/764178312/b64e4c6ca6",
        question: `Considering the interview in the story ‘’Ready for success’’, what step is omitted?`,
        options: [
          {
            id: 1,
            text: `Greeting and small talk`,
          },
          {
            id: 2,
            text: `Phrases for closing the meeting`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50692,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Watch the story ‘’The new colleague’’ and choose the correct answer:
        How is BUT being used in this paragraph?
        \n
        “The email is very long and full of technical words, but with a little patience, she can understand it. The client answered all Stephanie's questions in detail, but in addition, he asked to modify the project deadline.”`,
        options: [
          {
            id: 1,
            text: `It expresses contrast`,
          },
          {
            id: 2,
            text: `It means except`,
          },
          {
            id: 3,
            text: `It introduces the reason why something didn’t happen`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Based on the story ‘’The new colleague’, answer the following question: Why does Stephanie feel overwhelmed?`,
        options: [
          {
            id: 1,
            text: `Stephanie feels overwhelmed because it is nearly impossible to achieve the goal on time since she is delayed with some reports and there was a change in the project deadline.`,
          },
          {
            id: 2,
            text: `Stephanie feels overwhelmed because she needs an assistant.`,
          },
          {
            id: 3,
            text: `Stephanie feels overwhelmed because she does not have someone she can trust and delegate some tasks to.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Based on the story ‘’The new colleague’’, in which statement is the expression ‘’to be all ears’’ used correctly?`,
        options: [
          {
            id: 1,
            text: `Come on, tell me the good news! I am all ears.`,
          },
          {
            id: 2,
            text: `We are all ears as the celebrity guests emerge from the car.`,
          },
          {
            id: 3,
            text: `She has never been very friendly, but she was all ears when she asked me to help her with her homework.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Based on the story ‘’The new colleague’’, when Eliana says ‘’Now, go to your office, and try to unwind’’, she uses the imperative to:`,
        options: [
          {
            id: 1,
            text: `To give an order`,
          },
          {
            id: 2,
            text: `To give a warning`,
          },
          {
            id: 3,
            text: `To give a solution`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Based on the story ‘’The new colleague’’,  what does the expression ‘’it is not my business’’ mean?`,
        options: [
          {
            id: 1,
            text: `It is not my company`,
          },
          {
            id: 2,
            text: `It is not my problem/concern`,
          },
          {
            id: 3,
            text: `It is not my shop`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/763923972/3f24da511b",
        question: `Based on the story ‘’The new colleague’’, choose either A, B or C to complete the following statements:
        \n
        Please, ___ that computer and ___ a file called Black Horse. ___ the file in Python,  then ___, like this, and ___ coding mistakes. I didn't have time to do that.`,
        options: [
          {
            id: 1,
            text: `turn on / look for / open / scroll down / look for`,
          },
          {
            id: 2,
            text: `you turn in / look for / you open / scroll down / look up`,
          },
          {
            id: 3,
            text: `turn on / look up / open / scroll down / look up`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/763911352/9e2b2cd4d4",
        question: `Watch the story ‘’The blackout’’, and answer if the following statement is true or false.
        \n
        Mr. Richardson has a good command of Spanish, since he has lived in the country (Chile) for many years.`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/770404300/1f04c11cdc",
        question: `According to the video lesson  ‘’Useful Grammar Tips to Use on Non-IT Professional’’, which phrase is connected with active listening? `,
        options: [
          {
            id: 1,
            text: `I don’t agree / I don’t think so`,
          },
          {
            id: 2,
            text: `Can you repeat?`,
          },

          {
            id: 3,
            text: `So what you’re saying is …`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/763911352/9e2b2cd4d4",
        question: `Based on the story ‘’The blackout’’, answer if the following statement is true or false.
        \n
        At the end, Stephanie couldn’t finish with her presentation because there was a blackout.`,
        options: [
          {
            id: 1,
            text: `False, (because Diana was giving the presentation and the power was restored)`,
          },
          {
            id: 2,
            text: `True`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/763911352/9e2b2cd4d4",
        question: `Based on the story ‘’The blackout’’, does the word peculiarity refer to?`,
        options: [
          {
            id: 1,
            text: `Places`,
          },
          {
            id: 2,
            text: `People`,
          },
          {
            id: 3,
            text: `Both, places and people`,
          },
        ],
        answer: 3,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50693,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Watch the story ‘’The presentation’’, remember the tips given in the video lesson about  ‘’How to make an excellent presentation in English’’ Then answer the following question: Which is the structure that Stephanie applies in her presentation?`,
        options: [
          {
            id: 1,
            text: `Introduction and body`,
          },
          {
            id: 2,
            text: `Introduction, body and conclusion`,
          },
          {
            id: 3,
            text: `Body`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Based on the story ‘’The presentation’’, answer if the following statement is true or false
        \n
        Stephanie’s company suffered a cyberattack, so now it spends half a million dollars per year on security measures`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE (It is  Mr. Richardson's company the one that spends that amount of money)`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Based on the story ‘’The presentation’’, answer if the following statement is true or false.
        \n
        Mr. Richardson’s experts will train Stephanie’s team in cybersecurity.`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Based on the story ‘’The presentation’’, what does OUT OF POLITENESS mean?`,
        options: [
          {
            id: 1,
            text: `Without being polite`,
          },
          {
            id: 2,
            text: `In order to be polite`,
          },
          {
            id: 3,
            text: `Being polite and serious`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Based on the story ‘’The presentation’’, you learned the expression TO TAKE CONTROL OF (THE BUSINESS), now consider the following statement:
        \n
        You really need to take control of your staff, Peter. Their mistakes are costing this company a lot of money!…… Is the expression used correctly?`,
        options: [
          {
            id: 1,
            text: `Yes, it is`,
          },
          {
            id: 2,
            text: `No, it isn’t`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/763914607/5352b4b546",
        question: `Based on the story ‘’The presentation’’, remember the suggestions to build up your confidence to make presentations and choose them below:`,
        options: [
          {
            id: 1,
            text: `Do not prepare everything beforehand, speak fast so that the audience doesn’t get bored, improvise if necessary and constantly change your speaking volume`,
          },
          {
            id: 2,
            text: `Project your voice and articulate, do not sound monotonous, put more emphasis on important words and slow down at key points so that the audience can really pay attention`,
          },
          {
            id: 3,
            text: `Use filler words, make eye contact so that the audience can pay attention, use a monotonous tone during your presentation and shout so that everybody can hear you`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/763915793/6396384b65",
        question: `Watch the story ‘’A successful beta app“, and answer the following question: What does ‘’confident’’ mean?`,
        options: [
          {
            id: 1,
            text: `It is an adjective that means being certain about one’s abilities or having trust in people, plans or future`,
          },
          {
            id: 2,
            text: `It is an adjective that means a person who gives information in secret, especially to the police`,
          },
          {
            id: 3,
            text: `It is a noun that means the quality of being certain of one’s abilities or of having trust in people, plans, or the future`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/763915793/6396384b65",
        question: `Based on the story ‘’A successful beta app’’, answer whether the following statement is true or false.
        \n
        Stephanie’s team feels lucky and secure because they rely on Miguel, who knows a lot about modern technology`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/763915793/6396384b65",
        question: `Based on the story ‘’A successful beta app’’, answer the following question: Why is Steph worried?`,
        options: [
          {
            id: 1,
            text: `Stephanie is worried about the app`,
          },
          {
            id: 2,
            text: `Stephanie is worried about her speech, she is afraid of not understanding the American client`,
          },
          {
            id: 3,
            text: `Stephanie is worried about the client’s reaction`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/660513427/3722501bfe",
        question: `Based on the video lesson ‘’Key phases to avoid while making a sales pitch’’, choose either A or B considering the phrases that should be avoided while making a sales pitch`,
        options: [
          {
            id: 1,
            text: `Avoid repeating your company's name more than once; don't mention large amounts like trillion; replace discount for bargain ; replace contract for agreement; avoid saying absolutely perfect use instead ‘’ready to go, a hundred percent, green light’’; do not use implement, instead use onboard or getting started.`,
          },
          {
            id: 2,
            text: `Repeat your company’s name more than once; mention large amounts like billion; use the word discount; do not use contract, instead use the word ‘’agreement’’; use expressions like ‘’absolutely perfect’’; do not use implement, instead use ‘’onboard or getting started’’`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50694,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/763918962/d5d029fd76",
        question: `Watch the story ‘’A missing friend’’ and based on what you learned about the past simple, choose either A, B or C to complete the missing gaps:
        \n
        “Eliana …….. the apartment last year, and she never ……….. I’ve had no news from her since that moment. I………..calling her, but she doesn't answer.”`,
        options: [
          {
            id: 1,
            text: `left / returned /tried`,
          },
          {
            id: 2,
            text: `lefted /returned / tried`,
          },
          {
            id: 3,
            text: `leave / return / tryied`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/763918962/d5d029fd76",
        question: `Based on the story ‘’The missing friend’’, answer the following question: Why couldn’t Eliana reply to Stephanie's emails?`,
        options: [
          {
            id: 1,
            text: `Because it was really difficult for her to contact anyone, the rented house is far away from the city`,
          },
          {
            id: 2,
            text: `Because she was robbed of her wallet, credit cards, computer, cellphone and even clothes`,
          },
          {
            id: 3,
            text: `Because she lost her passport`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 3,
        type: "video",
        video_URL: "https://vimeo.com/684411394/7e52ffd906",
        question: `Based on the video lesson “Difference between too and very”, choose the correct option `,
        options: [
          {
            id: 1,
            text: `Very and too are used before an adjective or adverb. The basic difference between them is that VERY emphasizes the word that follows it, and it means in a high degree, whereas TOO is used as an intensifier adverb that means more than it should be, and it is mostly used in a negative sense`,
          },
          {
            id: 2,
            text: `Very and too are used before an adjective or adverb. The basic difference between them is that TOO emphasizes the word that follows it, and it means in a high degree, whereas VERY is used as an intensifier adverb that means more than it should be, and it is mostly used in a negative sense`,
          },
          {
            id: 3,
            text: `Very is used before an adjective or adverb, and too is only used before adjectives. The basic difference between them is that TOO emphasizes the word that follows it, and it means in a high degree, whereas VERY is used as an intensifier adverb that means more than it should be, and it is mostly used in a negative sense.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        type: "video",
        video_URL: "https://vimeo.com/684407338/52b62ac2ea",
        question: `Based on the video lesson ‘’Questions in the past’’, choose either A, B or C to complete the missing gaps in the following questions:
        \n
        ___ she a developer? ___ she ___ the project last month? Why ___ she ___ a job interview yesterday?`,
        options: [
          {
            id: 1,
            text: `Is / Did / finish / did / have`,
          },
          {
            id: 2,
            text: `Is / did / finishes / did / has`,
          },
          {
            id: 3,
            text: `Is / Does / finish / does / have`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/763927364/9eb8372416",
        question: `Watch the story  “Stephanie's first meeting“, remember what you learned from the video lesson ‘’Quantifiers’’, and answer the following question: What does ‘’A lot of issues’’ mean?`,
        options: [
          {
            id: 1,
            text: `There are many problems`,
          },
          {
            id: 2,
            text: `There are some problems`,
          },
          {
            id: 3,
            text: `It is a type of problem`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/684411225/2cb2a9fb14",
        question: `Based on the video lesson ” Quantifiers’’, answer if the following statement is true or false: The quantifiers ‘’a few, a little, a bit and a bit of’’ are used to talk about small amounts`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 7,
        type: "video",
        video_URL: "https://vimeo.com/684411225/2cb2a9fb14",
        question: `Following the video lesson on ‘’Quantifiers’’, choose either A, B or C to complete the following conversation
        \n
        B) As you know, there are ___ challenges the company is facing, but I am happy to announce a new agreement with a tech company.
        A) Fantastic! That is great news!
        B) Yes, it is. Now tell me, what are you working on?
        A) We are testing the beta app and looking for flaws. We are reviewing ___ codes just to find mistakes, but the work is almost done.
        B) I am glad to hear that. Remember, there shouldn't be ___ mistake this time! Is there ___ question?`,
        options: [
          {
            id: 1,
            text: `Many / a few / any / any`,
          },
          {
            id: 2,
            text: `little / some / no / some`,
          },
          {
            id: 3,
            text: `Some / some / any / any`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 8,
        type: "video",
        video_URL: "https://vimeo.com/763927364/9eb8372416",
        question: `Based on the story ‘’Stephanie’s first meeting’’, answer if the following statement is true or false
        \n
        Regarding the last quarter’s goals, Stephanie and her team could achieve them on time.`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/763927364/9eb8372416",
        question: `Based on the story ‘’Stephanie’s first meeting’’,  choose either A, B or C to complete the missing gaps in the following statement.
        \n
        Furthermore, our company ___ 13% of the client's demands, and the CEO is pointing to me as the only responsible person`,
        options: [
          {
            id: 1,
            text: `Did meet`,
          },
          {
            id: 2,
            text: `Didn’t meet`,
          },
          {
            id: 3,
            text: `Met`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 10,
        question: ` Based on what you learned about the past simple tense verbs, what kind of verbs are begin, get, have, come, find, choose? Choose either A, B or C to answer the question`,
        options: [
          {
            id: 1,
            text: `They are regular verbs and their past tense forms are beganed, geted, haded, comed, finded, choosed`,
          },
          {
            id: 2,
            text: `They are irregular verbs and their past tense forms are began, gotten, come, found, chosen`,
          },
          {
            id: 3,
            text: `They are irregular verbs and their past tense forms are began, got, had, came, found and chose`,
          },
        ],
        answer: 3,
        points: 10,
      },
      null,
    ],
  },
  {
    id: 50695,
    questions: [
      null,
      {
        id: 1,
        type: "video",
        video_URL: "https://vimeo.com/763928228/608c31f45f",
        question: `Watch the story ‘’A menace’’, and answer the following questions: What does the expression ‘’to be in a rush’’ mean?`,
        options: [
          {
            id: 1,
            text: `A situation, person, or thing that needs attention and needs to be dealt with or solved.`,
          },
          {
            id: 2,
            text: `A situation in which you need to hurry or move somewhere quickly.`,
          },
          {
            id: 3,
            text: `The set of things that are happening and the conditions that exist at a particular time and place.`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 2,
        type: "video",
        video_URL: "https://vimeo.com/684411882/502051860a",
        question: `Based on what you learned about modals, say whether the following statement is true or false: A modal is also known as a modal verb or modal auxiliary. It can express necessity, uncertainty, possibility or permission.`,
        options: [
          {
            id: 1,
            text: `TRUE`,
          },
          {
            id: 2,
            text: `FALSE`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 3,
        question: `Based on what you learned about modals, which modals are used to refer to a conclusion we have arrived at? (MODALS OF DEDUCTION)`,
        options: [
          {
            id: 1,
            text: `Could / May / Must`,
          },
          {
            id: 2,
            text: `Shall / Should / Will / Would`,
          },
          {
            id: 3,
            text: `Must / Shall / Should / Will`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 4,
        question: `Based on what you learned about modals, which modal is used to give a piece of advice? `,
        options: [
          {
            id: 1,
            text: `MUST`,
          },
          {
            id: 2,
            text: `SHOULD`,
          },
          {
            id: 3,
            text: `WOULD`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 5,
        type: "video",
        video_URL: "https://vimeo.com/763928228/608c31f45f",
        question: `Based on the story ‘’A menace’’, choose either A, B or C to answer the following question: What kind of modal is COULD in these sentences?
        \n
        1) “Hello, Miguel. There is something weird on some file extensions. Could you please look into that?”
        \n
        2) Miguel has been working in the security field for many years, and he knows that there are people who like to cause trouble, even if it makes no sense. It could be a company's rival, or just a tech-savvy person who wants to be the evil guy.`,
        options: [
          {
            id: 1,
            text: `In the first sentence, could is used as a modal of ability and, in the second, as a modal of deduction.`,
          },
          {
            id: 2,
            text: `In the first sentence, could is used as a modal of permission and, in the second, as a modal of expectation.`,
          },
          {
            id: 3,
            text: `In the first sentence, could is used as a modal of ability and, in the second, as a modal of ability as well.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 6,
        type: "video",
        video_URL: "https://vimeo.com/684411571/d224c01eeb",
        question: `According to the video lesson ‘’reported speech’’, choose the correct option:`,
        options: [
          {
            id: 1,
            text: `Indirect speech repeats the exact words the person used, whereas direct speech changes the original speaker's words.`,
          },
          {
            id: 2,
            text: `Direct speech repeats the exact words the person used, whereas indirect speech changes the  original speaker's words.`,
          },
          {
            id: 3,
            text: `None of them is correct.`,
          },
        ],
        answer: 2,
        points: 10,
      },
      {
        id: 7,
        question: `Based on the structure of reported speech sentences, choose the correct indirect speech sentence:
        \n
        The following day, the net was not available. Miguel is doing maintenance to the main server, and he said ‘’it will take at least an hour to be completed.’’`,
        options: [
          {
            id: 1,
            text: `Indirect speech: ‘’He said it is going to take at least an hour to be completed.’’`,
          },
          {
            id: 2,
            text: `Indirect speech: ‘’He said it will take at least an hour to be completed.’’`,
          },
          {
            id: 3,
            text: `Indirect speech: ‘’He said it would take at least an hour to be completed.’’`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 8,
        question: `Based on the structure of reported speech sentences, choose the correct indirect speech sentence:
        \n
        “Miguel said "I also recommend not to open attachments on suspicious emails, and not to click on links in emails or posts on social media websites."`,
        options: [
          {
            id: 1,
            text: `Indirect speech: ‘’He said he recommended not to open attachments on suspicious emails, and not to click on links in emails or posts on social media websites.‘’`,
          },
          {
            id: 2,
            text: `Indirect speech: ‘’He said he recommends not to open attachments on suspicious emails, and not to click on links in emails or posts on social media websites.''`,
          },
        ],
        answer: 1,
        points: 10,
      },
      {
        id: 9,
        type: "video",
        video_URL: "https://vimeo.com/694471521/8987d479a8",
        question: `According to the video lesson ''What Are You Working On?, choose the correct option: `,
        options: [
          {
            id: 1,
            text: `She is working with an application for tracking purchases.`,
          },
          {
            id: 2,
            text: `She works on an application for tracking purchases.`,
          },
          {
            id: 3,
            text: `She is working on an application for tracking purchases.`,
          },
        ],
        answer: 3,
        points: 10,
      },
      {
        id: 10,
        type: "video",
        video_URL: "https://vimeo.com/697147838/e0a549d7f0",
        question: `According to the video lesson ‘’What did you do yesterday?’’, choose the correct option:`,
        options: [
          {
            id: 1,
            text: `The word YESTERDAY can be placed at the beginning, middle or end of a sentence.`,
          },
          {
            id: 2,
            text: `The word YESTERDAY can be placed at the beginning or end of a sentence.`,
          },
          {
            id: 3,
            text: `The word YESTERDAY can be placed at the beginning or middle of a sentence.`,
          },
        ],
        answer: 1,
        points: 10,
      },
      null,
    ],
  },
];

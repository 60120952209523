export const diagnosisQuestionMarketing = {
     id: 0,
     type: "radio",
     question: "_______ you send the report to the manager?",
     options: [
          { id: 1, text: "Did", points: 2 },
          { id: 2, text: "Do", points: 0 },
          { id: 3, text: "Does", points: 0 },
          { id: 4, text: "Will", points: 0 },
     ],
}

export const testQuestionsMarketing = [
     null,
     {
          id: 1,
          type: "radio",
          question: "_______ you send the report to the manager?",
          options: [
               { id: 1, text: "Did" },
               { id: 2, text: "Do" },
               { id: 3, text: "Does" },
               { id: 4, text: "Will" },
          ],
          answer: 1,
          points: 4,
     },
     {
          id: 2,
          question: "We _______ ads on Facebook.",
          options: [
               { id: 1, text: "run" },
               { id: 2, text: "ran" },
               { id: 3, text: "running" },
               { id: 4, text: "runs" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 3,
          question: "We need to _______ the audience's needs.",
          options: [
               { id: 1, text: "understand" },
               { id: 2, text: "understood" },
               { id: 3, text: "understanding" },
               { id: 4, text: "understands" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 4,
          question: "Each day, he _______ the product description.",
          options: [
               { id: 1, text: "writes" },
               { id: 2, text: "wrote" },
               { id: 3, text: "writing" },
               { id: 4, text: "write" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 5,
          question: "They _______ the sales pitch for tomorrow.",
          options: [
               { id: 1, text: "practice" },
               { id: 2, text: "practiced" },
               { id: 3, text: "are practicing" },
               { id: 4, text: "practices" },
          ],
          answer: 3,
          points: 2,
     },
     {
          id: 6,
          question: "We _______ our marketing strategy next month.",
          options: [
               { id: 1, text: "will review" },
               { id: 2, text: "reviewed" },
               { id: 3, text: "reviewing" },
               { id: 4, text: "review" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 7,
          question: "The new campaign _______ more customers.",
          options: [
               { id: 1, text: "will attract" },
               { id: 2, text: "attracted" },
               { id: 3, text: "attracting" },
               { id: 4, text: "attracts" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 8,
          question: "She _______ market research for the new product at the moment.",
          options: [
               { id: 1, text: "is conducting" },
               { id: 2, text: "conducted" },
               { id: 3, text: "conduct" },
               { id: 4, text: "conducts" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 9,
          question: "We _______ our social media accounts regularly.",
          options: [
               { id: 1, text: "update" },
               { id: 2, text: "updated" },
               { id: 3, text: "updating" },
               { id: 4, text: "updates" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 10,
          question: "_______ you attend the marketing conference next week?",
          options: [
               { id: 1, text: "Will" },
               { id: 2, text: "Would" },
               { id: 3, text: "Could" },
               { id: 4, text: "Should" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 11,
          question: "The marketing team _______ the budget for the campaign already.",
          options: [
               { id: 1, text: "has set" },
               { id: 2, text: "set" },
               { id: 3, text: "setting" },
               { id: 4, text: "sets" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 12,
          question: "We _______ to target younger customers.",
          options: [
               { id: 1, text: "need" },
               { id: 2, text: "needs" },
               { id: 3, text: "needing" },
               { id: 4, text: "needed" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 13,
          question: "The new campaign _______ by the end of the month.",
          options: [
               { id: 1, text: "will be launched" },
               { id: 2, text: "launched" },
               { id: 3, text: "launching" },
               { id: 4, text: "launch" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 14,
          question: "They _______ a focus group for the new product yesterday.",
          options: [
               { id: 1, text: "organized" },
               { id: 2, text: "organizes" },
               { id: 3, text: "organizing" },
               { id: 4, text: "organize" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 15,
          question: "We _______ the results of the market research soon.",
          options: [
               { id: 1, text: "will receive" },
               { id: 2, text: "received" },
               { id: 3, text: "receiving" },
               { id: 4, text: "receives" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 16,
          question:
               "The last week of the month, we _______ the product launch for next month.",
          options: [
               { id: 1, text: "had planned" },
               { id: 2, text: "planned" },
               { id: 3, text: "planning" },
               { id: 4, text: "plan" },
          ],
          answer: 4,
          points: 2,
     },
     {
          id: 17,
          question:
               "The marketing director _______ a presentation at the conference tomorrow night.",
          options: [
               { id: 1, text: "will give" },
               { id: 2, text: "gave" },
               { id: 3, text: "giving" },
               { id: 4, text: "gives" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 18,
          question: "The marketing team _______ the campaign for months.",
          options: [
               { id: 1, text: "has been developing" },
               { id: 2, text: "developed" },
               { id: 3, text: "developing" },
               { id: 4, text: "develops" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 19,
          question: "The company _______ sales targets for this quarter.",
          options: [
               { id: 1, text: "has set" },
               { id: 2, text: "set" },
               { id: 3, text: "setting" },
               { id: 4, text: "sets" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 20,
          question: "_______ you considered influencer marketing for your brand?",
          options: [
               { id: 1, text: "Have" },
               { id: 2, text: "Has" },
               { id: 3, text: "Had" },
               { id: 4, text: "Will" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 21,
          question: "The marketing manager _______ for the new campaign.",
          options: [
               { id: 1, text: "have a vision" },
               { id: 2, text: "had a vision" },
               { id: 3, text: "having a vision" },
               { id: 4, text: "has had a vision" },
          ],
          answer: 4,
          points: 2,
     },
     {
          id: 22,
          question: "If we _______ this opportunity, we could increase our market share.",
          options: [
               { id: 1, text: "seize" },
               { id: 2, text: "seized" },
               { id: 3, text: "seizing" },
               { id: 4, text: "will seize" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 23,
          question:
               "We _______ the target audience's behavior using analytics. We have the software to make it possible.",
          options: [
               { id: 1, text: "can analyze" },
               { id: 2, text: "could analyze" },
               { id: 3, text: "analyzed" },
               { id: 4, text: "analyzing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 24,
          question: "The new product _______ by the end of the year.",
          options: [
               { id: 1, text: "will have been launched" },
               { id: 2, text: "has been launched" },
               { id: 3, text: "launching" },
               { id: 4, text: "launched" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 25,
          question:
               "The marketing department _______ to meet their sales targets this quarter.",
          options: [
               { id: 1, text: "needs to work harder" },
               { id: 2, text: "need to work harder" },
               { id: 3, text: "needing to work harder" },
               { id: 4, text: "needed to work harder" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 26,
          question: "The marketing campaign _______ a lot of attention from the media.",
          options: [
               { id: 1, text: "has received" },
               { id: 2, text: "received" },
               { id: 3, text: "receiving" },
               { id: 4, text: "will receive" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 27,
          question:
               "The marketing team _______ their research before they launched the campaign.",
          options: [
               { id: 1, text: "had completed" },
               { id: 2, text: "completed" },
               { id: 3, text: "completing" },
               { id: 4, text: "completes" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 28,
          question: "If we had more resources, we _______ a more ambitious campaign.",
          options: [
               { id: 1, text: "could plan" },
               { id: 2, text: "plan" },
               { id: 3, text: "planned" },
               { id: 4, text: "planning" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 29,
          question: "The market _______ significantly over the last few years.",
          options: [
               { id: 1, text: "has changed" },
               { id: 2, text: "changed" },
               { id: 3, text: "changing" },
               { id: 4, text: "will change" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 30,
          question: "Have you _______ the latest marketing trends?",
          options: [
               { id: 1, text: "been following" },
               { id: 2, text: "followed" },
               { id: 3, text: "following" },
               { id: 4, text: "will follow" },
          ],
          answer: 1,
          points: 2,
     },
     null,
]

import BubbleChat from "../../assets/burbuja-chat.svg";
import StudentsPage from "pages/MyTopic";

export default function BubbleChatComponent({ handleBubble, community, secondBar, showBar, size, materialId }) {
  return (
    <>
      {community && (
        <div className={`bubble-container ${!secondBar && !showBar ? "hide-bubble" : ""}`} onClick={handleBubble}>
          <img src={BubbleChat} alt="chat" />
        </div>
      )}
      {community ? size > 1024 && <StudentsPage materialId={materialId} /> : null}
    </>
  );
}

import { API_KEY } from "./settings";
import axiosInstace from "./axiosInstance";

export const checkEmail = async (email) => {
     const url = `EmailValidation/${email}/${API_KEY}`;
     const axios = axiosInstace();
     try {
          const response = axios.get(url);
          return response.ok;
     } catch (error) {
          console.log(error)
          return false;
     }
};

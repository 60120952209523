import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings"


export default function getCountries() {
	const url = `Usuario/GetPaises/${API_KEY}`
	const axios = axiosInstance();
	return axios.get(url).then(response => {
		return response.data;
	}
	)
}
import axiosInstance from "./axiosInstance";
import { API_KEY, API_URL, USER_ID } from "./settings";

export const updateAttendance = async (attend) => {
     const url = `${API_URL}/Asistencias/Update/${API_KEY}/${USER_ID}`;
     const axios = axiosInstance();
     const body = JSON.stringify(attend)
     try {

          const res = await axios.put(url, body);
          if (!res.ok) throw new Error(`Ha ocurrido un error`);
          return {
               ok: true,
          };
     } catch (error) {
          return {
               ok: false,
               errorMessage: "Ha ocurrido un error",
          };
     }
};

import { SizeContext } from "context/SizeContext";
import { useContext } from "react";
import { Link } from "wouter";

export default function SideNavItem({ title, icon, url = "/" }) {
  const { showBar } = useContext(SizeContext);

  return (
    <Link
      href={url}
      className={`text-md group flex border-accent hover:text-white to-green w-full py-5 ${
        showBar ? "pl-10" : "no-m justify-center"
      } text-[#7DB8F2] hover:border-l-[3px] hover:bg-[#CF7831] hover:font-bold child:w-6 child:h-6`}
    >
      {icon}
      {showBar && title}
    </Link>
  );
}

import React from 'react';
import 'styles/LoadingView.css';

const LoadingView = ({user}) => {  
   const username = user.nombre.split(" ").slice(0, 1);
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
            <h2 className="text-[#7DB8F2] text-[26px] font-roboto font-[600]">Welcome back, {username}</h2> 
            <h3 className="text-[#7DB8F2] text-[22px] font-roboto font-[500]">Loading your profile, please wait...</h3>
        </div>
    );
};

export default LoadingView;
export const UserSquareForm = ({ register, handleSubmit, onSubmit }) => {
     return (
          <form className='user-card-form' onSubmit={handleSubmit(onSubmit)}>
               <label className='label-card'>
                    Email
                    <input
                         type='email'
                         className='input-card label-user-input'
                         {...register("email")}
                    />
               </label>
               <label className='label-card'>
                    Phone
                    <input
                         type='text'
                         className='input-card label-user-input'
                         {...register("telefono")}
                    />
               </label>
               <input className="hidden" type="submit" value="" />
          </form>
     )
}

import { firestore } from "../firebase/credentials";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { USER_ID } from "services/settings";
import { MeetingsContext } from "./meetingsContext";

const MeetingsProvider = ({ children }) => {
  const [appointmentsAvailable, setAppointmentsAvailable] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [meetingsInfo, setMeetingsInfo] = useState("");

  const updatedTime = async () => {
    const timeRef = doc(firestore, "meetings", "serverTime");
    // Update the timestamp field with the value from the server
    await updateDoc(timeRef, {
      timestamp: serverTimestamp(),
    });

    const docSnap = await getDoc(timeRef);

    const currentTime = await docSnap.data().timestamp.seconds;

    return currentTime;
  };

  const runOperations = async (custom) => {
    const meetingsRef = doc(firestore, "meetings", `${USER_ID}`);
    const meetingsDoc = await getDoc(meetingsRef);

    if (meetingsDoc.exists()) {
      let data = meetingsDoc.data();

      const { appointmentsLeft, meetingsToDo, lastUpdate } = data;

      setMeetingsInfo(data);

      if (custom) {
        custom(meetingsToDo);
      }

      //Left
      setAppointmentsAvailable(appointmentsLeft);

      //Recharge
      let now = await updatedTime();

      let currentMonth = new Date(Number(now.toString() + "000")).getMonth();
      let lastUpdateMonth = new Date(
        Number(lastUpdate.toString() + "000")
      ).getMonth();

      // console.log("Current:", currentMonth, "Last:", lastUpdateMonth);

      if (currentMonth > lastUpdateMonth) {
        await setDoc(
          meetingsRef,
          {
            ...data,
            appointmentsLeft: 12,
            lastUpdate: now,
          },
          { merge: true }
        );

        runOperations();
      }
    } else {
      let now = await updatedTime();

      await setDoc(
        meetingsRef,
        {
          meetingsToDo: [
            {
              isBooked: false,
            },
            {
              isBooked: false,
            },
            {
              isBooked: false,
            },
            {
              isBooked: false,
            },
          ],
          appointmentsLeft: 12,
          lastUpdate: now,
        },
        { merge: true }
      );

      console.log("New appointment record");
      setAppointmentsAvailable(12);
      setLastUpdate(now);

      runOperations();
    }
  };

  useEffect(() => {
    runOperations();

    //eslint-disable-next-line
  }, []);

  return (
    <MeetingsContext.Provider
      value={{ runOperations, appointmentsAvailable, lastUpdate, meetingsInfo }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};

export default MeetingsProvider;

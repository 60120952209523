import { Button, Grid, Typography } from "@mui/material"

export const UpgradePlanPage = () => {
    const userInfo = JSON.parse(localStorage.getItem("loggedAppUser"))
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Grid sx={boxContainer}>
                <Typography sx={{ fontFamily: `'roboto', sans-serif`, color:`#7DB8F2` }}>
                    Para disfrutar de esta función actualiza tu suscripción
                </Typography>
                <a href={`https://www.digispeak.io/?email=${userInfo.email}&plan=${userInfo.id}&user=${userInfo.id}`} target='_blank' rel='noopener noreferrer'>
                    <Button variant='contained' sx={{ backgroundColor: "#CF7831", borderRadius: '10px' }}>
                        Actualizar suscripción
                    </Button>
                </a>
            </Grid>
        </div>
    )
}

const boxContainer = {
    backgroundColor: "#12171C",
    p: 5,
    ":focus": { outline: "none" },
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
}
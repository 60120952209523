import { useState, useEffect } from "react";
import getCommentsByMaterialId from "services/getCommentsByMaterialId";
import postComment from "services/postComment";
import CommentsList from "components/material-content-section/CommentList";

function MaterialCommentsSection({ courseId, materialId, isActive = false }) {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (comment) {
      const data = {
        UsuarioId: user.id,
        MaterialId: materialId,
        CursoId: courseId,
        Comentario: comment,
        ComentarioId: 0,
      };
      const update = [...comments];
      update.push({
        id: 999999,
        alumno: user.nombre,
        fecha: "",
        respuestas: [],
        comentario: comment,
      });
      setComments(update);
      postComment({ comment: data });
    }
  };

  useEffect(() => {
    if (isActive)
      getCommentsByMaterialId({ id: materialId }).then((comments) =>
        setComments(comments)
      );
  }, [isActive, materialId]);

  return isActive ? (
    <div className="flex w-full flex-col items-center gap-5 bg-[#1B212B] p-5 md:p-10">
      <CommentsList
        comments={comments}
        courseId={courseId}
        materialId={materialId}
        userId={user.id}
      />
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-3xl rounded-lg border-2 border-[#CF7831] bg-transparent p-5 shadow-sm"
      >
        <label className="text-[#7DB8F2]">Leave a comment</label>
        <textarea
          onChange={handleChange}
          type="textarea"
          value={comment}
          className="h-20 w-full rounded-xl border border-slate-200 p-1 bg-transparent text-white"
        />
        <button className="bg-[#CF7831] text-white m-1 rounded-lg p-2">
          Comment
        </button>
      </form>
    </div>
  ) : (
    <></>
  );
}

export default MaterialCommentsSection;

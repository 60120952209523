import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export const getAssistancesByProfessor = async () => {
     try {
          const url = `Asistencias/GetAllByProfesor/${USER_ID}/${API_KEY}/${USER_ID}`;
          const axios = axiosInstance();
          const res = await axios.get(url);
          if (!res.ok) throw new Error(`Ha ocurrido un error`);
          const data = await res.json();
          return {
               ok: true,
               data,
          };
     } catch (error) {
          return {
               ok: false,
               errorMessage: error,
          };
     }
};

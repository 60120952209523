import axiosInstance from "./axiosInstance";
import { USER_ID, API_KEY } from "./settings";

export const postAttendance = async (attendance) => {
  const url = `Asistencias/CreateGroup/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  const body = JSON.stringify(attendance);
  try {
    const res = await axios.post(url, body);
    if (!res.ok) {
      throw new Error(`Ha ocurrido un error`);
    }
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      errorMessage: error.message || "Ha ocurrido un error",
    };
  }
};

export default function SideBarSkeleton() {
    return (
        <div className="relative hidden min-h-[650px] max-h-[900px] w-1/3 pl-2 rounded-xl border-2 border-gray-300 bg-transparent flex-col animate-pulse lg:flex lg:ml-5">

        <div className="absolute z-50 h-10 w-10 bg-gray-300 rounded-full hidden lg:flex items-center justify-center"></div>
  
        <header className="flex flex-col gap-5 pt-2 pl-5">
          
          <div className="flex items-center gap-2">
            <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
            <div className="h-5 w-20 bg-gray-300 rounded-md"></div>
          </div>
  
          <div className="h-6 w-3/4 bg-gray-300 rounded-md"></div>
        </header>

        <div className="mt-5 flex flex-col gap-3 pl-5 w-full">
          <div className="h-4 w-3/4 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-2/3 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-1/2 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    );
  }
  
import { useState, useEffect, useContext } from "react";
import { useLocation } from "wouter";
import getMaterialById from "services/getMaterialById";
import { postMaterialCompleteAsync } from "services/postMaterialCompleteAsync";
import RecommendedPopUp from "./RecommendedPopUp";
import { SizeContext } from "context/SizeContext";
import { CoursesContext } from "context/coursesContext";
import { UpgradePlanPage } from "components/UpgradePlanPage";

import { MaterialSkeleton } from "components/material-content-section/skeletons-material-content/material-skeleton";

import MaterialContentSection from "components/material-content-section/MarerialContentSection";
import MaterialAboutSection from "components/material-content-section/MaterialAboutSection";
import MaterialCommentsSection from "components/material-content-section/MaterialCommentSection";
import BubbleChatComponent from "components/material-content-section/BubbleComponent";
import TopicsSidebar from "components/material-content-section/TopicSideBar";
import StudentsPage from "pages/MyTopic";
import ReactPlayer from "react-player/vimeo";

export default function MaterialPage({
  params,
  community = true,
  url,
  requiredPlanFeature,
}) {
  const { courseId, moduleId, materialId, bubbleId } = params;
  const { courses } = useContext(CoursesContext);
  const { secondBar, showBar, setShowBar, setSecondBar, handleSecondBar } =
    useContext(SizeContext);
  const [, setLocation] = useLocation();
  const { size } = useContext(SizeContext);

  const [material, setMaterial] = useState();
  const [course, setCourse] = useState({});
  const [isActive, setIsActive] = useState({
    about: true,
    comments: false,
    content: false,
  });
  const [recommendedMaterial, setRecommendedMaterial] = useState(null);
  const [isLoadingMaterial, setIsLoadingMaterial] = useState(true);

  useEffect(() => {
    async function fetchCourseAndMaterial() {
      const fetchedCourse = courses.find(
        (course) => course.id === parseInt(courseId)
      );
      if (fetchedCourse) {
        const orderedCourse = {
          ...fetchedCourse,
          modulos: fetchedCourse.modulos
            .map((mod) => ({
              ...mod,
              clases: mod.clases.sort((a, b) => a.claseNumero - b.claseNumero),
            }))
            .filter((mod) =>
              community ? mod.id.toString() === moduleId : true
            ),
        };

        setCourse(orderedCourse);

        try {
          const fetchedMaterial = await getMaterialById({ id: materialId });
          setMaterial(fetchedMaterial);
        } catch (error) {
          console.error("Error fetching material:", error);
        } finally {
          setIsLoadingMaterial(false);
        }
      }
    }
    fetchCourseAndMaterial();
  }, [materialId, courseId, community, moduleId, courses]);

  const updateCompleteVideo = async () => {
    const newData = {
      ...course,
      modulos: course.modulos.map((modules) => {
        modules.clases.map((videoClass) => {
          if (videoClass.id === parseInt(materialId)) {
            videoClass.completada = true;
          }
          return videoClass;
        });
        return modules;
      }),
    };
    setCourse(newData);

    try {
      const { ok, recommended } = await postMaterialCompleteAsync({
        materialId,
        classNum: material.claseNumero,
      });
      if (ok && recommended) {
        setRecommendedMaterial(recommended);
      }
    } catch (error) {
      console.error("Error updating video completion:", error);
    }
  };

  if (isLoadingMaterial) {
    return (
      <MaterialSkeleton />
    );
  }

  if (!material) return <UpgradePlanPage feature={requiredPlanFeature} />;

  const handleTabClick = (tab) => {
    setIsActive({
      about: tab === "about",
      comments: tab === "comments",
      content: tab === "content",
    });
  };

  const handleBubble = () => {
    setShowBar(false);
    setSecondBar(false);
  };

  const coursesPacedURL = `/${url}/${courseId}/`;
  const normalURL = `/${url}/bubble/${courseId}/${bubbleId}`;

  async function handleNextMaterial() {
    const moduleI = parseInt(moduleId);
    const materialI = parseInt(materialId);
    const currentModule = course.modulos.find(({ id }) => id === moduleI);
    const currentMaterial = currentModule.clases.find(
      ({ id }) => id === materialI
    );
    const lastModule = course.modulos[course.modulos.length - 1];
    const lastClass = lastModule.clases[lastModule.clases.length - 1];
    if (materialI === lastClass.id) {
      const { ok } = await postMaterialCompleteAsync({
        materialId,
        classNum: material.claseNumero,
      });
      const bubbleURL = `/${url}/bubble/${courseId}/${bubbleId}`;
      const normalURL = `/${url}/${courseId}`;
      setLocation(url === "courses" ? bubbleURL : normalURL);
      return;
    }
    const currentModuleIndex = course.modulos.findIndex(
      (module) => module.id === moduleI
    );
    const currentMaterialIndex = currentModule.clases.findIndex(
      (material) => material.id === materialI
    );

    if (currentMaterial.completada) {
      if (currentMaterialIndex === currentModule.clases.length - 1) {
        const nextModule = course.modulos[currentModuleIndex + 1];
        setLocation(
          `/${url}/${courseId}/module/${nextModule.id}/material/${
            nextModule.clases[0].id
          }/${community ? bubbleId : ""}`
        );
      } else {
        const nextMaterial = currentModule.clases[currentMaterialIndex + 1];
        setLocation(
          `/${url}/${courseId}/module/${moduleId}/material/${nextMaterial.id}/${
            community ? bubbleId : ""
          }`
        );
      }
    } else {
      if (currentMaterialIndex === currentModule.clases.length - 1) {
        const nextModule = course.modulos[currentModuleIndex + 1];

        setLocation(
          `/${url}/${courseId}/module/${nextModule.id}/material/${
            nextModule.clases[0].id
          }/${community ? bubbleId : ""}`
        );
      } else {
        const nextMaterial = currentModule.clases[currentMaterialIndex + 1];

        setLocation(
          `/${url}/${courseId}/module/${moduleId}/material/${nextMaterial.id}/${
            community ? bubbleId : ""
          }`
        );
      }
    }
  }

  return (
    <>
      {recommendedMaterial && (
        <RecommendedPopUp
          recommended={recommendedMaterial}
          onCancel={setRecommendedMaterial}
        />
      )}
      <div className="flex flex-col w-full px-5">
        <section className="bg-[#333333] flex flex-col lg:flex-row lg:min-h-[700px] items-center">
          <TopicsSidebar
            secondBar={secondBar}
            handleSecondBar={handleSecondBar}
            course={course}
            courseId={courseId}
            bubbleId={bubbleId}
            community={community}
            url={url}
            coursesPacedURL={coursesPacedURL}
            normalURL={normalURL}
          />
          <div className="lg:-max-h-none flex max-h-[900px] w-full max-w-[50rem] min-w-[90vw] lg:min-w-[50rem] flex-col items-center lg:w-[65%] lg:max-w-none lg:px-5 lg:pt-10">
            <ReactPlayer
              url={material.linkVideo}
              height="80%"
              width="100%"
              controls
              className="aspect-video"
              onEnded={updateCompleteVideo}
            />
            <div className="flex w-full items-center justify-between gap-2 p-5">
              <h1
                className="font-Barlow text-2xl font-semibold text-[#7DB8F2]"
                dangerouslySetInnerHTML={{ __html: material.nombre }}
              ></h1>
              <button
                className="bg-[#CF7831] text-white w-40 rounded-3xl p-2"
                onClick={handleNextMaterial}
              >
                next class
              </button>
            </div>
          </div>
          <BubbleChatComponent
            handleBubble={handleBubble}
            community={community}
            secondBar={secondBar}
            showBar={showBar}
            size={size}
            materialId={materialId}
          />
        </section>
        <section>
          {community && size <= 1024 && (
            <StudentsPage materialId={materialId} />
          )}
          <header className="h-20 w-full border-gray-200 bg-[#1B212B] px-10 shadow-sm">
            <ul className="flex h-full items-center gap-5">
              {["about", "comments", "content"].map((tab) => (
                <li
                  key={tab}
                  className={`border-[#CF7831] font-Barlow text-[#7DB8F2] text-lg font-semibold hover:border-b-4 ${
                    tab === "content" ? "lg:hidden" : ""
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </li>
              ))}
            </ul>
          </header>
          <MaterialAboutSection isActive={isActive.about} moduleId={moduleId} />
          <MaterialCommentsSection
            courseId={courseId}
            materialId={materialId}
            isActive={isActive.comments}
          />
          <MaterialContentSection
            courseId={courseId}
            course={course}
            isActive={isActive.content}
          />
        </section>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

import { USER_ID } from "services/settings";
import { updateBubble } from "./calls";

import { useVideolessonsTable } from "./useVideolessonsTable";
import { ServerDataContext } from "./ServerDataContext";
import { SnackbarComponent } from "./SnackbarComponent";

const initialSnackBar = {
  isSnackBarOpen: false,
  severity: "success",
  message: "The data has been successfully updated",
};

const errorSnackBar = {
  isSnackBarOpen: true,
  severity: "error",
  message: "An error has occurred",
};

export const StudentDataGrid = ({
  user,
  courseId,
  bubbleSelectValue,
  openModal,
}) => {
  const { serverData, setUpdater } = useContext(ServerDataContext);

  const [contentArray, setContentArray] = useState(null);
  const [bubbleObservations, setBubbleObservations] = useState("");
  const [score, setScore] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);

  // Bubble initial values
  const [initialState, setInitialState] = useState(null);

  useEffect(() => {
    const [firstArray] = serverData.filter(
      (student) => student.studentId === user.id
    );

    if (firstArray) {
      const [cursoArray] = firstArray.cursos.filter((curso) => {
        if (curso.cursoid === courseId) {
          return curso;
        } else {
          return false;
        }
      });

      if (cursoArray) {
        const [bubbleArray] = cursoArray.bubbles.filter(
          (bubble) => bubble.bubbleId === bubbleSelectValue.id
        );

        if (bubbleArray) {
          setContentArray(bubbleArray);
          setBubbleObservations(bubbleArray.observations);
          setScore(bubbleArray.score);
          setInitialState({
            observations: bubbleArray.observations,
            score: bubbleArray.score,
          });
        } else {
          setContentArray(null);
          setBubbleObservations("");
          setScore("");
        }
      }
    }
  }, [serverData, bubbleSelectValue, user, courseId]);

  const { columns, rows } = useVideolessonsTable(
    contentArray,
    bubbleSelectValue
  );

  const handleSubmit = async () => {
    setIsLoading(true);

    const obj = {
      teacherId: USER_ID,
      studentId: user.id,
      cursoId: courseId,
      bubbleId: bubbleSelectValue.id,
      observations: bubbleObservations,
      score,
    };

    const { ok, data } = await updateBubble(
      obj,
      contentArray ? contentArray.id : ""
    );

    if (ok) {
      setSnackBarInfo({ ...initialSnackBar, isSnackBarOpen: true });

      console.log(!contentArray ? `Se ha creado la burbuja: ${data.id}` : data);

      setUpdater(Date.now());
      setIsLoading(false);
    } else {
      setIsLoading(false);

      setSnackBarInfo({ ...errorSnackBar });
    }
  };

  const closeSnackBar = () => {
    setSnackBarInfo({
      ...snackBarInfo,
      isSnackBarOpen: false,
    });
  };

  return (
    <div className="followup-user-box" key={user.id}>
      <h2 className="text-primary text-lg mb-6 ">
        Student: <span className="font-semibold">{user.nombre}</span>
      </h2>

      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          onCellDoubleClick={(params) =>
            openModal(
              params,
              user.id,
              bubbleSelectValue.id,
              courseId,
              contentArray === null ? true : false
            )
          }
        />
      </Box>

      <div className="w-full flex">
        <TextField
          className="observations-basic"
          label="Observations of the content bubble"
          variant="outlined"
          sx={{ marginRight: "1rem" }}
          multiline
          fullWidth
          value={bubbleObservations}
          onChange={(event) => {
            setBubbleObservations(event.target.value);
          }}
        />
        <TextField
          type="text"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
          className="score-basic"
          label="Score"
          variant="outlined"
          sx={{ width: "10rem", marginRight: "1rem" }}
          value={score}
          onChange={(event) => {
            const regex = /^[0-9\b]+$/;
            if (event.target.value === "" || regex.test(event.target.value)) {
              setScore(event.target.value);
            }
          }}
        />
        <Button
          variant="contained"
          size="large"
          sx={{ width: "20rem", maxHeight: "3.5rem" }}
          disableElevation
          disabled={
            isLoading ||
            (initialState &&
              initialState.observations === bubbleObservations &&
              initialState.score === score) ||
            (bubbleObservations === "" && score === "")
          }
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </div>

      <SnackbarComponent closeSnackBar={closeSnackBar} {...snackBarInfo} />
    </div>
  );
};

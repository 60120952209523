
export default function RecommendedPopUp({ recommended, onCancel }) {
    return (
        <>
            <div
                className="confirmation-modal-speaking opacity-0 fixed top-0 min-h-screen bg-[#1B212B] flex justify-center items-center"
                style={{
                    backgroundColor: "#94a3b840",
                    animation: ".3s ease-in-out forwards appear",
                    zIndex: 5000,
                }}
            >
                <div className="h-min flex flex-col justify-center text-center items-center p-6 md:px-10 rounded-lg bg-[#1B212B]">

                    <strong className="text-[#7DB8F2] mb-5">
                        Congratulations!
                    </strong>
                    <p className="text-[#7DB8F2]">{recommended.popUpText}</p>
                    <strong className="text-base font-medium mb-5 leading-5 mt-4 text-[#7DB8F2]">{recommended.name}</strong>
                    <p className="text-[#7DB8F2]">{recommended.isSpeakingSession ? "Do you want to go to speaking sessions now?" : "Do you want to go to the module now?"}</p>
                    <div className="flex mt-4">
                        <button
                            className="p-2 px-8 rounded-full bg-green-400 hover:bg-green-500 text-white font-semibold w-fit mr-2"
                            onClick={() => window.location.href = recommended.url}
                        >
                            Yes
                        </button>
                        <button
                            className="p-2 px-8 rounded-full bg-red-600 hover:bg-red-700  text-white font-semibold w-fit"
                            onClick={() => { onCancel() }}
                        >
                            Not now
                        </button>
                    </div>
                </div>
            </div >
        </>
    )
}
import React, { useState } from "react"

//Components
import TopComponent from "../TopComponent"
import Welcome from "./Welcome"
import Form from "./Form"
import { useEffect } from "react"

//Maximum number of scroll screens
const screenLimit = 1

const Verification = ({ setEmailIsAuthorized, setUserHasBestPlan }) => {
     const [screen, setScreen] = useState(0)
     const [pulledInfo, setPulledInfo] = useState(null)

     const goForward = () => {
          // if (screen < screenLimit) {
          //   setScreen(screen + 1);
          // }
     }

     useEffect(() => {
          if (screen < screenLimit) {
               setScreen(screen + 1)
          }
     }, [])

     return (
          <TopComponent>
               {/* <Welcome
        screen={screen}
        goForward={goForward}
        setUserHasBestPlan={setUserHasBestPlan}
        setPulledInfo={setPulledInfo}
      /> */}
               <Form
                    screen={screen}
                    setEmailIsAuthorized={setEmailIsAuthorized}
                    pulledInfo={pulledInfo}
               />
               {/* <Form2 /> */}
          </TopComponent>
     )
}

export default Verification

import ReactPlayer from "react-player";

export default function VideoSection() {
  return (
    <div className="flex flex-col items-center w-full max-w-[50rem] min-w-[90vw] lg:min-w-[50rem] lg:w-[65%] max-h-[900px] lg:px-5 lg:pt-10 animate-pulse">

      <div className="aspect-video w-full bg-gray-300 rounded-lg mb-5"></div>

      <div className="flex w-full items-center justify-between gap-2 p-5">

        <div className="h-8 w-1/2 bg-gray-300 rounded-md"></div>

        <div className="h-10 w-32 bg-gray-300 rounded-3xl"></div>
      </div>
    </div>
  );
}

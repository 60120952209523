import axiosInstance from "./axiosInstance";
import { API_KEY} from "./settings";

export const getAllClassMaterial = async () => {
  const url = `additional_Material/GetAll/${API_KEY}`;
  const axios = axiosInstance();
  const res = await axios.get(url);
  const aditionalMaterial = res.data;
  return {
    ok: true,
    aditionalMaterial,
  };
};

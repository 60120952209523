import { useContext, useEffect, useState } from "react";
import "./styles.scss";

import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { CoursesContext } from "context/coursesContext";
import ServerDataProvider from "./ServerDataProvider";
import { StudentClassModal } from "./StudentClassModal";
import { StudentDataGrid } from "./StudentDataGrid";
import GenerateReports from "./GenerateReports";
import { API_ROOT } from "services/settings";

const initialModal = {
  isModalOpen: false,
  modalData: { indicator: "", observations: "", noData: true },
};

export default function FollowUpPage() {
  const { courses } = useContext(CoursesContext);

  const [students, setStudents] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState([]);
  const [courseSelectValue, setCourseSelectValue] = useState("");
  const [bubbleSelectValue, setBubbleSelectValue] = useState("");
  const [modalInfo, setModalInfo] = useState(initialModal);

  useEffect(() => {
    const getAllStudents = async () => {
      const res = await fetch(
        API_ROOT + "/Usuario/GetByPlan/pro/1234/1007"
      );
      const allUsers = await res.json();
      const onlyStudentsArray = allUsers.filter((obj) => obj.rol === "Alumno");

      setStudents(onlyStudentsArray);
    };

    getAllStudents();
  }, []);

  const autocompleteDefaultProps = {
    multiple: true,
    options: students,
    getOptionLabel: (option) => {
      return `${option.nombre} - ${option.email}`;
    },
    autoHighlight: true,
  };

  const openModal = ({ row }, userId, bubbleId, cursoId, noBubble) => {
    setModalInfo({
      isModalOpen: true,
      modalData: row,
      userId,
      bubbleId,
      cursoId,
      noBubble,
    });
  };

  const closeModal = () => {
    setModalInfo(initialModal);
  };

  return (
    <ServerDataProvider>
      <div className="w-full p-5 mb-20">
        <div className="flex w-full flex-col gap-5 rounded-xl border border-gray-200 bg-white p-5 shadow-sm">
          <h1 className="text-primary text-xl font-semibold">
            Follow Up<span className="text-accent">.</span>
          </h1>
          <h2 className="text-primary text-lg">Select one or more students</h2>
          {students.length > 0 ? (
            <Autocomplete
              {...autocompleteDefaultProps}
              value={autocompleteValue}
              onChange={(event, newValue) => {
                setAutocompleteValue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Students" />
              )}
            />
          ) : (
            <p className="text-slate-500">Loading ...</p>
          )}
          {courses.length > 0 ? (
            <FormControl>
              <InputLabel id="course-simple-select">Course</InputLabel>
              <Select
                labelId="course-simple-select"
                label="Course"
                value={courseSelectValue}
                onChange={(event) => {
                  setCourseSelectValue(event.target.value);
                  setBubbleSelectValue("");
                }}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course}>
                    {course.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <p className="text-slate-500">Loading ...</p>
          )}
          {courseSelectValue && (
            <FormControl>
              <InputLabel id="bubble-simple-select">Content Bubble</InputLabel>
              <Select
                labelId="bubble-simple-select"
                label="Content Bubble"
                value={bubbleSelectValue}
                onChange={(event) => {
                  setBubbleSelectValue(event.target.value);
                }}
              >
                {courseSelectValue.getmodulos.map((module) => (
                  <MenuItem key={module.id} value={module}>
                    {module.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {bubbleSelectValue &&
            autocompleteValue.map((user) => {
              return (
                <>
                  <StudentDataGrid
                    key={user.id}
                    user={user}
                    courseId={courseSelectValue.id}
                    bubbleSelectValue={bubbleSelectValue}
                    openModal={openModal}
                    courseName={courseSelectValue.nombre}
                  />
                </>
              );
            })}

          <StudentClassModal {...modalInfo} closeModal={closeModal} />

          <GenerateReports
            autocompleteValue={autocompleteValue}
            courseSelectValue={courseSelectValue}
          />
        </div>
      </div>
    </ServerDataProvider>
  );
}

import { MeetingsContext } from "context/meetingsContext";
import { useContext, useEffect, useState } from "react";
import Bar from "./Bar";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";
import { CoursesContext } from "context/coursesContext";

const getCompleted = (courses) => {
  const completed = courses.courses.filter(
    (course) => course.porcentajeCompletado === 100
  );
  return completed.length;
};

const getInProgress = (courses) => {
  const inProgress = courses.courses.filter((course) => {
    if (course.porcentajeCompletado !== 100) {
      const { nombre, porcentajeCompletado } = course;
      return { nombre, porcentajeCompletado };
    }
  });

  return inProgress;
};

const getSeparateCourses = (courses) => {
  let main = [];
  let secundary = [];

  const levelsToCheck = ['Junior', 'Middle', 'Senior', 'Expert'];

  courses.courses.forEach((course) => {
    
    if (  
      levelsToCheck.some(level => course.planEstudio.includes(level))
    ) {
      const { nombre, porcentajeCompletado } = course;
      main.push({ nombre, porcentajeCompletado });
    } else {
      const { nombre, porcentajeCompletado } = course;
      if (porcentajeCompletado > 0) {
        secundary.push({ nombre, porcentajeCompletado });
      }
    }
  });

  secundary = secundary.sort(
    (a, b) => b.porcentajeCompletado - a.porcentajeCompletado
  );

  return [main, secundary.slice(0, 5)];
};

export default function DashboardInfo() {
  const [completed, setCompleted] = useState(null);
  const [inProgress, setInProgress] = useState([]);
  const [separateCourses, setSeparateCourses] = useState(null);
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));
  const username = user.nombre.split(" ").slice(0, 1);
  const courses = useContext(CoursesContext);
  const { appointmentsAvailable } = useContext(MeetingsContext);
  const [isSelfOpen, setIsSelfOpen] = useState(true);
  const [isPacedOpen, setIsPacedOpen] = useState(false);

  useEffect(() => {
    if (courses) {
      setSeparateCourses(getSeparateCourses(courses));
      setCompleted(getCompleted(courses));
      setInProgress(getInProgress(courses));
      
    }
  }, [user.id, courses]);



  return (
    <div className="flex w-full h-fit flex-col gap-3 bg-transparent p-5 text-gray-50">
      <div className="flex flex-col">
        <span className="text-center text-xl font-semibold ">
          Hey, {username}
          <span className="text-accent">.</span>
        </span>
        <span className="text-center text-sm">
          Welcome back, nice to see you again!
        </span>
      </div>
      {completed !== null ? (
        inProgress.length > 0 ? (
          <>
            <div className="grid auto-cols-min grid-cols-2 gap-5">
              <div className="flex flex-col xl:flex-row items-center rounded-lg border-[#CF7831] border-2 bg-transparent p-4">
                <div className="flex  shrink-0 h-8 w-8 items-center justify-center rounded-full text-xl font-bold text-[#7DB8F2]">
                  <span>{completed}</span>
                </div>
                <span className="text-center font-semibold text-[#7DB8F2] xl:ml-2 xl:text-left ">
                  Completed Courses
                </span>
              </div>
              <div className="flex flex-col xl:flex-row  items-center rounded-lg border-[#CF7831] border-2 bg-transparent p-4">
                <div className="flex shrink-0 h-8 w-8  items-center justify-center rounded-full text-xl font-bold text-[#7DB8F2]">
                  <span>{inProgress.length}</span>
                </div>
                <span className="text-center font-semibold text-[#7DB8F2] xl:ml-2 xl:text-left">
                  Courses In Progress
                </span>
              </div>
            </div>

            <div className="flex flex-col overflow-hidden">
              <strong className="font-semibold  text-lg mb-2">
                Learning Path Progress
              </strong>
              <div
                className="flex flex-col justify-start transition-all duration-500 ease-in-out origin-top overflow-hidden"
                style={{ height: isSelfOpen ? "100%" : "0px" }}
              >
                {separateCourses[0].map(
                  ({ nombre, porcentajeCompletado }, index) => {
                    return (
                      <Bar
                        key={nombre + index}
                        nombre={nombre}
                        porcentajeCompletado={porcentajeCompletado}
                      />
                    );
                  }
                )}
              </div>
              <button
                onClick={() => {
                  setIsSelfOpen(!isSelfOpen);
                }}
                className={"cursor-pointer"}
              >
                <ChevronDown
                  className="w-full  h-8 transition-all duration-500 ease-in-out"
                  style={{ transform: `rotateZ(${isSelfOpen ? 180 : 0}deg)` }}
                />
              </button>
            </div>

            <div className="flex flex-col overflow-hidden">
              <strong className="font-semibold text-lg mb-2">
                Self-Paced Courses Progress
              </strong>
              <div
                className="flex flex-col justify-start transition-all duration-500 ease-in-out origin-top overflow-hidden"
                style={{ height: isPacedOpen ? "100%" : "0px" }}
              >
                {separateCourses[1].map(
                  ({ nombre, porcentajeCompletado }, index) => {
                    return (
                      <Bar
                        key={nombre + index}
                        nombre={nombre}
                        porcentajeCompletado={porcentajeCompletado}
                      />
                    );
                  }
                )}
              </div>
              <button
                onClick={() => {
                  setIsPacedOpen(!isPacedOpen);
                }}
                className={"cursor-pointer"}
              >
                <ChevronDown
                  className="w-full h-8 transition-all duration-500 ease-in-out"
                  style={{ transform: `rotateZ(${isPacedOpen ? 180 : 0}deg)` }}
                />
              </button>
            </div>

            <div className="flex flex-col gap-3 mb-4">
              <strong className="font-semibold text-lg">
                Practical sessions available this month{" "}
              </strong>
              <p className="font-medium mr-3 whitespace-nowrap">
                {`${appointmentsAvailable} out of 12`}
              </p>
            </div>
          </>
        ) : (
          <p className="w-full text-center">Loading...</p>
        )
      ) : (
        <p className="w-full text-center">Loading...</p>
      )}
    </div>
  );
}
export const diagnosisQuestionTech = {
     id: 0,
     type: "radio",
     question: "1. __________ you a software developer?",
     options: [
          { id: 1, text: "Are", points: 2 },
          { id: 2, text: "Is", points: 0 },
          { id: 3, text: "Am", points: 0 },
          { id: 4, text: "Be", points: 0 },
     ],
}

export const testQuestionsTech = [
     null,
     {
          id: 1,
          type: "radio",
          question: "1. __________ you a software developer?",
          options: [
               { id: 1, text: "Are" },
               { id: 2, text: "Is" },
               { id: 3, text: "Am" },
               { id: 4, text: "Be" },
          ],
          answer: 2,
          points: 4,
     },
     {
          id: 2,
          question: "The stack I __________ is LAMP",
          options: [
               { id: 1, text: "is using" },
               { id: 2, text: "use" },
               { id: 3, text: "used" },
               { id: 4, text: "am using" },
          ],
          answer: 2,
          points: 2,
     },
     {
          id: 3,
          question: "We __________ to test the code before we release it.",
          options: [
               { id: 1, text: "need" },
               { id: 2, text: "needs" },
               { id: 3, text: "needing" },
               { id: 4, text: "needing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 4,
          question: "She __________ JavaScript.",
          options: [
               { id: 1, text: "likes." },
               { id: 2, text: "like." },
               { id: 3, text: "liked." },
               { id: 4, text: "is liking." },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 5,
          question: "I __________ new to programming.",
          options: [
               { id: 1, text: "am" },
               { id: 2, text: "is" },
               { id: 3, text: "are" },
               { id: 4, text: "be" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 6,
          question: `He __________ his code carefully every time before delivery.`,
          options: [
               { id: 1, text: "checks" },
               { id: 2, text: "check" },
               { id: 3, text: "checked" },
               { id: 4, text: "is checking" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 7,
          question: "We __________ to use Python for this project.",
          options: [
               { id: 1, text: "decided" },
               { id: 2, text: "decide" },
               { id: 3, text: "deciding" },
               { id: 4, text: "decides" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 8,
          question: "They __________ the new feature tomorrow.",
          options: [
               { id: 1, text: "implement" },
               { id: 2, text: "implements" },
               { id: 3, text: "implemented" },
               { id: 4, text: "are implementing" },
          ],
          answer: 4,
          points: 2,
     },
     {
          id: 9,
          question: "I __________ to learn more about databases.",
          options: [
               { id: 1, text: "want" },
               { id: 2, text: "wants" },
               { id: 3, text: "wanted" },
               { id: 4, text: "wanting" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 10,
          question: "She __________ to a client right now.",
          options: [
               { id: 1, text: "talks" },
               { id: 2, text: "talk" },
               { id: 3, text: "talked" },
               { id: 4, text: "is talking" },
          ],
          answer: 4,
          points: 2,
     },
     {
          id: 11,
          question:
               "The software engineer __________ the project by herself last night, at 10 pm.",
          options: [
               { id: 1, text: "finished" },
               { id: 2, text: "finish" },
               { id: 3, text: "finishes" },
               { id: 4, text: "is finishing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 12,
          question: "We __________ to refactor the code next week.",
          options: [
               { id: 1, text: "plan" },
               { id: 2, text: "plans" },
               { id: 3, text: "planned" },
               { id: 4, text: "planning" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 13,
          question: "He __________ his first app last year.",
          options: [
               { id: 1, text: "developed" },
               { id: 2, text: "develop" },
               { id: 3, text: "develops" },
               { id: 4, text: "developing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 14,
          question: "They __________ to hire more developers soon.",
          options: [
               { id: 1, text: "need" },
               { id: 2, text: "needs" },
               { id: 3, text: "needed" },
               { id: 4, text: "needing" },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 15,
          question: " I __________ this code before, but I can't remember how to do it.",
          options: [
               {
                    id: 1,
                    text: "have done",
               },
               {
                    id: 2,
                    text: "had done",
               },
               {
                    id: 3,
                    text: "did",
               },
               {
                    id: 4,
                    text: "do",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 16,
          question: "By the time we release the app, we __________ a lot of testing.",
          options: [
               {
                    id: 1,
                    text: " will have done",
               },
               {
                    id: 2,
                    text: "would have done",
               },
               {
                    id: 3,
                    text: " had done",
               },
               {
                    id: 4,
                    text: " did",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 17,
          question: "The project manager __________ the new feature yesterday.",
          options: [
               {
                    id: 1,
                    text: "approved",
               },
               {
                    id: 2,
                    text: "approve",
               },
               {
                    id: 3,
                    text: "approves",
               },
               {
                    id: 4,
                    text: "is approving",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 18,
          question: " I __________ working on this project for two weeks now.",
          options: [
               {
                    id: 1,
                    text: "have been",
               },
               {
                    id: 2,
                    text: "had been",
               },
               {
                    id: 3,
                    text: "was",
               },
               {
                    id: 4,
                    text: "am",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 19,
          question:
               "They __________ the app if they had more time, but the deadline was too close.",
          options: [
               {
                    id: 1,
                    text: "could finish",
               },
               {
                    id: 2,
                    text: "can finish",
               },
               {
                    id: 3,
                    text: "could have finished",
               },
               {
                    id: 4,
                    text: "can have finished",
               },
          ],
          answer: 3,
          points: 2,
     },
     {
          id: 20,
          question: "We __________ our code review next week.",
          options: [
               {
                    id: 1,
                    text: "will have",
               },
               {
                    id: 2,
                    text: "would have",
               },
               {
                    id: 3,
                    text: "had",
               },
               {
                    id: 4,
                    text: "have",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 21,
          question:
               " If the client __________ the new feature, we'll have to change our plans.",
          options: [
               {
                    id: 1,
                    text: "doesn't like",
               },
               {
                    id: 2,
                    text: "won't like",
               },
               {
                    id: 3,
                    text: "didn't like",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 22,
          question:
               " We __________ more progress if we had had more resources. Unfortunately, that wasn’t the case.",
          options: [
               {
                    id: 1,
                    text: "would make",
               },
               {
                    id: 2,
                    text: "will make",
               },
               {
                    id: 3,
                    text: "would have made",
               },
               {
                    id: 4,
                    text: "had made",
               },
          ],
          answer: 3,
          points: 2,
     },
     {
          id: 23,
          question: "The QA analyst __________ her code before sending it to the client.",
          options: [
               {
                    id: 1,
                    text: "always double-checks",
               },
               {
                    id: 2,
                    text: "always double-checked",
               },
               {
                    id: 3,
                    text: "is always double-checking",
               },
               {
                    id: 4,
                    text: "had always double-checked",
               },
          ],
          answer: 3,
          points: 2,
     },
     {
          id: 24,
          question: "We __________ the project if we don't get more funding.",
          options: [
               {
                    id: 1,
                    text: "will have to cancel",
               },
               {
                    id: 2,
                    text: "would have to cancel",
               },
               {
                    id: 3,
                    text: "had to cancel",
               },
               {
                    id: 4,
                    text: "would cancel",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 25,
          question: "By the time we finish this project, we __________ for six months.",
          options: [
               {
                    id: 1,
                    text: "will have worked",
               },
               {
                    id: 2,
                    text: "would have been working",
               },
               {
                    id: 3,
                    text: "had been working",
               },
               {
                    id: 4,
                    text: "will be working",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 26,
          question:
               "If we __________ Agile methodology, we would be able to work more efficiently.",
          options: [
               {
                    id: 1,
                    text: "adopted",
               },
               {
                    id: 2,
                    text: "adopt",
               },
               {
                    id: 3,
                    text: " had adopted",
               },
               {
                    id: 4,
                    text: "have adopted",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 27,
          question: "The code __________ if it hadn't been for the QA analyst's feedback.",
          options: [
               {
                    id: 1,
                    text: "wouldn't have been improved",
               },
               {
                    id: 2,
                    text: "wouldn't improve",
               },
               {
                    id: 3,
                    text: "wouldn't improve",
               },
               {
                    id: 4,
                    text: "wouldn't have improved",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 28,
          question: "I __________ this technology for years.",
          options: [
               {
                    id: 1,
                    text: "have been using",
               },
               {
                    id: 2,
                    text: "had been using",
               },
               {
                    id: 3,
                    text: "was using",
               },
               {
                    id: 4,
                    text: "am using",
               },
          ],
          answer: 1,
          points: 2,
     },
     {
          id: 29,
          question: "They __________ the project ahead of schedule.",
          options: [
               {
                    id: 1,
                    text: "had completed",
               },
               {
                    id: 2,
                    text: "have completed",
               },
               {
                    id: 3,
                    text: "completed",
               },
               {
                    id: 4,
                    text: "will have completed",
               },
          ],
          answer: 2,
          points: 2,
     },
     {
          id: 30,
          question: "By the time the client arrives, we __________ the software.",
          options: [
               {
                    id: 1,
                    text: "will have tested",
               },
               {
                    id: 2,
                    text: "would have tested",
               },
               {
                    id: 3,
                    text: "had tested",
               },
               {
                    id: 4,
                    text: "will test",
               },
          ],
          answer: 1,
          points: 2,
     },
     null,
]

import { UserSquareForm } from "components/UserSquareForm"
import Target from "../../assets/target.svg"
import TargetEdit from "../../assets/target-edit.svg"
import { useForm } from "react-hook-form"
import { useContext, useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { SnackBarComponent } from "components/SnackBarComponent"
import { BubbleProfile } from "components/BubbleProfile"
import { updateUser } from "services/updateUser"
import { UserDataContext } from "context/UserDataContext"

const schema = yup.object({
     email: yup.string().email().required(),
     direccionCompleta: yup.string(),
     telefono: yup.string(),
})

const initialSnackBar = {
     message: "",
     isSnackbarOpen: false,
     severity: "success",
}

export const UserSquare = () => {
     const { userData: user, getUserInfo } = useContext(UserDataContext)
     const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar)
     const {
          register,
          handleSubmit,
          formState: { errors },
     } = useForm({
          values: {
               email: user.email,
               direccionCompleta: user.direccionCompleta,
               telefono: user.telefono,
          },
          resolver: yupResolver(schema),
     })

     const handleClose = () => {
          setSnackBarInfo({ ...initialSnackBar, isSnackbarOpen: false })
     }

     const onSubmit = async (data) => {
          setSnackBarInfo({
               ...initialSnackBar,
               isSnackbarOpen: true,
               message: "Guardando...",
               autoHideDuration: 2000,
          })
          const res = await updateUser({ ...user, ...data })
          if (!res.ok)
               return setSnackBarInfo({
                    ...initialSnackBar,
                    isSnackbarOpen: true,
                    message: "Ha ocurrido un error al actualizar",
                    severity: "error",
               })
          setSnackBarInfo({
               ...initialSnackBar,
               isSnackbarOpen: true,
               message: "Informacion actualizada correctamente",
          })
          getUserInfo()
     }

     useEffect(() => {
          if (Object.keys(errors).length > 0) {
               for (const error of Object.keys(errors)) {
                    setSnackBarInfo({
                         ...initialSnackBar,
                         message: errors[error].message,
                         severity: "error",
                         isSnackbarOpen: true,
                    })
               }
          }
     }, [errors])

     return (
          <div className='bg-primary flex w-[90vw] lg:w-[30vw] flex-col flex-wrap items-center rounded-xl pt-10 px-2.5 pb-[57px] blue-square'>
               <div className='user-card-header pb-10 lg:pl-10 w-full'>
                    <BubbleProfile {...user} />
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row w-[80%]">
                         <div className="w-[80%]">
                              <h3 className='w-full user-name-h3'>{user.nombre}</h3>
                              <p className='occupation-user '>{user.ocupacion}</p>
                              <p className='location-user '>
                                   <label className='flex gap-2'>
                                        {user.direccionCompleta.length <= 0 ? (
                                             <img src={TargetEdit} className='target-icon' />
                                        ) : (
                                             <img src={Target} className='target-icon' />
                                        )}
                                        <input
                                             className='input-card max-w-[90%]'
                                             {...register("direccionCompleta")}
                                        />
                                   </label>
                              </p>
                         </div>
                    </form>
               </div>
               <div className='w-6/12 border-t border-white'></div>
               <UserSquareForm
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
               />
               <SnackBarComponent handleClose={handleClose} {...snackBarInfo} />
          </div>
     )
}

import { Link } from "wouter";
import { PropTypes } from "prop-types";

Cards.propTypes = {
  ruta: PropTypes.string.isRequired,
};

function Cards({ ruta, nombre }) {
  return (
    <Link to={ruta}>
      <div
        className={`flex mx-auto lg:mx-0 items-center justify-center flex-col rounded-3xl border-4 border-[#7DB8F2] hover:border-orange-400 bg-transparent shadow-sm transition delay-[50ms] ease-in-out hover:scale-[1.01] hover:shadow-md w-64 h-32`}
      >
        <div className="m-auto text-[1.2em]">
          <h3 className="font-roboto text-[#7DB8F2] line-clamp-2 mb-1 font-bold text-center items-center">
            {`${nombre}`}
          </h3>
        </div>
      </div>
    </Link>
  );
}

export default Cards;

import { useEffect, useState } from "react"
import getUser from "services/getUser"
import { UserDataContext } from "./UserDataContext"
import { USER_ID } from "services/settings"

export const UserDataProvider = ({ children }) => {
     const [userData, setUserData] = useState(null)

     const getUserInfo = async () => {
          const res = await getUser()
          setUserData(JSON.parse(res))
     }

     useEffect(() => {
          if (USER_ID) {
               getUserInfo()
          }
     }, [USER_ID])

     return (
          <UserDataContext.Provider value={{ userData, getUserInfo }}>
               {children}
          </UserDataContext.Provider>
     )
}

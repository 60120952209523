import { Link } from "wouter";
import CourseIcons from "components/CourseIcons";
import CourseNav from "components/CourseNav";

export default function TopicsSidebar({
  secondBar,
  handleSecondBar,
  course,
  courseId,
  bubbleId,
  community,
  url,
  coursesPacedURL,
  normalURL,
}) {
  return (
    <div className={`relative hidden min-h-[650px] max-h-[900px] rounded-xl border-2 border-[#B75F25] bg-transparent text-[#7DB8F2] ${secondBar ? "w-1/3 pl-2" : "w-16"} flex-col transition-all lg:flex lg:ml-5`}>
      <div className={`icon-box-container absolute z-50 h-10 w-10 ${secondBar ? "" : "rotate-arrow"} hidden items-center justify-start lg:flex`} onClick={handleSecondBar}>
        <ion-icon className={`${secondBar ? "" : "rotate-arrow"}`} name="chevron-forward-sharp"></ion-icon>
      </div>
      <header className="flex max-h-[20vh] flex-col gap-5 pt-2 pl-5">
        <Link href={`${url === "courses-paced" ? coursesPacedURL : normalURL}`} className="a-icon flex items-center gap-2">
          <CourseIcons name="back" /> {secondBar && "My classes"}
        </Link>
        <h2 className={`text-lg font-medium ${secondBar ? "" : "hidden"}`}>{course.nombre}</h2>
      </header>
      <CourseNav courseId={courseId} bubbleId={bubbleId} community={community} units={course.modulos} url={url} />
    </div>
  );
}

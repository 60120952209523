import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export default function getCoursesByProfessor() {
  const url = `Cursos/GetAllByProfesor/${USER_ID}/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  return axios.get(url)
    .then((response) => response.json())
    .then((response) => {
      const data = response;
      console.log(data)
      return data;
    });
}

import { useContext } from "react"
import { Link, useLocation } from "wouter"
import HeaderIcons from "components/HeaderIcons"
import { SideBarContext } from "context/sideBarContext"
import { UserDataContext } from "context/UserDataContext"
import { API_ROOT } from "services/settings"

export default function Header() {
     const { setIsOpen } = useContext(SideBarContext)
     const { userData } = useContext(UserDataContext)
     const [location, setLocation] = useLocation()
     const user = JSON.parse(localStorage.getItem("loggedAppUser"))
     const username = user.nombre.split(" ").slice(0, 1)

     const handleLogout = () => {
          window.localStorage.removeItem("loggedAppUser")
          setLocation("/")
          window.location.reload()
     }

     return (
          <>
               <header className='flex h-[48px] w-full items-center justify-between bg-[#1B212B] px-5 py-2 shadow-sm md:px-10'>
                    <div className='flex h-full items-center'>
                         <button className='md:hidden' onClick={() => setIsOpen(true)}>
                              <HeaderIcons name='hamburguer' />
                         </button>
                         <Link href='/' className=' text-white text-2xl font-light h-8 md:hidden'>
                              i<span className='text-accent'>.</span>speak
                         </Link>
                    </div>

                    <div className='flex w-full  justify-end'>
                         {/* <HeaderSearchBar /> */}
                         <div className='flex gap-5'>
                              <Link href='/profile' className='flex items-center'>
                                   {userData === null || userData?.imagen === "" ? (
                                        <HeaderIcons name='profile' className="fill-[#7DB8F2]" />
                                   ) : (
                                        <div className='message-profile mr-2'>
                                             <img
                                                  src={
                                                       API_ROOT + "/ProfilePhotos/" + userData.imagen
                                                  }
                                                  alt={userData.nombre}
                                             />
                                        </div>
                                   )}
                                   <span className='text-[#7DB8F2] hidden font-medium md:inline'>
                                        {username}
                                   </span>
                              </Link>
                              <button onClick={handleLogout} className='text-[#7DB8F2]'>
                                   Logout
                              </button>
                         </div>
                    </div>
               </header>
          </>
     )
}

import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "wouter";
import "./styles.css";
import SendIcon from "@mui/icons-material/Send";
import getAllUsers from "services/getAllUsers";
import CallLogo from "../../assets/boton-llamada.png";
import getCoursesByProfessor from "services/getCoursesByProfessorId";
import SideBarTopic from "components/SideBarTopic";
import Mensaje from "../../components/TopicMessage/index";
import firebaseApp, { uploadFile } from "../../firebase/credentials";
import {
     getFirestore,
     doc,
     setDoc,
     collection,
     onSnapshot,
} from "firebase/firestore";
import { SizeContext } from "context/SizeContext";
import { Grid, Typography } from "@mui/material";
import { UserDataContext } from "context/UserDataContext";

const firestore = getFirestore(firebaseApp);

const StudentsPage = ({ materialId }) => {
     const [file, setFile] = useState("");
     const [students, setStudents] = useState([]);
     const [teacher, setTeacher] = useState([]);
     const [inputMensaje, setinputMensaje] = useState([]);
     const [listaMensaje, setListaMensaje] = useState([]);
     const { secondBar, showBar, size } = useContext(SizeContext);
     const { userData } = useContext(UserDataContext)
     const chatDiv = useRef();

     const user = JSON.parse(localStorage.getItem("loggedAppUser"));

     //obtenemos el id de la url
     const id = materialId;

     const getListaMensajes = async () => {
          onSnapshot(
               collection(firestore, "topics", `${materialId}`, "messages"),
               (querySnapshot) => {
                    if (querySnapshot.empty) {
                         setListaMensaje([]);
                         return;
                    }
                    const mensajesArr = [];
                    querySnapshot.forEach((doc) => {
                         mensajesArr.push(doc.data());
                    });
                    setListaMensaje([...mensajesArr]);
               }
          );
     };

     const enviarMensaje = async (e) => {
          e.preventDefault();
          let image;
          if (inputMensaje === "" && file === "") {
               return;
          }
          if (file !== "") {
               const res = await uploadFile(file);
               image = res;
          }
          setDoc(
               doc(
                    firestore,
                    "topics",
                    `${materialId}`,
                    `messages`,
                    `${new Date().getTime()}`
               ),
               {
                    foto: userData.imagen,
                    usuario: user.nombre,
                    mensaje: inputMensaje,
                    image: image? image: "",
                    id: new Date().getTime(),
               }
          );
          setinputMensaje("");
     };

     const getStudents = async () => {
          await getAllUsers().then((data) => {
          });
     };

     let filtro;

     const getCantidadAlumnos = async (course_id) => {
          await getCoursesByProfessor().then((data) => {
               filtro = data.filter((alumno) => alumno.id === course_id);
          });

          setStudents(filtro);
     };

     useEffect(() => {
          getListaMensajes();
     }, []);

     useEffect(() => {
          getListaMensajes();
     }, [materialId]);

     useEffect(() => {
          chatDiv.current.scrollTop =
               chatDiv.current.scrollHeight - chatDiv.current.clientHeight;
     }, [listaMensaje]);
     return (
          <>
               <div
                    className={`chat-box relative lg:min-h-[650px] max-h-[650px] overflow-hidden z-20 rounded-[12px] bg-transparent lg:w-[30%] ${
                         secondBar || (showBar && size < 1550)
                              ? "hidden"
                              : "app"
                    }`}
               >
                    <div
                         className="min-h-[400px] w-full overflow-y-scroll chatHiddenScroll pb-[87px] bg-[#333333]"
                         ref={chatDiv}
                    >
                         <div
                              className={`chatHeader absolute top-0 h-[80px] w-full border-b border-b-zinc-400 bg-[#333333] border-t-2 border-l-2 border-r-2 rounded-t-lg border-[#B75F25] ${
                                   (secondBar || showBar) &&
                                   size < 1550 &&
                                   size > 1024
                                        ? "hidden"
                                        : ""
                              }`}
                         >
                              <div className="chatHeader__left">
                                   <h3 className="text-[#7DB8F2]">
                                        Comunidad
                                   </h3>
                              </div>

                              <div className="chatHeader__right text-[#7DB8F2]">
                                   <Link
                                        href={`/JitsiMeet/${id}`}
                                        className="a-right"
                                   >
                                        {" "}
                                        <span className="chatHeader__video text-[#7DB8F2]">
                                             Join a room.
                                             <img
                                                  src={CallLogo}
                                                  alt="call logo"
                                             />
                                        </span>{" "}
                                   </Link>
                              </div>
                         </div>

                         <div
                              className={`chat__messages pt-[80px] overflow-hidden ${
                                   (secondBar || showBar) &&
                                   size < 1550 &&
                                   size > 1024
                                        ? "hidden"
                                        : ""
                              }`}
                         >
                              {listaMensaje &&
                                   listaMensaje.map((mensaje) => {
                                        return (
                                             <Mensaje
                                                  key={mensaje.id}
                                                  mensajeFirebase={mensaje}
                                                  user_name={user.nombre}
                                                  profile={mensaje.foto}
                                             />
                                        );
                                   })}
                         </div>
                         {listaMensaje.length <= 0 && (
                              <Grid
                                   container
                                   justifyContent={"center"}
                                   sx={{
                                        position: "absolute",
                                        bottom: "100px",
                                   }}
                              >
                                   <Typography
                                        variant="h6"
                                        fontSize={14}
                                        color={"#7DB8F2"}
                                   >
                                        be the first to share your insights
                                   </Typography>
                              </Grid>
                         )}
                    </div>
                    <div className="chat-input-container absolute bottom-0 bg-[#333333] border-b-2 border-l-2 border-r-2 rounded-b-lg border-[#B75F25]">
                         <div className="chat__input !m-0 w-[80%]">
                              <form onSubmit={enviarMensaje}>
                                   <input
                                        type="text"
                                        value={inputMensaje}
                                        onChange={(e) =>
                                             setinputMensaje(e.target.value)
                                        }
                                        placeholder="Type to reply..."
                                   />
                                   <button
                                        className="chat__inputButton"
                                        type="submit"
                                   >
                                        Enviar mensaje
                                   </button>
                              </form>

                              <div className="chat__inputIcons">
                                   <SendIcon
                                        onClick={enviarMensaje}
                                        className="sideicon__top"
                                        fontSize="large"
                                   />
                              </div>
                         </div>
                    </div>
                    <SideBarTopic students={students} profesor={teacher} />
               </div>
          </>
     );
};

export default StudentsPage;

import {
  Box,
  Button,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { SnackBarActions } from "components/SnackBarActions"
import { useForm } from "hooks/useForm";
import { useState } from "react";
import { postFreeRegister } from "services/postFreeRegister";
import { Notification } from "components/registration-notice";

const initialForm = {
  nombre: "",
  apellido: "",
  email: "",  
};

const initialSnackBar = {
  isSnackBarOpen: false,
  severity: "success",
  message: "Alumno registrado correctamente",
};

const errorSnackbar = {
  isSnackBarOpen: true,
  severity: "error",
  message: "Ha ocurrido un error",
};

export const UserRegisterPage = () => {
  const {
    nombre,
    apellido,
    email,
    onInputChange,
    formState,
    onResetForm,
  } = useForm(initialForm);
  const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);
  const [renderNotice, setRenderNotice] = useState(false);

  const closeSnackbar = () => {
    setSnackBarInfo({
         ...snackBarInfo,
         isSnackBarOpen: false,
    })
}


  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      nombre === "" ||
      apellido === "" ||
      email === ""
    ) {
      return setSnackBarInfo({
        ...errorSnackbar,
        message: "Por favor completa los datos",
      });
    }
    const res = await postFreeRegister(formState)
    if (!res.ok) {
      return setSnackBarInfo({ ...errorSnackbar, message: await res.json() });
    }
    onResetForm();
    setRenderNotice(true);
  };
  return (
    <>
      {renderNotice ? (
        <Notification email={email} />
      ) : (
        <Grid
          container
          minHeight={"100vh"}
          justifyContent={"center"}
          alignItems="center"
          py={10}
        >
          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{
              width: { xs: "95%", sm: "90%" },
              maxWidth: "700px",
              backgroundColor: "#fff",
              borderRadius: "6px",
              p: 4,
              boxShadow: "0 0 15px rgba(0,0,0, 0.03)",
            }}
          >
            <Typography variant="h4" fontWeight={600} color={"#000"} mb={3}>
              Registrarse
            </Typography>
            <Grid item xs={12} my={1}>
              <InputBase
                label="Nombre"
                placeholder="Nombre"
                variant="outlined"
                name="nombre"
                value={nombre}
                onChange={onInputChange}
                fullWidth
                sx={inputSx}
              />
            </Grid>
            <Grid item xs={12} my={1}>
              <InputBase
                label="Apellido"
                variant="outlined"
                placeholder="Apellido"
                name="apellido"
                value={apellido}
                onChange={onInputChange}
                fullWidth
                sx={inputSx}
              />
            </Grid>
            <Grid item xs={12} my={1}>
              <InputBase
                label="Correo"
                type="email"
                variant="outlined"
                placeholder="Correo Electronico"
                name="email"
                value={email}
                onChange={onInputChange}
                fullWidth
                sx={inputSx}
              />
            </Grid>
            <Grid item my={2}>
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  backgroundColor: "#2de678",
                  border: "none",
                  color: "#fff",
                  fontWeight: 700,
                  ":hover": {
                    backgroundColor: "#2de678",
                    border: "none",
                  },
                  borderRadius: "8px",
                }}
              >
                Registrar
              </Button>
            </Grid>
          </Box>
          <SnackBarActions handleSnackbar={closeSnackbar} {...snackBarInfo} />
        </Grid>
       
      )}
    </>
  );
};

const inputSx = {
  backgroundColor: "#efefef",
  py: 0.5,
  pl: 2,
  borderRadius: "8px",
  ":focus": { outline: "1px solid #ccc" },
  my: 1,
};

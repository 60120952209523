import { Alert, Snackbar } from "@mui/material";

export const SnackbarComponent = ({
  isSnackBarOpen,
  closeSnackBar,
  message = "",
  severity = "success",
}) => {
  return (
    <Snackbar
      open={isSnackBarOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={3000}
      onClose={closeSnackBar}
    >
      <Alert onClose={closeSnackBar} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

import React from "react";

export const SkeletonCard = () => {
  return (
    <div className="flex w-full flex-col rounded-xl border-4 border-gray-300 bg-transparent shadow-sm animate-pulse md:w-64">
      <div className="py-5 px-2">

        <div className="h-5 w-16 mx-auto bg-gray-300 rounded-md mb-4"></div>

        <div className="h-6 w-3/4 bg-gray-300 rounded mb-6 mx-auto"></div>

        <div className="bg-gray-200 h-5 w-full rounded-xl">
          <div className="bg-gray-300 h-full w-1/2 rounded-xl"></div>
        </div>
      </div>
    </div>
  );
};

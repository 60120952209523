import axios from "axios"
import { useState } from "react"
import { API_KEY, API_URL, USER_ID } from "../services/settings"

export const useUploadPhoto = () => {
     const [percentageUploaded, setPercentageUploaded] = useState(0)

     const uploadPhoto = async (photo, id = "") => {
          let formData = new FormData()
          formData.append('file', photo)
          try {
               const res = await axios.post(
                    `${API_URL}/Usuario/UpdateProfilePhoto/${API_KEY}/${
                         id === "" ? USER_ID : id
                    }`,
                    formData,
                    {
                         headers: {
                              "Content-Type": "multipart/form-data",
                         },
                         onUploadProgress: (progressEvent) => {
                              const { loaded, total } = progressEvent
                              const percentage = Math.floor((loaded * 100) / total)
                              setPercentageUploaded(percentage)
                         },
                    }
               )
               return {
                    ok: true,
                    photo: res,
               }
          } catch (error) {
               return {
                    ok: false,
                    errorMessage: error.response.message | "Ha ocurrido un error",
               }
          }
     }

     return { uploadPhoto, percentageUploaded }
}

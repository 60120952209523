import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";

import { ServerDataContext } from "./ServerDataContext";
import { generatePDF, sendReportPDF } from "./generateReport";
import { CoursesContext } from "context/coursesContext";

const GenerateReports = ({ autocompleteValue, courseSelectValue }) => {
  const { serverData } = useContext(ServerDataContext);
  const { courses } = useContext(CoursesContext);

  const [tableInfo, setTableInfo] = useState([]);

  useEffect(() => {
    if (
      autocompleteValue &&
      autocompleteValue.length > 0 &&
      courseSelectValue
    ) {
      autocompleteValue.forEach((user) => {
        const [firstArray] = serverData.filter(
          (student) => student.studentId === user.id
        );

        if (firstArray) {
          const [cursoArray] = firstArray.cursos.filter((curso) => {
            if (curso.cursoid === courseSelectValue.id) {
              return curso;
            } else {
              return false;
            }
          });

          setTableInfo((c) => {
            if (cursoArray !== undefined) {
              const index = c.findIndex((item) => item.user.id === user.id);
              if (index === -1) {
                return [...c, { user, table: cursoArray }];
              } else {
                return c.map((item) => {
                  if (item.user.id === user.id) {
                    return { user, table: cursoArray };
                  } else {
                    return item;
                  }
                });
              }
            } else {
              console.log("undefined");
              return c.filter((item) => item.user.id !== user.id);
            }
          });
        }
      });
    }
  }, [serverData, autocompleteValue, courseSelectValue]);

  return (
    <div>
      {tableInfo.length > 0 &&
        tableInfo.map((obj) => {
          return (
            <div>
              <Button
                sx={{ margin: ".5rem" }}
                variant="contained"
                disableElevation
                key={"report-" + obj.user.id}
                onClick={() => {
                  generatePDF(
                    obj.user,
                    obj.table,
                    courses.find((course) => {
                      return course.id === obj.table.cursoid;
                    })
                  );
                }}
              >
                Generate report for {obj.user.nombre}
              </Button>

              <Button
                sx={{ margin: ".5rem" }}
                variant="contained"
                disableElevation
                key={"send-email-" + obj.user.id}
                onClick={() => {
                  sendReportPDF(
                    obj.user,
                    obj.table,
                    courses.find((course) => {
                      return course.id === obj.table.cursoid;
                    })
                  );
                }}
              >
                Send Report to {obj.user.nombre}
              </Button>
            </div>

            // boton para enviar por email
          );
        })}
    </div>
  );
};

export default GenerateReports;

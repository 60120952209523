import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { Link, useLocation } from "wouter";
import { CoursesContext } from "context/coursesContext";
import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../firebase/credentials";
import { Box } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { UpgradePlanPage } from "components/UpgradePlanPage";

const AccordionItem = React.memo(({ course, module, url }) => {
  const [location, setLocation] = useLocation();
  const onClickCard = useCallback(() => {
    setLocation(`/${url}/bubble/${course}/${module.id}`);
  }, [setLocation, url, course, module.id]);
  return (
    <li
      onClick={onClickCard}
      className={`accordion-item show-peace-page text-primary cursor-pointer rounded-xl border-4 ${module.allCompleted ? "border-[#CF7831]" : "border-[#7DB8F2]"} bg-transparent p-5 shadow-sm transition-all duration-300 ease-in-out`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <h2 className="accordion-title font-Barlow text-[#7DB8F2] mr-5 text-left lg:text-center text-lg font-semibold">
            {module.moduleName}
          </h2>
        </div>
      </div>
    </li>
  );
});

const Module = React.memo(({ course, modules = [], url }) => {
  return (
    <>
      {modules.map((module) => (
        <AccordionItem
          key={module.id}
          module={module}
          course={course}
          url={url}
        />
      ))}
    </>
  );
});

export default function CoursePage({ params, url, requiredPlanFeature }) {
  const id = useMemo(() => parseInt(params.courseId), [params.courseId]);
  const [modules, setModules] = useState([]);
  const { courses, userPlan, profesor, isLoading } = useContext(CoursesContext);
  const course = useMemo(() => {
    const courseSelected = courses.find((course) => course.id === id);
    return courseSelected
    ? {
      ...courseSelected,
      modulos: courseSelected.modulos.filter((mod) =>
        mod.nombre.includes("Content")
    ),
  }
  : null;
}, [courses, id]);

  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      const getData = async () => {
        const docRef = collection(firestore, "modulos", `${id}`, "modulos");
        const data = await getDocs(query(docRef));
        if (isMounted) {
          const modulos = [];
          data.forEach((item) => {
            modulos.push(item.data());
          });
          setModules(modulos);
        }
      };

      getData();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const groupedModules = useMemo(() => {
    if (!modules.length || !course) return [];

    return modules.map((module) => {
      const filteredModulos = course.modulos.filter((modulo) =>
        module.bubbleIds.includes(modulo.id)
      );

      const allCompleted = filteredModulos.length > 0 && filteredModulos.every((modulo) =>
        modulo.clases?.every((clase) => clase.completada)
      );


      return {
        ...module,
        modulos: filteredModulos,
        allCompleted,
      };
    });
  }, [modules, course]);

  const [hasRequiredPlan, setHasRequiredPlan] = useState(false);

  useEffect(() => {
    if (profesor) {
      setHasRequiredPlan(true);
    } else {
      setHasRequiredPlan(userPlan?.features?.some((item) => 
        item.featureName === requiredPlanFeature && item.enabled
      ));
    }
  }, [userPlan, profesor]);

  return hasRequiredPlan ? (
    <section className="show-peace-page p-5 md:p-10 lg:px-14">
      {course && (
        <>
          <Box display="flex" alignItems={"center"} gap={2}>
            <Link to={`/${url}/`}>
              <ArrowBackIosNewRoundedIcon
                fontSize="10px"
                sx={{ cursor: "pointer", color: "#7DB8F2" }}
              />
            </Link>
            <h1 className="font-Barlow text-[#7DB8F2] mr-5 text-2xl font-semibold">
              {course.nombre || course.title}
            </h1>
          </Box>
          <ol className="accordion flex flex-col gap-3 p-5">
            <Module url={url} course={course.id} modules={groupedModules} />
          </ol>
        </>
      )}
    </section>
  ) : (
    <UpgradePlanPage />
  );
}

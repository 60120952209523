function Reply({ reply }) {
    return (
      <div className="ml-5 mt-5 rounded-xl border border-gray-300 p-5 shadow-lg">
        <header className="border-accent flex justify-between border-b-2">
          <div>
            <img url={reply.imagen} alt="" />
            <span className="text-primary font-semibold">{reply.alumno}</span>
          </div>
          <span>{reply.fecha}</span>
        </header>
        <p className="p-2">{reply.comentario}</p>
      </div>
    );
  }
  
  export default Reply;
  
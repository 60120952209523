import { useEffect, useRef, useState } from "react"
import EditPhoto from "../../assets/edit.svg"
import BubbleCamera from "../../assets/bubble-camera.svg"
import { SnackBarComponent } from "components/SnackBarComponent"
import { useLocation } from "wouter"
import { useUploadPhoto } from "hooks/useUploadPhoto"
import { API_ROOT } from "services/settings"

const initialSnackBar = {
     message: "",
     isSnackbarOpen: false,
     severity: "success",
}

export const BubbleProfileRegister = ({ imagen = "", nombre = "nadie", id, redirect = false, onUploadingImageChange }) => {
     const inputFile = useRef(null)
     const form = useRef(null)
     const { uploadPhoto, percentageUploaded } = useUploadPhoto()
     const [recentImage, setRecentImage] = useState("")
     const [isLoading, setIsLoading] = useState(false)
     const [file, setFile] = useState(null)
     const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar)
     const [location, setLocation] = useLocation()

     const onSetPhoto = (e) => {
          const url = URL.createObjectURL(e.target.files[0])
          setFile(e.target.files[0])
          setRecentImage(url)
          onUploadingImageChange()
     }

     const onUploadPhoto = async (id) => {
          setIsLoading(true)
          setSnackBarInfo({ ...snackBarInfo, message: "Subiendo imagen", isSnackbarOpen: true })
          const res = await uploadPhoto(file, id)
          form.current.reset()
          setIsLoading(false)
          if (!res.ok)
               return setSnackBarInfo({
                    ...initialSnackBar,
                    severity: "error",
                    message: "Ha ocurrido un error en la subida",
                    isSnackbarOpen: true,
               })
          setSnackBarInfo({
               ...snackBarInfo,
               message: "Imagen subida correctamente",
               isSnackbarOpen: true,
          })
          alert(`Registro exitoso`)
          if (redirect) {
               setLocation(`/`)
          }
     }

     const handleClose = () => {
          setSnackBarInfo({ ...initialSnackBar, isSnackbarOpen: false })
     }

     const imageURL = API_ROOT + "/ProfilePhotos/" + imagen

     useEffect(() => {
          if (id !== "") {
               onUploadPhoto(id)
          }
     }, [id])

     useEffect(() => {
          if (percentageUploaded === 0) return
          setSnackBarInfo({
               ...snackBarInfo,
               message: `subiendo imagen ${percentageUploaded}%`,
               isSnackbarOpen: true,
          })
     }, [percentageUploaded])

     const onEditClick = () => {
          if (isLoading)
               return setSnackBarInfo({
                    ...initialSnackBar,
                    severity: "error",
                    message: "Actualmente se esta subiendo una imagen",
                    isSnackbarOpen: true,
               })
          inputFile.current.click()
     }
     return (
          <>
               <div className='image-bubble-container relative'>
                    {imagen === "" && recentImage === "" ? (
                         <img src={BubbleCamera} className='bubble-img-card' alt='no imagen' />
                    ) : (
                         <div className='user-img-container '>
                              <img
                                   src={recentImage !== "" ? recentImage : imageURL}
                                   alt={nombre}
                                   className='user-img'
                              />
                         </div>
                    )}
                    <div
                         className='edit-photo-container absolute right-0 top-0'
                         onClick={onEditClick}
                    >
                         <form ref={form}>
                              <input
                                   type='file'
                                   ref={inputFile}
                                   className='hidden'
                                   onChange={onSetPhoto}
                              />
                         </form>
                         <img src={EditPhoto} alt='editar-foto' />
                    </div>
               </div>
               <SnackBarComponent handleClose={handleClose} {...snackBarInfo} />
          </>
     )
}

import axios from "axios";
import { USER_TOKEN, API_URL } from "./settings";

const axiosInstance = () => {

    const instance = axios.create({
        baseURL: API_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: USER_TOKEN ? `Bearer ${USER_TOKEN}` : undefined,
        },
        withCredentials: true,
    });

    const refreshToken = async () => {
        try {
            const requestBody = { token: USER_TOKEN };
            const response = await axios.post(`${API_URL}/User/RefreshToken`, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: false,
            });
            const newToken = response.data.token;
            updateTokenInLocalStorage(newToken);
            return newToken;
        } catch (error) {
            console.error("Failed to refresh token:", error);
            localStorage.removeItem("loggedAppUser");
            window.location.href = "/";
            throw error;
        }
    };

    const updateTokenInLocalStorage = (newToken) => {
        const storedData = localStorage.getItem("loggedAppUser");
        if (storedData) {
            const userData = JSON.parse(storedData);
            userData.token = newToken;
            localStorage.setItem("loggedAppUser", JSON.stringify(userData));
            return userData;
        } else {
            console.error("No stored token found");
            return null;
        }
    };


    const deleteSessionFromLocalStorage = () => {
        localStorage.removeItem("loggedAppUser");
    };

    const navigateToHome = () => {
        window.location.href = "/";
    }


    instance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            const TokenExpiredAndNoPreviousRetry = error.response
                && error.response.status === 401
                && !originalRequest._retry &&
                error.response.data?.Code === "TokenExpired";

            if (TokenExpiredAndNoPreviousRetry) {
                originalRequest._retry = true;
                try {
                    const newToken = await refreshToken();
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    console.log("Retrying with new token:", newToken);
                    return instance(originalRequest);
                } catch (refreshError) {
                    console.error("Failed to retry with new token:", refreshError);
                    deleteSessionFromLocalStorage();
                    navigateToHome();
                }
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export default axiosInstance;

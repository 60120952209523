import { useState, useEffect, useContext } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import { SnackbarComponent } from "./SnackbarComponent";
import { updateBubble, updateRow } from "./calls";
import { ServerDataContext } from "./ServerDataContext";
import { USER_ID } from "services/settings";

const initialSnackBar = {
  isSnackBarOpen: false,
  severity: "success",
  message: "The data has been successfully updated",
};

const errorSnackbar = {
  isSnackBarOpen: true,
  severity: "error",
  message: "An error has occurred",
};

export const StudentClassModal = ({
  isModalOpen,
  modalData,
  userId,
  bubbleId,
  cursoId,
  closeModal,
  noBubble,
}) => {
  const { setUpdater } = useContext(ServerDataContext);

  const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);
  const [isLoading, setIsLoading] = useState(false);

  const [selectValue, setSelectValue] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");
  const [initialState, setInitialState] = useState(null);

  useEffect(() => {
    setSelectValue(modalData.indicator);
    setTextFieldValue(modalData.observations);
    setInitialState({
      indicator: modalData.indicator,
      observations: modalData.observations,
    });
    setIsLoading(false);
  }, [modalData]);

  const closeSnackBar = () => {
    setSnackBarInfo({
      ...snackBarInfo,
      isSnackBarOpen: false,
    });
  };

  const handleSubmit = async () => {
    if (selectValue === "") {
      setSnackBarInfo({
        ...errorSnackbar,
        message: "Please first set a value for the indicator",
      });
      return;
    }

    setIsLoading(true);

    if (noBubble) {
      const obj = {
        teacherId: USER_ID,
        studentId: userId,
        cursoId,
        bubbleId: bubbleId,
        observations: "",
        score: "",
      };

      const { ok, data: bubbleData } = await updateBubble(obj);

      if (ok) {
        setSnackBarInfo({ ...initialSnackBar, isSnackBarOpen: true });

        console.log(`Se ha creado la burbuja: ${bubbleData.id}`);

        const obj = {
          teacherId: USER_ID,
          studentId: userId,
          cursoId,
          bubbleId,
          lessonId: modalData.id,
          indicator: selectValue,
          observations: textFieldValue,
        };

        const { ok, data, error } = await updateRow(
          obj,
          modalData.lessonId ? modalData.lessonId : ""
        );

        if (ok) {
          setSnackBarInfo({ ...initialSnackBar, isSnackBarOpen: true });

          console.log(
            !modalData.lessonId ? `Se ha creado la lección: ${data.id}` : data
          );

          setUpdater(Date.now());

          setTimeout(() => {
            closeModal();
          }, 600);
        } else {
          setIsLoading(false);

          setSnackBarInfo({
            ...errorSnackbar,
            message:
              error === "409"
                ? "Error 409 - Save some data for the bubble first"
                : "Ha ocurrido un error",
          });
        }
      } else {
        setIsLoading(false);

        setSnackBarInfo({ ...errorSnackbar });
      }
    } else {
      const obj = {
        teacherId: USER_ID,
        studentId: userId,
        cursoId,
        bubbleId,
        lessonId: modalData.id,
        indicator: selectValue,
        observations: textFieldValue,
      };

      const { ok, data, error } = await updateRow(
        obj,
        modalData.lessonId ? modalData.lessonId : ""
      );

      if (ok) {
        setSnackBarInfo({ ...initialSnackBar, isSnackBarOpen: true });

        console.log(
          !modalData.lessonId ? `Se ha creado la lección: ${data.id}` : data
        );

        setUpdater(Date.now());

        setTimeout(() => {
          closeModal();
        }, 600);
      } else {
        setIsLoading(false);

        setSnackBarInfo({
          ...errorSnackbar,
          message:
            error === "409"
              ? "Error 409 - Save some data for the bubble first"
              : "Ha ocurrido un error",
        });
      }
    }
  };

  const selectOptions = [
    "(A) Achieved",
    "(BA) Barely Achieved",
    "(UN) Unachieved",
  ];

  return (
    <>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          p={5}
          sx={{
            width: `32rem`,
            borderRadius: "0.5rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            overflowY: "auto",
            maxHeight: "500px",
          }}
        >
          <h2 className="text-primary text-xl mb-4 font-semibold">Edit row</h2>

          <Grid container>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="simple-select">Indicator</InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  labelId="simple-select"
                  label="Indicator"
                  value={selectValue}
                  onChange={(event) => {
                    setSelectValue(event.target.value);
                  }}
                >
                  {selectOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <TextField
                fullWidth
                label="Observations"
                placeholder="Observations"
                multiline
                value={textFieldValue}
                onChange={(event) => {
                  setTextFieldValue(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ my: 1 }}>
              <Button
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                disabled={
                  isLoading ||
                  (initialState &&
                    initialState.indicator === selectValue &&
                    initialState.observations === textFieldValue) ||
                  (selectValue === "" && textFieldValue === "")
                }
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <SnackbarComponent closeSnackBar={closeSnackBar} {...snackBarInfo} />
    </>
  );
};

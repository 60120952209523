import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

const saveData = (data) => {
     try {
          const { id, email, nombre, rol, token } = data;
          const parsedData = { id, email, nombre, rol, token };
          window.localStorage.setItem("loggedAppUser", JSON.stringify(parsedData));
          return true;
     } catch (error) {
          console.error("Error saving data to local storage: " + error.message);
     }
     return false;;
};

const postLogin = async ({ email, password }) => {
     const url = `User/Login/${API_KEY}`;
     const axios = axiosInstance();
     const credentials = {
          email: email,
          password: password,
     };

     try {
          const loginRes = await axios.post(url, credentials);
          if (loginRes.status === 200) {
               saveData(loginRes.data);
               return true
          }
     } catch (error) {
          console.error("Login request failed");
     }
     return false;
};

export default postLogin;

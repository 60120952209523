export const SkeletonContent = () => {
    return (
      <div className="w-full px-10 py-5 animate-pulse">

        <div className="h-6 w-24 bg-gray-300 rounded-md mb-3"></div>

        <div className="space-y-2">
          <div className="h-4 w-full bg-gray-300 rounded-md"></div>
          <div className="h-4 w-3/4 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-5/6 bg-gray-300 rounded-md"></div>
        </div>

        <div className="h-6 w-20 bg-gray-300 rounded-md mt-5 mb-3"></div>

        <div className="space-y-2">
          <div className="h-4 w-3/4 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-5/6 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-2/3 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    );
  };
  
export const IconDone = () => {
  return (
    <div className='mx-auto'>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 256 256"
      style={{ fill: '#000000' }}
    >
      <g transform="scale(2,2)">
        <path
          d="M64,16c-26.50967,0 -48,21.49033 -48,48c0,26.50967 21.49033,48 48,48c26.50967,0 48,-21.49033 48,-48c0,-26.50967 -21.49033,-48 -48,-48z"
          fill="#ffffff"
        />
        <path
          d="M64,25c-21.53911,0 -39,17.46089 -39,39c0,21.53911 17.46089,39 39,39c21.53911,0 39,-17.46089 39,-39c0,-21.53911 -17.46089,-39 -39,-39z"
          fill="#8fd6be"
        />
        <path
          d="M64,16c-26.50967,0 -48,21.49033 -48,48c0,26.50967 21.49033,48 48,48c26.50967,0 48,-21.49033 48,-48c0,-26.50967 -21.49033,-48 -48,-48z"
          fill="none"
          stroke="#d78d36"
          strokeWidth="6"
        />
        <path
          d="M42,69l13.55,12l30.45,-35"
          fill="none"
          stroke="#ffffff"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
    </svg>
  </div>
  );
};

import { UserSquare } from "components/UserSquare"
import { UserDataContext } from "context/UserDataContext"
import { useContext } from "react"

export default function ProfilePage() {
     const { userData } = useContext(UserDataContext)
     return (
          <section className='flex flex-col p-5 pt-8 pb-14 md:p-10 lg:px-14'>
               <h2 className='text-[#7DB8F2] mb-5 text-2xl font-semibold'>My Profile</h2>
               {!!userData && <UserSquare />}
          </section>
     )
}

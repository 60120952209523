export const useReportFiltering = (customizedInfo, contentBubble) => {
  const { materialEstudios: materials } = contentBubble;

  if (materials === undefined) {
    return { columns: [], rows: [] };
  }

  const defaultProps = {
    indicator: "",
    observations: "",
    noData: true,
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "videolesson",
      headerName: "Topics",
    },
    {
      field: "indicator",
      headerName: "Indicator",
    },
    {
      field: "observations",
      headerName: "Observations",
    },
  ];

  // console.log(customizedInfo);

  const rows = materials.map(({ id, nombre: videolesson }) => {
    let found = false;

    if (customizedInfo) {
      found = customizedInfo.lessons.find((lesson) => lesson.lessonId === id);
    }

    if (found) {
      return {
        id,
        videolesson,
        indicator: found.indicator
          .replace("(", "")
          .replace(")", "")
          .slice(0, 2),
        observations: found.observations,
        noData: false,
        lessonId: found.id,
      };
    } else {
      return {
        id,
        videolesson,
        ...defaultProps,
      };
    }
  });

  return {
    columns,
    rows,
  };
};

export const useBubbleObservations = (customizedInfo) => {
  const finalColumns = [
    { field: "observations", headerName: "Bubble Observations" },
    {
      field: "score",
      headerName: "Score",
    },
  ];

  const finalRows = {
    observations: customizedInfo.observations || "",
    score: customizedInfo.score || "-",
  };

  console.log({
    finalColumns,
    finalRows,
  });

  return {
    finalColumns,
    finalRows,
  };
};

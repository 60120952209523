import React from "react";
import TopComponent from "./TopComponent";

const Results = ({ testName, score, closeTest }) => {
  return (
    <TopComponent>
      <div className="screen instructions">
        <div className="top-container">
          <div className="modal">
            <h2>MULTIPLE CHOICE TEST - JUNIOR LEVEL</h2>

            <strong>{testName}</strong>
            <p>
              Your score for this test was: <strong>{score + "/100"}</strong>{" "}
              points
            </p>

            <button
              className="button blue"
              onClick={() => {
                closeTest();
              }}
            >
              Close Test
            </button>
          </div>
        </div>
      </div>
    </TopComponent>
  );
};

export default Results;

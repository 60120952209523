import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export default function getCourseById({ id }) {
  const url = `Cursos/GetAllByAlumno/${USER_ID}/${API_KEY}/${USER_ID}`;
  const axios = axiosInstance();
  return axios.get(url)
    .then((response) => {
      const course = response.data.filter((course) => course.id === parseInt(id));
      return course[0];
    });
}

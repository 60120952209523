import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

export const getAllCoursesAsync = async () => {
     const url = `Cursos/GetAll/${API_KEY}/`;
     const axios = axiosInstance();
     return axios.get(url)
          .then(response => {               
               return response.data;
          });
}

import React, { useContext, useEffect, useState } from "react";
import { CoursesContext } from "context/coursesContext";

import CourseListSection from "components/CoursesSection";
import CoursesHeader from "components/CoursesHeader";
import { filterByCategory, filterBySearch, filterByStatus } from "utils";
import { Typography } from "@mui/material";
import { UpgradePlanPage } from "components/UpgradePlanPage";
import { SkeletonCard } from "components/CourseCard/skeleton";

const initialSelect = "";

export default function CoursesPage({
  url,
  requiredPlanFeature,
  defaultSearch,
}) {
  const { courses, userPlan, isLoading } = useContext(CoursesContext); // Ahora obtienes isLoading del contexto
  const [course, setCourse] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(initialSelect);
  const [status, setStatus] = useState(initialSelect);
  const [hasRequiredPlan, setHasRequiredPlan] = useState(false);

  const handleCategory = (e) => setCategory(e.target.value);
  const handleStatus = (e) => setStatus(e.target.value);
  const handleSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    setHasRequiredPlan(
      userPlan?.features?.find(
        (item) => item.featureName === requiredPlanFeature && item.enabled
      )
    );
  }, [userPlan]);

  useEffect(() => {
    let filteredCourses = courses;
    if (search) {
      filteredCourses = filterBySearch(courses, search);
    } else {
      if (category) {
        filteredCourses = filterByCategory(filteredCourses, category);
      }
      if (status) {
        filteredCourses = filterByStatus(filteredCourses, status);
      }
    }
    setCourse(filteredCourses);
  }, [category, status, courses, search]);

  useEffect(() => {
    if (search !== "") {
      setCategory(initialSelect);
      setStatus(initialSelect);
      setCourse(filterBySearch(courses, search));
    } else {
      setCourse(courses);
    }
  }, [search]);

  if (hasRequiredPlan === undefined) {
    return null;
  }

  return hasRequiredPlan ? (
    <>
      <section className="flex w-full flex-col gap-5 p-5 md:p-10 lg:px-14">
        <CoursesHeader
          coursesNum={courses.length}
          handleCategory={handleCategory}
          handleStatus={handleStatus}
          handleSearch={handleSearch}
          search={search}
        />
        {isLoading ? (
          <SkeletonCard />
        ) : course.length > 0 ? (
          <div>
            <CourseListSection courses={course} url={url} />
          </div>
        ) : (
          <Typography variant="h6" mt={5} color="#7DB8F2">
            No results, probably you have not completed progress.
          </Typography>
        )}
      </section>
    </>
  ) : (
    <UpgradePlanPage />
  );
}

import CourseNav from "components/CourseNav";

function MaterialContentSection({ courseId, course, isActive = false }) {
  if (!isActive) return null;

  return (
    <section className="flex w-full items-center justify-center lg:hidden text-[#7DB8F2]">
      <CourseNav courseId={courseId} units={course.modulos} />
    </section>
  );
}

export default MaterialContentSection;
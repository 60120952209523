import { useEffect, useState } from "react"
import { TestsContext } from "./TestsContext"
import {
     diagnosisQuestionBusiness,
     testQuestionsBusiness,
} from "../pages/FreeTest/data/testQuestionsOriginalBusinnes"
import {
     diagnosisQuestionMarketing,
     testQuestionsMarketing,
} from "../pages/FreeTest/data/testQuestionsOriginalMarketing"
import {
     diagnosisQuestionTech,
     testQuestionsTech,
} from "../pages/FreeTest/data/testQuestionsOriginalTecnologia"
// import { testQuestions, diagnosisQuestion } from '../pages/FreeTest/data/testQuestions'
import { useLocation } from "wouter"

const initialDiagnosis = {
     id: 0,
     type: "",
     question: "",
     options: [
          {
               id: 0,
               text: "",
               points: 0,
          },
     ],
}

export const TestsProvider = ({ children }) => {
     const [testsData, setTestsData] = useState({
          diagnosisQuestion: initialDiagnosis,
          testQuestions: [],
     })
     const [location, setLocation] = useLocation()
     const [topicTest, setTopicTest] = useState("")

     const testsSelectors = {
          "/free-test/business": () => {
               setTestsData({
                    diagnosisQuestion: diagnosisQuestionBusiness,
                    testQuestions: testQuestionsBusiness,
               })
               setTopicTest("business")
          },
          "/free-test/marketing": () => {
               setTestsData({
                    diagnosisQuestion: diagnosisQuestionMarketing,
                    testQuestions: testQuestionsMarketing,
               })
               setTopicTest("marketing")
          },
          "/free-test/tech": () => {
               setTestsData({
                    diagnosisQuestion: diagnosisQuestionTech,
                    testQuestions: testQuestionsTech,
               })
               setTopicTest("tech")
          },
     }

     useEffect(() => {
          if (testsSelectors[location]) {
               testsSelectors[location]()
          }
     }, [location])

     return (
          <TestsContext.Provider value={{ testsData, topicTest }}>
               {children}
          </TestsContext.Provider>
     )
}

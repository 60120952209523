import { useState, useEffect} from "react";
import getModuleById from "services/getModuleById";

function MaterialAboutSection({ isActive = true, moduleId }) {
 
  const [about, setAbout] = useState();
  useEffect(() => {
    getModuleById(moduleId).then((response) => setAbout(response));
  }, [moduleId]);

  return isActive && about ? (
    <div className="w-full px-10 py-5">
      <h4 className="font text-[#7DB8F2] text-lg font-semibold">Content</h4>
      <p className="text-white" dangerouslySetInnerHTML={{ __html: about.contenido }}></p>
      <h4 className="font text-[#7DB8F2] mt-5 text-lg font-semibold">Goals</h4>
      <p className="text-white">{about.objetivos}</p>
    </div>
  ) : (
    <></>
  );
}

export default MaterialAboutSection;

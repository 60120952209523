import { API_URL } from "./settings";
import axiosInstance from "./axiosInstance";

export const recoverPassword = async (email) => {
     const URL = `${API_URL}/User/RecoverPassword/${email}`
     const axios = axiosInstance();
     const response = await axios.post(URL);
     return response.data;
};


import axiosInstance from "./axiosInstance";
import { USER_ID } from "./settings";

export default function getModuleById(id) {
  const url = `Modulos/GetById/${id}/${USER_ID}`;
  const axios = axiosInstance();
  return axios.get(url)
    .then((response) => {
      return response.data;
    });
}

import React from 'react'

export const SkeletonTopicCard = () => {
    return (
        <div className="flex flex-col p-4 rounded-3xl border-4 border-[#7DB8F2] bg-transparent text-[#7DB8F2] items-center justify-between text-center animate-pulse">
          <div className="h-6 bg-gray-300 w-3/4 mb-5 rounded"></div>
          <div className="flex flex-col items-center mb-6">
            <p className="h-4 bg-gray-300 w-1/2 mb-2 rounded"></p>
            <div className="flex">
              <div className="h-4 w-4 bg-gray-300 rounded-full mx-1"></div>
              <div className="h-4 w-4 bg-gray-300 rounded-full mx-1"></div>
              <div className="h-4 w-4 bg-gray-300 rounded-full mx-1"></div>
              <div className="h-4 w-4 bg-gray-300 rounded-full mx-1"></div>
              <div className="h-4 w-4 bg-gray-300 rounded-full mx-1"></div>
            </div>
          </div>
          <button className="p-2 pt-1 px-8 rounded-full bg-gray-300 text-transparent font-semibold w-fit cursor-not-allowed">
            Loading...
          </button>
        </div>
      );
}

import CourseCard from "components/CourseCard";
import { CourseCardCommunity } from "components/CourseCardCommunity";
import { CoursesContext } from "context/coursesContext";
import { useContext, useState, useMemo } from "react";
import { v4 } from "uuid";
import PopUpUrl from "components/PopUp";

export default function CourseList({ courses: coursesList, url }) {
     const { profesor: professor = false } = useContext(CoursesContext);
     const userInfo = JSON.parse(localStorage.getItem("loggedAppUser"))
     const [upgradePopUp, setUpgradePopUp] = useState(false);

     const courses = useMemo(() => {
          if (url === "courses") {
               let filteredCourses = coursesList.filter((course) => course.planEstudio !== "Obligatorio" && course.planEstudio !== "Freemium");               
               filteredCourses = filteredCourses.filter((course) => course.planEstudio.includes('plus')).length > 0 ? filteredCourses.filter((course) => course.planEstudio.includes('plus')) : filteredCourses;               
               return filteredCourses;
          }
          if (url === "courses-paced") {
               return coursesList.filter((course) => course.planEstudio === "Obligatorio" || course.planEstudio === "Freemium");
          }
          return coursesList
     }, [coursesList, url]);
     const sortedCourses = courses.sort((a, b) => {
          if (a.planEstudio === "Freemium" && b.planEstudio !== "Freemium") {
               return -1;
          } else if (a.planEstudio !== "Freemium" && b.planEstudio === "Freemium") {
               return 1;
          } else {
               return 0;
          }
     });

     const showPopUp = () => {
          setUpgradePopUp(true);
     }
     const hidePopUp = () => {
          setUpgradePopUp(false);
     }

     return (
          <>
               <div className="flex flex-wrap gap-5">
                    {professor
                         ? courses.map((course) => (
                              <CourseCardCommunity key={v4()} url={url} {...course} />
                         ))
                         : sortedCourses.map((course) => {
                              return (
                                   <CourseCard
                                        key={v4()}
                                        url={url}
                                        showPopUp={showPopUp}
                                        {...course}
                                   />
                              );
                         }
                         )}

               </div>
               {upgradePopUp && <PopUpUrl
                    title="This course is not available in your plan"
                    message="To gain access please upgrade your plan"
                    redirectUrl={`https://www.digispeak.io/?email=${userInfo.email}&plan=${userInfo.id}&user=${userInfo.id}`}
                    onCancel={hidePopUp}
                    acceptText="Upgrade"
               />}
          </>
     );
}

import { firestore } from "../../firebase/credentials";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";

const TestCard = ({ module, user, testID, refresh }) => {
  const [times, setTimes] = useState(0);
  const [bestScore, setBestScore] = useState(0);

  const checkTestRepetitions = async () => {
    const querySnapshot = await getDocs(
      collection(firestore, "testResults", `${user.id}`, `${testID}`)
    );

    let localTimes = 0;
    let localBestScore = 0;

    querySnapshot.forEach((doc) => {
      let entry = doc.data();
      // doc.data() is never undefined for query doc snapshots

      if (entry.score) {
        localTimes++;

        if (localBestScore < entry.score) {
          localBestScore = entry.score;
        }
      }
    });

    setBestScore(localBestScore);
    setTimes(localTimes);
  };

  useEffect(() => {
    // console.log(testID);
    checkTestRepetitions();

    //eslint-disable-next-line
  }, [refresh]);

  return (
    <div className="flex w-full flex-col items-start">
      <h2 className="accordion-title font-roboto text-[#7DB8F2] mr-5 mb-6 text-left  text-lg font-semibold ">
        {`Test ${module.id}: ${module.moduleName}`}
      </h2>

      {times === 0 ? (
        <p>Click here to start the test of this module</p>
      ) : (
        <p>
          You have already completed this test. Best Score:{" "}
          <strong>{bestScore}</strong>. Times you have completed this test:{" "}
          <strong>{times}</strong>
        </p>
      )}
    </div>
  );
};

export default TestCard;

import "./App.css"
import React, { useState } from "react"
import { Redirect, Route, Switch } from "wouter"
import { BrowserRouter as Router } from "react-router-dom"
import ScrollToAnchor from "utils/ScrollToAnchor"
import { UIProvider } from "context/UIProvider"
import { TestsProvider } from "context/TestsProvider"
import { SideBarContext } from "context/sideBarContext"

import LoginPage from "pages/Login"
import ExternalRegisterPage from "pages/ExternalRegister"
import { PreSignUp } from "pages/PreSignUp"
import { RecoverPasswordPage } from "pages/Password/recover"
import { ResetPasswordPage } from "pages/Password/reset"
import { ManualRegisterPage } from "pages/ManualRegister"
import { UserInfoContext } from "pages/Tests/Test/contexts/UserInfoContext"
import FreeTest from "pages/FreeTest"
import { UserRegisterPage } from "pages/UserRegister"
import { ProfessorView } from "pages/Views/ProfessorView"
import { StudentView } from "pages/Views/StudentView"
import { UserRegisterBasicPlan } from "pages/basic-plan-register";

function App() {
     const loggedUserJson = localStorage.getItem("loggedAppUser")
     const [user, setUser] = useState(JSON.parse(loggedUserJson))
     const [isOpen, setIsOpen] = useState(false)

     return (
          <Router><ScrollToAnchor />
               {!user ? (
                    <>
                         <TestsProvider>
                              <Switch>

                                   <Route component={LoginPage} path='/' />
                                   <Route component={RecoverPasswordPage} path='/password' />
                                   <Route component={ResetPasswordPage} path='/password/:secret' />
                                   <Route
                                        component={ManualRegisterPage}
                                        path='/manual-register'
                                   />
                                   <Route component={UserRegisterPage} path='/user-register' />
                                   <Route component={UserRegisterBasicPlan} path='/register-dsl' />
                                   <Route
                                        component={ExternalRegisterPage}
                                        path='/register/:paymentId'
                                   />
                                   <Route
                                        component={PreSignUp}
                                        path='/pre-register/:planId/:countryId'
                                   />
                                   <Route path='/free-test/:type' component={FreeTest} />
                                   <Route><Redirect href="/" /></Route>
                              </Switch>
                         </TestsProvider>
                    </>
               ) : (
                    <UIProvider>
                         <UserInfoContext.Provider value={user}>
                              <SideBarContext.Provider value={{ isOpen, setIsOpen }}>
                                   {user.rol === "Profesor" ? (
                                        <ProfessorView />
                                   ) : (
                                        <StudentView />
                                   )}
                              </SideBarContext.Provider>
                         </UserInfoContext.Provider>
                    </UIProvider>
               )}

          </Router>
     )
}

export default App

import SideNavItem from "../SideNavItem"
import SideNavIcons from "../SideNavIcons"
import CastForEducationIcon from "@mui/icons-material/CastForEducation"
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv"
import { CoursesContext } from "context/coursesContext"
import { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material"

const NAV_ITEMS = {
     student: [
          {
               id: 0,
               title: "Home",
               icon: <SideNavIcons name='home'/>,
               url: "/",
          },
          {
               id: 1,
               title: "Learning Path",
               icon: <SideNavIcons name='learning_path'/>,
               url: "/courses",
          },
          {
               id: 2,
               title: "Library",
               icon: <SideNavIcons name='library'/>,
               url: "/courses-paced",
          },
          {
               id: 3,
               title: "Speaking",
               icon: <SideNavIcons name='speaking'/>,
               url: "/speaking",
          },
          {
               id: 4,
               title: "Tests",
               icon: <SideNavIcons name='tests_icon'/>,
               url: "/tests",
          },
          {
               id: 5,
               title: "Course Material",
               icon: <SideNavIcons name='additional_material'/>,
               url: "/course-material",
          },
     ],
     professor: [
          {
               id: 0,
               title: "Dashboard",
               icon: <SideNavIcons name='dashboard'/>,
               url: "/",
          },
          {
               id: 1,
               title: "Students",
               icon: <SideNavIcons name='students'/>,
               url: "/students",
          },
          {
               id: 2,
               title: "Courses",
               icon: <SideNavIcons name='courses'/>,
               url: "/courses",
          },
          {
               id: 3,
               title: "Attendance",
               icon: <SideNavIcons name='assistance'/>,
               url: "/assistance",
          },
          {
               id: 4,
               title: "Follow Up",
               icon: <SideNavIcons name='followup'/>,
               url: "/followup",
          },
          {
               id: 5,
               title: "Progress",
               icon: <SideNavIcons name='progress'/>,
               url: "/progress",
          },
          {
               id: 7,
               title: "Community",
               icon: <SideNavIcons name='community'/>,
               url: "/course-community",
          },
     ],
}

export default function SideNav({ isOpen }) {
     const { userPlan } = useContext(CoursesContext)
     const [renderButton, setRenderButton] = useState(false);
     const userInfo = JSON.parse(localStorage.getItem("loggedAppUser"))
     
     const [navItems, setNavItems] = useState([])
     
     const updateNav = (original, obj) => {
          const array1 = original.slice(0, 4)
          const array2 = original.slice(4)
          array1.push(obj)
          array1.push(...array2)
          
          setNavItems(array1)
     }
     
     useEffect(() => {
          if (userPlan !== null) {
               if (userInfo.rol === "Profesor") {
                    setNavItems(NAV_ITEMS.professor);
                    
               } else {
                    setNavItems(NAV_ITEMS.student);
                    setRenderButton(userPlan.nombre !== 'Pro' && userPlan.nombre !== 'Flexible');
               }
          }
     }, [userPlan, userInfo])

     return (
          <>
               <nav className='flex flex-col pb-10'>
                    {navItems.map(({ id, title, icon, url }) => (
                         <SideNavItem key={id} title={title} icon={icon} url={url} />
                    ))}
                    {isOpen && userInfo.rol === 'Alumno' && renderButton &&(
                          
                              <a href={`https://www.digispeak.io/?email=${userInfo.email}&plan=${userInfo.id}&user=${userInfo.id}`} target="_blank" rel="noopener" className="mx-auto mt-10">
                                  <Button variant="contained" sx={{ ml: 2 }}>Upgrade Plan</Button>
                              </a>
                    )}
               </nav>
          </>
     )
}

export const processAttendance = ({
  courseSelected,
  moduloSelected,
  profesorSelected,
  classSelected,
  listOfStudentMinusLabel,
  date,
}) => {
  // return {
  //   alumnoId: studentSelected,
  //   cursoId: courseSelected,
  //   moduloIds: moduloSelected,
  //   profesorId: profesorSelected,
  //   fecha: date,
  //   presente: presentSelected,
  //   reprogramar: "true",
  //   observaciones,
  //   fechaCarga: new Date(Date.now()).toISOString(),
  //   clase: classSelected,
  // };
  const obj = {
    CursoId: courseSelected,
    Moduloid: moduloSelected,
    Profesorid: profesorSelected,
    Clase: classSelected,
    Fecha: date,
    FechaCarga: new Date(Date.now()).toISOString(),
    Reprogramar: true,
    Asistencias: listOfStudentMinusLabel,
  };

  console.log(JSON.stringify(obj));

  return obj;
};

// const onj = {
//   cursoID: 5064,
//   moduloID: 8314,
//   profesorID: 8959,
//   clase: 8,
//   fecha: "2023-02-24",
//   fechaCarga: "2023-02-06T14:10:49.494Z",
//   reprogramar: "true",
//   asistencias: [{ alumno: 2005, presente: true, observaciones: "SI" }],
// };

import axiosInstance from "./axiosInstance";
import { API_KEY, USER_ID } from "./settings";

export const getAllUsersAsync = async () => {
     try {
          const url = `Usuario/GetAll/${API_KEY}/${USER_ID}`;
          const axios = axiosInstance();
          const res = await axios.get(url);
          if (!res.ok) throw new Error(`Ha ocurrido un error`);
          const users = await res.json();
          return {
               ok: true,
               users,
          };
     } catch (error) {
          return {
               ok: false,
               errorMessage: error,
          };
     }
};

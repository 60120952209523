import { Link } from "wouter";

import CourseIcons from "components/CourseIcons";

export default function CourseNavItems({
  courseId,
  moduleId,
  bubbleId,
  materials = [],
  showBar,
  secondBar,
  url,
  community,
}) {
  return (
    <ol>
      {materials.map(({ id, nombre, completada }, index) => {
        return completada ? (
          <li
            key={id}
            className={`flex items-center p-2 text-[#7DB8F2] ${
              secondBar ? "" : "pl-0 pr-0 justify-center"
            }`}
          >
            <Link
              className="flex items-center gap-3"
              href={`/${url}/${courseId}/module/${moduleId}/material/${id}/${
                community ? bubbleId : ""
              }`}
            >
              <div
                className={`bg-[#7DB8F2] flex ${
                  secondBar ? "min-w-[2rem] h-8" : "min-w-[1.5rem] h-[1.5rem]"
                } items-center justify-center rounded-full`}
              >
                <CourseIcons name="check" />
              </div>
              <h4 dangerouslySetInnerHTML={{__html: nombre}} className={`font-semibold ${secondBar ? "" : "lg:hidden"}`}>
              </h4>
            </Link>
            {/* <span>{cls.video.duration}</span> */}
          </li>
        ) : (
          <li
            key={id}
            className={`flex items-center p-2 ${
              secondBar ? "" : "pl-0 pr-0 justify-center"
            }`}
          >
            <Link
              className="flex items-center gap-3"
              href={`/${url}/${courseId}/module/${moduleId}/material/${id}/${
                community ? bubbleId : ""
              }`}
            >
              <div
                className={`bg-[#1B212B] flex ${
                  secondBar ? "min-w-[2rem] h-8" : "min-w-[1.5rem] h-[1.5rem]"
                } items-center justify-center rounded-full`}
              >
                <CourseIcons name="play" />
              </div>
              <h4 dangerouslySetInnerHTML={{__html: nombre}} className={`font-semibold ${secondBar ? "" : "lg:hidden"}`}>
              </h4>
            </Link>
            {/* <span>{cls.video.duration}</span> */}
          </li>
        );
      })}
    </ol>
  );
}

import React, { useEffect, useState } from "react";

const Bar = ({ nombre, porcentajeCompletado }) => {
  const [barSize, setBarSize] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setBarSize(porcentajeCompletado);
    }, 0);

    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-2 mb-3 first:mt-2">
      <div className="flex items-center">
        <span className="font-medium mr-3 whitespace-nowrap">{nombre}</span>
        <div className="h-5 w-full rounded-xl bg-[#CF7831]/30">
          <div
            className="flex h-full min-w-fit items-center rounded-xl bg-[#CF7831] px-2 text-right text-primary w-0 transition-all ease-in-out duration-1000 "
            style={{ width: barSize + "%" }}
          >
            <span className="flex items-center justify-end h-full w-full text-white">
              {porcentajeCompletado}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bar;

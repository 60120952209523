import React, { useContext, useEffect, useRef, useState } from "react";
import { firestore } from "../../firebase/credentials";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { CoursesContext } from "context/coursesContext";
import { MeetingsContext } from "context/meetingsContext";

import "react-calendar/dist/Calendar.css";
import "../../styles/Calendar.css";

import { ModalPremium } from "components/ModalPremium";
import TopicCard from "./TopicCard";
import { getSpeakingTopics } from "services/getSpeakingTopics";
import { teacherLinks } from "./teacherLinks";
import { USER_ID } from "services/settings";
import getAllCoursesByUser from "services/getAllCoursesByUser";
import { UpgradePlanPage } from "components/UpgradePlanPage";
import { SkeletonTopicCard } from "./skeletonTopicCard";

const SpeakingPage = ({ requiredPlanFeature }) => {
  const { courses, setCourses, userPlan } = useContext(CoursesContext);
  const [topics, setTopics] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));
  // const [meetingSelected, setMeetingSelected] = useState("");

  const getProfessors = async (meetingsToDo) => {
    //Professors
    const meetingsNotBooked = [];

    for (const meeting in meetingsToDo) {
      if (!meetingsToDo[meeting].isBooked) {
        meetingsNotBooked.push(parseInt(meeting) + 1);
      }
    }

    if (meetingsNotBooked.length <= 0) {
      setTeachers([]);
      return;
    }

    const profRef = query(collection(firestore, "professors"));
    const profRes = await getDocs(profRef);

    const professorsList = [];
    profRes.forEach((prof) => {
      professorsList.push(prof.data());
    });
    const professorsListFiltered = professorsList.filter((prof) =>
      meetingsNotBooked.includes(prof.meetingId)
    );

    setTeachers(professorsListFiltered);
  };

  //--------------------------------------------------------------
  const { runOperations, appointmentsAvailable } = useContext(MeetingsContext);

  useEffect(() => {
    if (userPlan) {
      if (userPlan.nombre === "Basic") {
        // return setIsModalOpen(true);
      }

      runOperations(getProfessors);
    }

    //eslint-disable-next-line
  }, [userPlan]);

  useEffect(() => {
    const fetchTopics = async (courseId) => {
      try {
        const courseTopics = await getSpeakingTopics();
        return { courseId, topics: courseTopics };
      } catch (error) {
        console.error(`Error fetching topics for course ${courseId}:`, error);
        return null;
      }
    };

    const updateTopics = async () => {
      let updatedTopics = {};

      await Promise.all(
        courses.map(async (course) => {
          if (
            course.planEstudio !== "Obligatorio" &&
            course.planEstudio !== "Freemium" &&
            !course.planEstudio.includes("plus")
          ) {
            const result = await fetchTopics(course.id);
            if (result) {
              updatedTopics[result.courseId] = result.topics;
            }
          }
        })
      );

      setTopics(updatedTopics);
    };

    updateTopics();
  }, [courses]);

  // Shiny new stuff
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSchedulingModuleOpen, setIsSchedulingModuleOpen] = useState(false);
  const [topicToSchedule, setTopicToSchedule] = useState(null);
  const [teachersArray, setTeachersArray] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [showTeachersList, setShowTeachersList] = useState(true);
  const Page = useRef(null);
  const Iframe = useRef(null);

  const goToIframe = () => {
    Iframe.current.scrollIntoView();
  };
  const goToTop = () => {
    Page.current.scrollIntoView();
  };

  const openConfirmation = (courseID, topic) => {
    setTopicToSchedule({ courseID, topic });
    setIsConfirmationModalOpen(true);
  };

  const closeTeacheList = () => {
    setSelectedTeacher(null);
    setIsSchedulingModuleOpen(null);
    goToTop();
  };

  const closeCalendar = () => {
    setSelectedTeacher(null);
    setShowTeachersList(true);
    goToTop();
  };

  const [isLoading, setIsLoading] = useState(false);

  const updatedTime = async () => {
    const timeRef = doc(firestore, "meetings", "serverTime");
    // Update the timestamp field with the value from the server
    await updateDoc(timeRef, {
      timestamp: serverTimestamp(),
    });
    const docSnap = await getDoc(timeRef);
    const currentTime = docSnap.data().timestamp.seconds;
    return currentTime;
  };

  const updateFirebase = async () => {
    setIsLoading(true);
    const now = await updatedTime();
    let date = new Date(Number(now.toString() + "000"));
    let obj = {
      appointmentsLeft: appointmentsAvailable - 1,
    };
    const meetingsRef = doc(firestore, "meetings", `${USER_ID}`);
    await setDoc(meetingsRef, obj, { merge: true });
    await runOperations();
    getAllCoursesByUser(user.id).then((response) => {
      setCourses(response);
    });
    setIsLoading(false);
    closeCalendar();
  };

  const confirmScheduling = async (confirmed) => {
    if (confirmed) {
      // await updateFirebase();
      let tempTeachersArray = [];
      let [obj] = teacherLinks.filter(
        (obj) => obj.id === topicToSchedule.courseID
      );
      obj.teachers.forEach((teacher) => {
        let [topic] = teacher.topics.filter(
          (topic) => topic.id === topicToSchedule.topic.position
        );
        if (!!topic) {
          let teacherObj = { name: teacher.name, link: topic.link };
          tempTeachersArray.push(teacherObj);
        }
      });
      setTeachersArray(tempTeachersArray);
      setIsSchedulingModuleOpen(true);
      setShowTeachersList(true);
      goToTop();
    }
    setIsConfirmationModalOpen(false);
  };

  const hasRequiredPlan = userPlan?.features.some(
    (item) => item.featureName === requiredPlanFeature && item.enabled
  );

  if (hasRequiredPlan === undefined) {
    return null;
  }

  return hasRequiredPlan ? (
    <>
      {isConfirmationModalOpen && (
        <div
          className="confirmation-modal-speaking opacity-0 fixed top-0 min-h-screen bg-[#1B212B] flex justify-center items-center"
          style={{
            backgroundColor: "#94a3b840",
            animation: ".3s ease-in-out forwards appear",
          }}
        >
          <div className="h-min flex flex-col justify-center text-center items-center p-6 md:px-10 rounded-lg bg-[#1B212B]">
            <>
              <p className="text-[#7DB8F2]">
                Do you want to schedule this session?
              </p>
              <strong className="text-[#7DB8F2] mb-5">
                (It will be deducted from your 12 monthly sessions)
              </strong>

              <div className="flex">
                <button
                  className="p-2 px-8 rounded-full bg-[#7DB8F2] hover:opacity-75 text-white font-semibold w-fit mr-2"
                  onClick={() => {
                    confirmScheduling(true);
                  }}
                >
                  Yes
                </button>
                <button
                  className="p-2 px-8 rounded-full bg-[#CF7831] hover:opacity-75  text-white font-semibold w-fit"
                  onClick={() => {
                    confirmScheduling(false);
                  }}
                >
                  Not now
                </button>
              </div>
            </>
          </div>
        </div>
      )}

      <div
        className={`calendar-container pt-8 pb-14 p-5 md:p-10 xl:px-14 bg-[#1B212B]`}
        ref={Page}
      >
        <div className="blue-box pr-6 py-6 text-center md:text-left text-[20px]">
          <h1 className="main-title md:mb-4 text-[#7DB8F2] text-[1.2em] font-[700]">
            Schedule your speaking sessions
          </h1>
          <p className="text-md w-full py-4 pb-0 text-[#7DB8F2]">
            Practical sessions available this month:{" "}
            <strong className="font-semibold">{appointmentsAvailable}</strong>
          </p>
        </div>

        <ModalPremium isModalOpen={isModalOpen} />

        {isSchedulingModuleOpen ? (
          <div className="mb-2">
            {showTeachersList ? (
              <div className="flex flex-col justify-start items-center">
                <p className="text-xl my-2 mb-10 mt-4 mb:mt-8 text-[#7DB8F2] font-semibold text-center w-full">
                  Schedule the session:{" "}
                  <span className="text-[#7DB8F2]">
                    {topicToSchedule.topic.name}
                  </span>
                </p>

                <strong className="text-lg text-[#7DB8F2]">
                  Select a coach
                </strong>
                <p className="text-white mb-8">Select only one coach!</p>

                <div className="w-full max-w-md mb-4">
                  {teachersArray !== null &&
                    teachersArray.map((teacher) => {
                      return (
                        <div
                          key={teacher.name}
                          className={
                            "flex justify-center border border-transparent items-center bg-white rounded-lg shadow p-4 px-10 hover:border-teal-400 cursor-pointer mb-2"
                          }
                          onClick={() => {
                            setSelectedTeacher(teacher.link);
                            setShowTeachersList(false);
                          }}
                        >
                          <p>{teacher.name}</p>
                        </div>
                      );
                    })}
                </div>
                <button
                  className="p-2 px-8 rounded-full bg-[#CF7831] hover:opacity-75  text-white font-semibold w-fit mb-4"
                  onClick={closeTeacheList}
                >
                  Go back
                </button>
              </div>
            ) : (
              <>
                <iframe
                  ref={Iframe}
                  src={`${selectedTeacher}?embed=1&embedType=iframe`}
                  loading="lazy"
                  style={{
                    border: "none",
                    minWidth: "320px",
                    minHeight: "544px",
                  }}
                  className={"iframe-calendar-speaking"}
                  id="zcal-invite"
                  title="zcal"
                ></iframe>
                <div className="flex flex-col justify-start items-center -mt-0">
                  <strong className="text-lg my-2 mb-6 mt-8 text-[#7DB8F2]">
                    Have you scheduled the session?
                  </strong>
                  <div className="flex ">
                    <button
                      className="p-2 px-8 rounded-full bg-green-400 hover:bg-green-500 text-white font-semibold w-fit mr-2"
                      onClick={updateFirebase}
                    >
                      {isLoading ? "Loading..." : "Yes, confirm my session"}
                    </button>
                    <button
                      className="p-2 px-8 rounded-full bg-red-600 hover:bg-red-700  text-white font-semibold w-fit"
                      onClick={closeCalendar}
                    >
                      No, I'm checking the availability
                    </button>
                  </div>

                  <strong className="text-lg my-2 mb-6 mt-8 text-[#7DB8F2]">
                    Are the available hours not suited to your needs?
                  </strong>
                  <button
                    className="p-2 px-8 rounded-full bg-red-600 hover:bg-red-700  text-white font-semibold w-fit mb-4"
                    onClick={closeCalendar}
                  >
                    Go back
                  </button>
                </div>
              </>
            )}

            {selectedTeacher && <></>}
          </div>
        ) : (
          <div className="grid w-full grid-cols-1 lg:grid-cols-1 gap-4 gap-y-8">
            {courses.length > 0 ? (
              courses.map((course) => {
                return (
                  <>
                    {topics[course.id] && (
                      <div
                        key={course.id}
                        className="flex flex-col justify-start"
                      >
                        <strong className="text-[#7DB8F2] text-2xl font-bold mb-6">
                          {course.nombre} Topics
                        </strong>
                        <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
                          {topics[course.id].map((topic, i) => (
                            <TopicCard
                              key={topic.name + "-" + topic.id}
                              {...topic}
                              appointmentsAvailable={appointmentsAvailable}
                              courseID={course.id}
                              openConfirmation={openConfirmation}
                              isSchedulingModuleOpen={isSchedulingModuleOpen}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <div className="flex flex-col justify-start">
                <strong className="text-[#7DB8F2] text-2xl font-bold mb-6">
                  Topics
                </strong>
                <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
                  {Array.from({ length: 8 }).map((_, index) => (
                    <SkeletonTopicCard key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <UpgradePlanPage />
  );
};

export default SpeakingPage;

import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

export const getModuleByCourseIdAsync = async (id) => {
     try {
          const url = `Modulos/GetByCurso/${id}/${API_KEY}/`;
          const axios = axiosInstance();
          const res = await axios.get(url);
          if (!res.ok) throw new Error(`Ha ocurrido un error`);
          const modulos = await res.json();
          return {
               ok: true,
               modulos,
          };
     } catch (error) {
          return {
               ok: false,
               errorMessage: error,
          };
     }
};

export default function PopUpUrl({ title, message, redirectUrl, onCancel, acceptText = "Ok" }) {
    return (
        <>
            <div
                className="confirmation-modal-speaking opacity-0 fixed top-0 left-0 w-full h-full bg-[#1B212B] flex justify-center items-center"
                style={{
                    backgroundColor: "#94a3b840",
                    animation: ".3s ease-in-out forwards appear",
                    zIndex: 5000,
                }}
                onClick={onCancel}
            >
                <div className="relative h-min flex flex-col p-10 rounded-lg bg-[#1B212B] justify-center text-center items-center"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        className="absolute top-2 right-4 hover:text-white text-[#7DB8F2] font-bold"
                        onClick={onCancel}
                    >
                        X
                    </button>
                    <strong className="text-[#7DB8F2] mb-5">
                        {title}
                    </strong>
                    <strong className="text-base font-medium mb-5 leading-5 mt-4 text-[#7DB8F2]">{message}</strong>
                    <div className="flex mt-4">
                        <button
                            className="p-2 px-8 rounded-full bg-green-400 hover:bg-green-500 text-white font-semibold w-fit mr-2"
                            onClick={() => window.location.href = redirectUrl}
                        >
                            {acceptText}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
import React from "react";
import CoursesIcons from "components/CoursesIcons";

export default function CoursesHeader({
     coursesNum,
     handleCategory,
     handleStatus,
     handleSearch,
     search
}) {
     return (
          <>
               <header className="flex flex-col mx-auto">
                    <div className="flex items-center gap-3 mb-5 justify-center">
                         <h2 className="text-[#7DB8F2] text-[26px] font-[700] font-roboto">My Courses</h2>
                    </div>
                    <form className="flex items-center gap-2 flex-wrap">
                         <div className="flex h-8 w-40 items-center rounded-lg border border-white bg-white">
                              <input
                                   type="text"
                                   placeholder="Find Course"
                                   className="h-full w-full px-2 text-xs outline-none rounded-lg font-roboto"
                                   value={search}
                                   onChange={handleSearch}
                              />
                              <CoursesIcons name="search" />
                         </div>
                         <label className="text-sm text-gray-400">Status</label>
                         <select
                              className="h-8 w-28 rounded-lg border border-white px-2 text-xs text-gray-400"
                              onChange={handleStatus}
                         >
                              <option value="">All</option>
                              <option value="completed">Completed</option>
                              <option value="in progress">In Progress</option>
                              <option value="no started">Not Started</option>
                         </select>
                    </form>
               </header>
          </>
     );
}

import React, { useContext, useEffect, useState } from "react";

//Contexts
import { DataLenghtContext } from "../../contexts/DataLenghtContext";
import { UserChoicesContext } from "../../contexts/UserChoicesContext";

const RadioType = ({ data }) => {
  const { id: questionID, question, options } = data;

  const [selectedOptionID, setSelectedOptionID] = useState(null);

  const dataLenght = useContext(DataLenghtContext);
  const userChoices = useContext(UserChoicesContext);

  //Set ID
  const handleRadioClick = (e) => {
    setSelectedOptionID(Number(e.target.value));
  };

  //Load option already chosen if it exists
  useEffect(() => {
    //In new load
    if (selectedOptionID === null) {
      //Context has loaded
      if (userChoices().array.length !== 0) {
        //The option has been chosen before
        if (userChoices().array[questionID].choice !== null) {
          setSelectedOptionID(userChoices().array[questionID].choice);
        }
      }
    }
    //eslint-disable-next-line
  }, [userChoices()]);

  //Update context
  useEffect(() => {
    if (selectedOptionID !== null) {
      console.log(userChoices(selectedOptionID).array[questionID]?.choice)
      if (userChoices(selectedOptionID).array[questionID]?.choice !== selectedOptionID) {
        userChoices().updateContext(questionID, selectedOptionID);
      }
    }

    //eslint-disable-next-line
  }, [selectedOptionID]);

  return (
    <div className="radio">
      {/* Only in the questionnaire */}
      {dataLenght > 0 && (
        <strong>{`Question ${questionID} of ${dataLenght}`}</strong>
      )}

      <p>{question}</p>

      {options.map((option) => {
        return (
          <label
            key={questionID + "-" + option.id}
            className={selectedOptionID === option.id ? "checked" : ""}
          >
            <input
              type="radio"
              name={"question-" + questionID}
              value={option.id}
              checked={selectedOptionID === option.id}
              onChange={handleRadioClick}
            />
            <p>{option.text}</p>
          </label>
        );
      })}
    </div>
  );
};

export default RadioType;

import React, { useEffect, useState } from "react";

import { ServerDataContext } from "./ServerDataContext";
import { getAllByTeacher } from "./calls";

const ServerDataProvider = ({ children }) => {
  const [serverData, setServerData] = useState([]);
  const [updater, setUpdater] = useState(null);

  useEffect(() => {
    const getAll = async () => {
      const { data } = await getAllByTeacher();

      // console.log(data.student);
      setServerData(data.student);
    };

    getAll();
  }, [updater]);

  return (
    <ServerDataContext.Provider value={{ serverData, setUpdater }}>
      {children}
    </ServerDataContext.Provider>
  );
};

export default ServerDataProvider;

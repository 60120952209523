import { Link, useLocation } from "wouter";

export default function CourseCard({
  id,
  nombre,
  cantidadAlumnos,
  duracion,
  profesores,
  porcentajeCompletado,
  url,
  planEstudio,
  habilitado,
  showPopUp
}) {
  const [location, setLocation] = useLocation();
  
  if (porcentajeCompletado > 100) {
    porcentajeCompletado = 100;
  }

  const onCardClick = (e) => {
    if (habilitado) {
      setLocation(`/${url}/${id}`);
    } else {
      showPopUp();
    }
  };

  return (
    <div
      className={`flex w-full flex-col rounded-xl border-4 ${habilitado ? "border-[#7DB8F2] hover:border-orange-400 hover:scale-[1.01] hover:shadow-md " : "border-[#AAAAAA]"} bg-transparent shadow-sm transition delay-[50ms] ease-in-out md:w-64`}
      onClick={onCardClick}
    >
      <div className="show-card-fadeIn py-5 px-2">
        {planEstudio == "Freemium" ?
          <div className="font-roboto text-center bg-[#CF7831] md:w-16 rounded-md">
            Free
          </div>
          : null}
        <h3 className={`font-roboto text-center ${habilitado ? "text-[#7DB8F2]" : "text-[#AAAAAA]"} line-clamp-2 mb-1 font-bold pt-6 text-[1.2em]`}>
          {nombre}
        </h3>
        <div className="bg-[#CF7831]/30 h-5 w-full rounded-xl">
          <div
            className="bg-[#CF7831] flex h-full min-w-fit items-center rounded-xl px-2 text-right text-gray-50"
            style={{ width: porcentajeCompletado + "%" }}
          >
            <span className="w-full">{porcentajeCompletado}%</span>
          </div>
        </div>
      </div>
    </div>
  );
}
